import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './index.css';

// images
import InsectCollectingImg from './images/Insect Collecting-compressed.png';

export default () => (
  <section className="hero-section pt-7 pb-4.5 pt-sm-9 pt-md-15.5 pb-md-10">
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-7 mb-3.5 mb-sm-4.5 mb-md-0">
          <h1 className="mb-2 mb-md-3">Insect Collecting Made Easy</h1>
          <h2 className="mb-3 mb-md-3.5">Simplify your insect collection's data management with in-field documentation, easily retrievable specimen data, and automated label printing</h2>
          <div className="btn-hero-group">
            <Link to="/sign-up" className="btn btn-lg btn-tertiary btn-hero mr-3 py-1 px-0.5 px-md-1.5" style={{color: 'black'}}><strong>Sign Up Now</strong></Link>
            <Link to="/about" className="btn btn-lg btn-tertiary-border btn-hero text-white py-1 px-0.5 px-md-1.5"><strong>Learn More</strong></Link>
          </div>
        </div>
        <div className="col-12 col-md-5">
          <img className="hero-img" src={InsectCollectingImg} alt="net collecting butterfly" />
        </div>
      </div>
    </div>
  </section>
);
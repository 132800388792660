/**
 * Tests the format of a string against a valid email format
 * From this awesome answer:  https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 * @param {String} emailString The string to test for the format of being an email
 */
const isValidEmail = emailString => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(emailString).toLowerCase());
}

export default isValidEmail;

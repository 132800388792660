import React, { useState, useRef, useEffect, createRef } from 'react';
import PropTypes from 'prop-types';
import BasicModal from '../../../../components/BasicModal';
import BasicModalHeader from '../../../../components/BasicModalHeader';
import BasicModalBody from '../../../../components/BasicModalBody';
import BasicModalFooter from '../../../../components/BasicModalFooter';
import StandardFormInput from '../../../../components/StandardFormInput';

const CurrentPasswordModal = ({
  isOpen,
  toggle,
  currentPasswordValue,
  onChangeCurrentPassword: handleChange,
  onSubmitUserSettingsForm: handleSubmitUserSettingsForm,
  className
}) => {
  const [ passwordFieldType, setPasswordFieldType ] = useState('password');
  const [ currentPasswordFormState, setCurrentPasswordFormState ] = useState({
    formSubmitted: false,
    formSubmissionAttempted: false
  })

  /**
   * Toggles the password field visibility
   * @param {Object} e The event information associated with clicking the password visibility button in the input field
   */
  const handlePasswordVisibilityClick = e => setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password');

  /**
   * Handles the submission of the current password and the user settings form
   * @param {Object} e The event associated with clicking the submit button
   */
  const handleSubmit = e => {
    e.preventDefault();
    setCurrentPasswordFormState(prevState => ({
      ...prevState,
      formSubmissionAttempted: true
    }))
    if (currentPasswordValue) {
      toggle(e);
      handleSubmitUserSettingsForm(currentPasswordValue)(e);
      setCurrentPasswordFormState(prevState => ({
        ...prevState,
        formSubmissionAttempted: false
      }));
    }
  };

  return (
    <BasicModal isOpen={isOpen} toggle={toggle} className={className}>
      <BasicModalHeader toggle={toggle}>
        <h1>Current Password</h1>
      </BasicModalHeader>
      <BasicModalBody>
        <form>
          <StandardFormInput
            validation
            passwordVisibilityButton
            onClickPasswordVisibility={handlePasswordVisibilityClick}
            label="Current Password"
            id="currentPasswordValue"
            type={passwordFieldType}
            autoComplete="current-password"
            value={currentPasswordValue}
            onChange={handleChange}
            inputValidator={value => Boolean(value)}
            errorMessage="Required"
            formSubmitted={currentPasswordFormState.formSubmissionAttempted}
          />

          <button
            className="btn btn-lg btn-primary w-100 mt-2"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </form>
      </BasicModalBody>
      <BasicModalFooter />
    </BasicModal>
  );
};

export default CurrentPasswordModal;
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import StandardFormInput from '../../../../../../components/StandardFormInput';

const TaxonSearch = ({
  onChange: handleChange,
  searchTerm
}) => {
  return (
    <StandardFormInput
      label="Taxon Search and Selection"
      id="taxon-search"
      type="text"
      autoComplete="off"
      value={searchTerm}
      onChange={handleChange}
    />
    // <div className="form-group form-group-responsive-spacing">
    //   <label htmlFor="taxon-search">Taxon Search</label>
    //   <input
    //     type="text"
    //     className="form-control"
    //     autoComplete="off"
    //     id="taxon-search"
    //     aria-label="taxon-search"
    //     value={searchTerm}
    //     onChange={handleChange}
    //   />
    // </div>
  );
}

TaxonSearch.propTypes = {
  onChange: PropTypes.func.isRequired,
  searchTerm: PropTypes.string.isRequired
}

export default TaxonSearch;
import React from 'react';
import PropTypes from 'prop-types';
import getTaxonByRank, { jsonifyClassificationStringWithRanks } from '../../../../utils/classificationTraversal';
import { labelNameFormatter } from '../../../../utils/nameFormats';


const IdentificationCell = ({
  insect
}) => {
  let identification = insect.classificationStringWithRanks
    ? jsonifyClassificationStringWithRanks(insect.classificationStringWithRanks)
    : null;

  let orderRank,
      family,
      genus,
      species,
      specificIdentification;
      
  if (identification) {
    orderRank = getTaxonByRank(identification, 'order');
    family = getTaxonByRank(identification, 'family');
    genus = getTaxonByRank(identification, 'genus');
    species = getTaxonByRank(identification, 'species');
  }
  
  if (species) {
    specificIdentification = <em>{species.taxon}</em>;
  } else if (genus) {
    specificIdentification = <em>{genus.taxon} spp.</em>;
  } else if (family) {
    specificIdentification = family.taxon;
  }

  const identifier = labelNameFormatter(
    insect.identifierFirstName,
    insect.identifierMiddleName,
    insect.identifierLastName,
    insect.identifierSuffix
  );

  return (
    <td>
      <div>
        <p>{insect.humanReadableId}</p>
        {identification && orderRank && (
          <p>{orderRank.taxon}</p>
        )}
        {specificIdentification && (
          <p>{specificIdentification}</p>
        )}
        {identifier && (
          <p>det. {identifier}</p>
        )}
      </div>  
    </td>
  )
};

IdentificationCell.propTypes = {
  insect: PropTypes.object.isRequired
};

export default IdentificationCell;
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

// css
import './index.css';

// utils
import { handleHttpResponse } from '../../utils/handleFetchHttpErrors';
import parseQueryParamString from '../../utils/parseQueryParamString';

// custom components
import SmallFormBoxPageContent from '../../components/SmallFormBoxPageContent';
import SmallFormBoxInput from '../../components/SmallFormBoxInput';
import AsyncAlert from '../../components/AsyncAlert';
import ValidationInput from '../../components/ValidationInput';

const PasswordReset = () => {
  let passwordField = useRef(null);
  const [ passwordValue, setPasswordValue ] = useState('');
  const [ passwordFieldType, setPasswordFieldType ] = useState('password');
  const [ formSubmissionState, setFormSubmissionState ] = useState({
    submissionAttempted: false,
    formSubmitted: false,
    submissionPending: false,
    formError: '',
    formErrorAlertVisible: false
  });

  const { collectorId } = useParams();
  const { token } = parseQueryParamString(useLocation().search);

  useEffect(() => {
    passwordField.current.focus();
  }, []);

  /**
   * Toggles the password field visibility
   * @return {Void} (changes state)
   */
  const onClickPasswordVisibility = () => setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password');

  /**
   * Validates the password input to make sure that it is filled
   * @param {String|Number} value The value of input field to validate
   * @return {Boolean} Whether or not the input field is filled
   */
  const validateFilledInput = value => Boolean(value);

  /**
   * Turns off the alert visibility error
   */
  const handleFormSubmissionErrorAlertVisibility = () => setFormSubmissionState(prevState => ({
    ...prevState,
    formErrorAlertVisible: false
  }));

  /**
   * Submits the form data (new password) to the API backend for a new password
   */
  const handleFormSubmit = e => {
    e.preventDefault();
    setFormSubmissionState(prevState => ({
      ...prevState,
      submissionPending: true
    }));
    if (Boolean(passwordValue)) {
      setFormSubmissionState(prevState => ({
        ...prevState,
        submissionPending: true
      }))
      fetch(`/api/collectors/${collectorId}/password`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          newPassword: passwordValue,
          token
        })
      })
        .then(handleHttpResponse)
        .then(response => setFormSubmissionState(prevState => ({
          submissionAttempted: false,
          submissionPending: false,
          formSubmitted: true,
          formError: '',
          formErrorAlertVisible: false
        })))
        .catch(error => setFormSubmissionState(prevState => ({
          submissionAttempted: false,
          submissionPending: false,
          formSubmitted: true,
          formError: error.message,
          formErrorAlertVisible: true
        })));
    }
  }
  
  // console.log('password reset request form:', emailValue, formSubmissionState);
  return (
    <SmallFormBoxPageContent title="Password Reset">
      {(! formSubmissionState.formSubmitted || Boolean(formSubmissionState.formError)) && (
        <div>
        {/* password */}
          <div className="form-group password-reset-group">
            <label htmlFor="password">New Password:</label>
            <ValidationInput
              type={passwordFieldType}
              passwordVisibilityButton
              onClickPasswordVisibility={onClickPasswordVisibility}
              id="password"
              autoComplete="new-password"
              aria-describedby="password"
              value={passwordValue}
              onChange={e => setPasswordValue(e.target.value)}
              inputValidator={validateFilledInput}
              errorMessage="Required"
              formSubmitted={formSubmissionState.submissionAttempted}
              componentRef={passwordField}
            />
          </div>

          <button
            type="submit"
            className="btn btn-lg btn-tertiary w-100 mt-2"
            onClick={handleFormSubmit}
            disabled={formSubmissionState.submissionPending}
          >
            {formSubmissionState.submissionPending && <i className="fas fa-circle-notch fa-spin"></i>} <strong>Submit</strong>
          </button>

          <AsyncAlert
            color="danger"
            isOpen={Boolean(formSubmissionState.formError) && formSubmissionState.formErrorAlertVisible}
            changeIsOpen={handleFormSubmissionErrorAlertVisibility}
            visibleTime={5}
          >
            <i className="fas fa-bug"></i> {formSubmissionState.formError}
          </AsyncAlert>
        </div>
      ) || (
        <div className="alert alert-success">
          <i className="fas fa-check-circle"></i> Password successfully reset.  Use the new password to <Link to="/login">login</Link>.
        </div>
      )}
    </SmallFormBoxPageContent>
  );
};

export default PasswordReset;
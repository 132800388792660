import React from 'react';
import PropTypes from 'prop-types';
import { Link as AnchorLink } from 'react-scroll';

// css
import './index.css';

/**
 * Intended for use in the SideNav Component Scheme
 * e.g.
 * <SideNavContainer>
 *   <SideNav>
 *     <SideNavItem></SideNavItem>
 *   </SideNav>
 *   <SideNavPageContent></SideNavPageContent>
 * </SideNavContainer>
 */

/**
 * The individual items to be placed in a side navigation component as shown above
 * @param {String} to The id of the HTML element to link to
 * @param {Number} offset The number of pixels below (+) or above (-) the HTML element to trigger the active event of react-scroll
 * @param {String} activeClass The class to apply to the anchor when the HTML element plus offset is at the top of the screen
 * @param {String} className The CSS classes to apply to the component
 * @param {Object} children React children (components or HTML elements)
 */
 const SideNavItem = ({
  to,
  offset,
  activeClass,
  className,
  children,
}) => {

  return (
    <AnchorLink
      to={to}
      offset={offset}
      spy
      isDynamic
      className={className}
      activeClass={activeClass}
    >
      <div className={`side-nav-item py-0.75`}>
        {children}
      </div>
    </AnchorLink>
  );
};

SideNavItem.propTypes = {
  className: PropTypes.string,
  active: PropTypes.bool
};

export default SideNavItem;
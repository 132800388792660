import './index.css';

import React from 'react';
import PropTypes from 'prop-types';

const FeatureSectionImg = ({
  featureImgSrc,
  featureImgAlt
}) => {
  return (
    <div className="text-center">
      <img
        className="img-fluid feature-img"
        src={featureImgSrc}
        alt={featureImgAlt}
      />
    </div>
  );
}

FeatureSectionImg.propTypes = {
  featureImgSrc: PropTypes.string.isRequired,
  featureImgAlt: PropTypes.string.isRequired
};

export default FeatureSectionImg;
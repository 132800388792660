import './index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// utils
import getCsrfToken from '../../../../utils/getCsrfToken';
import dateFormatter from '../../../../utils/dateFormats';
import locationFormatter from '../../../../utils/locationFormats';

// redux
import { prepareInsectUpdate } from '../../../../state/insects/actions';

const IndividualInsect = ({
  checked,
  insect,
  onSelectInsect: handleSelectInsect, // onSelectRow
  onClickInsect: handleClickInsect, // onClick/handleClick
  onDeleteInsect: handleDeleteInsect,
  prepareInsectUpdate
}) => {
  const [ imgSrc, setImgSrc ] = useState(null);
  useEffect(() => {
    const primaryImageId = insect.images.length
      ? insect.images.filter(image => image.primaryImage === 1)[0].imageId
      : null;
    if (primaryImageId) {
      fetch(`/api/specimens/${insect.humanReadableId}/images/${primaryImageId}/raw`, {
        headers: {
          'x-csrf-token': getCsrfToken()
        }
      })
        .then(response => response.blob())
        .then(blob => setImgSrc(URL.createObjectURL(blob)));
    }
  }, [
    insect.images
  ]);

  /**
   * Retrieves the last and most-specific taxon identifying an insect
   * @param {String} identificationString The '$' delimited string identifying the insect
   * @return {String} The most-specific identifying taxon
   */
  const getMostSpecificIdentification = identificationString => {
    if (identificationString) {
      return identificationString.split('$').slice(-1)[0];
    } else {
      return 'Unknown';
    }
  }

  return (
    <div
      className="individual-insect mb-1.5"
      onClick={e => {e.stopPropagation(); handleClickInsect(e)}}
    >
      <div
        className="insect-img text-center"
        style={{
          backgroundImage: imgSrc ? `url('${imgSrc}')` : 'none'
        }}
      >
        {! imgSrc && (
          <i className="far fa-image fa-5x mx-auto" style={{ color: 'grey' }}></i>
        )}
      </div>

      <div className="insect-data py-1 py-lg-1.5 pl-1.5">
        <div className="individual-insect-info">
          <h2>{getMostSpecificIdentification(insect.classificationString)}</h2>
          <ul>
            <li><strong>ID</strong>:  {insect.humanReadableId}</li>
            <li><strong>Date</strong>:  {dateFormatter(insect.collectionDate)}</li>
            <li><strong>Location</strong>:  {locationFormatter(insect.city, insect.state)}</li>
          </ul>
        </div>
      </div>
      <div className="individual-insect-actions d-none d-lg-block ml-auto mr-0.5 mr-xxxl-3">
        <Link
          to={`/edit/${insect.humanReadableId}`}
          className="btn btn-lg btn-tertiary mr-1.5"
          onClick={e => {
            e.stopPropagation();
            prepareInsectUpdate(insect)
          }}
        >
          <i className="fas fa-pencil-alt"></i> Edit
        </Link>
        <button
          className="btn btn-lg btn-tertiary-border text-white"
          onClick={e => {
            e.stopPropagation();
            handleDeleteInsect()
          }}
        >
          <i className="fas fa-trash-alt"></i> Delete
        </button>
      </div>
      <div className="individual-insect-select ml-auto ml-lg-0 mr-1.5 mt-1.25">
        <div className="form-group custom-control custom-checkbox text-white">
          <input
            type="checkbox"
            className="custom-control-input"
            id={`select-insect-${insect.humanReadableId}`}
            checked={checked === undefined ? false : checked}
            // value={}
            onChange={handleSelectInsect}
            onClick={e => e.stopPropagation()}
          />
          <label
            className="custom-control-label"
            htmlFor={`select-insect-${insect.humanReadableId}`}
            onClick={e => e.stopPropagation()}
          ></label>
        </div>
      </div>
    </div>
  );
};

IndividualInsect.propTypes = {
  insect: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  onSelectInsect: PropTypes.func.isRequired,
  onClickInsect: PropTypes.func.isRequired,
  onDeleteInsect: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  prepareInsectUpdate: insect => dispatch(prepareInsectUpdate(insect))
});

export default connect(
  null,
  mapDispatchToProps
)(IndividualInsect);
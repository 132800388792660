const states = [{
    "name": "alabama",
    "abbreviation": "AL"
}, {

    "name": "alaska",
    "abbreviation": "AK"
}, {

    "name": "american samoa",
    "abbreviation": "AS"
}, {

    "name": "arizona",
    "abbreviation": "AZ"
}, {

    "name": "arkansas",
    "abbreviation": "AR"
}, {

    "name": "california",
    "abbreviation": "CA"
}, {

    "name": "colorado",
    "abbreviation": "CO"
}, {

    "name": "connecticut",
    "abbreviation": "CT"
}, {

    "name": "delaware",
    "abbreviation": "DE"
}, {

    "name": "district of columbia",
    "abbreviation": "DC"
}, {

    "name": "florida",
    "abbreviation": "FL"
}, {

    "name": "georgia",
    "abbreviation": "GA"
}, {

    "name": "guam",
    "abbreviation": "GU"
}, {

    "name": "hawaii",
    "abbreviation": "HI"
}, {

    "name": "idaho",
    "abbreviation": "ID"
}, {

    "name": "illinois",
    "abbreviation": "IL"
}, {

    "name": "indiana",
    "abbreviation": "IN"
}, {

    "name": "iowa",
    "abbreviation": "IA"
}, {

    "name": "kansas",
    "abbreviation": "KS"
}, {

    "name": "kentucky",
    "abbreviation": "KY"
}, {

    "name": "louisiana",
    "abbreviation": "LA"
}, {

    "name": "maine",
    "abbreviation": "ME"
}, {

    "name": "maryland",
    "abbreviation": "MD"
}, {

    "name": "massachusetts",
    "abbreviation": "MA"
}, {

    "name": "michigan",
    "abbreviation": "MI"
}, {

    "name": "minnesota",
    "abbreviation": "MN"
}, {

    "name": "mississippi",
    "abbreviation": "MS"
}, {

    "name": "missouri",
    "abbreviation": "MO"
}, {

    "name": "montana",
    "abbreviation": "MT"
}, {

    "name": "nebraska",
    "abbreviation": "NE"
}, {

    "name": "nevada",
    "abbreviation": "NV"
}, {

    "name": "new hampshire",
    "abbreviation": "NH"
}, {

    "name": "new jersey",
    "abbreviation": "NJ"
}, {

    "name": "new mexico",
    "abbreviation": "NM"
}, {

    "name": "new york",
    "abbreviation": "NY"
}, {

    "name": "north carolina",
    "abbreviation": "NC"
}, {

    "name": "north dakota",
    "abbreviation": "ND"
}, {

    "name": "northern mariana is",
    "abbreviation": "MP"
}, {

    "name": "ohio",
    "abbreviation": "OH"
}, {

    "name": "oklahoma",
    "abbreviation": "OK"
}, {

    "name": "oregon",
    "abbreviation": "OR"
}, {

    "name": "pennsylvania",
    "abbreviation": "PA"
}, {

    "name": "puerto rico",
    "abbreviation": "PR"
}, {

    "name": "rhode island",
    "abbreviation": "RI"
}, {

    "name": "south carolina",
    "abbreviation": "SC"
}, {

    "name": "south dakota",
    "abbreviation": "SD"
}, {

    "name": "tennessee",
    "abbreviation": "TN"
}, {

    "name": "texas",
    "abbreviation": "TX"
}, {

    "name": "utah",
    "abbreviation": "UT"
}, {

    "name": "vermont",
    "abbreviation": "VT"
}, {

    "name": "virginia",
    "abbreviation": "VA"
}, {

    "name": "virgin islands",
    "abbreviation": "VI"
}, {

    "name": "washington",
    "abbreviation": "WA"
}, {

    "name": "west virginia",
    "abbreviation": "WV"
}, {

    "name": "wisconsin",
    "abbreviation": "WI"
}, {

    "name": "wyoming",
    "abbreviation": "WY"
}];

const getStateByName = name =>
    states.filter(state => 
        state.name.toLowerCase().includes(name.toLowerCase()) ||
        name.toLowerCase().includes(state.name.toLowerCase())
    );

export default getStateByName;
import './index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'reactstrap';

/**
 * Displays an fixed position alert at the bottom of the screen for a set amount of time
 * @param {Object} children The react children
 * @param {String} color The bootstrap 4 color of the alert
 * @param {Boolean} isOpen A boolean determining whether the alert should currently be open or not
 * @param {Func} changeIsOpen A function that accepts a boolean and changes the visibility of the alert
 * @param {Number} visibleTime The number of seconds the alert should remain visible
 */
const AsyncAlert = ({
  children,
  color,
  isOpen,
  changeIsOpen,
  visibleTime
}) => {
  const [ timer, setTimer ] = useState(null);

  useEffect(() => {
    let timer;
    if (timer) {clearTimeout(timer);}
    if (isOpen) timer = setTimer(setTimeout(() => changeIsOpen(false), visibleTime * 1000));
    return () => clearTimeout(timer);
  }, [isOpen]);

  // console.log('async alert ' + color + ' is open:', typeof isOpen, isOpen)

  return (
    <Alert
      className="async-alert"
      color={color}
      fade={true}
      isOpen={isOpen}
      toggle={() => changeIsOpen(false)}
    >
      {children}
    </Alert>
  )
};

AsyncAlert.propTypes = {
  color: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  changeIsOpen: PropTypes.func.isRequired,
  visibleTime: PropTypes.number
};

AsyncAlert.defaultProps = {
  visibleTime: 5
};

export default AsyncAlert;
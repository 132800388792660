import './index.css';

import React from 'react';
import PropTypes from 'prop-types';

const FeatureSectionText = ({
  headerText,
  subText,
  textColor
}) => {
  return (
    <div style={{ color: textColor }}>
      <h2 className="feature-header pb-1.5 pb-md-3">{headerText}</h2>
      <p className="feature-subtext" dangerouslySetInnerHTML={{ __html: subText }}/>
    </div>
  );
};

FeatureSectionText.propTypes = {
  headerText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  textColor: PropTypes.string
};

FeatureSectionText.defaultProps = {
  textColor: 'black'
};

export default FeatureSectionText;
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LoadingCircle = ({ className, text }) => {
  return (
    <div className={`text-center ${className}`}>
      <i className="fas fa-circle-notch fa-spin fa-5x"></i>
      <p>{text}</p>
    </div>
  );
};

LoadingCircle.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string
};

export default LoadingCircle;
import React, { Component, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './index.css';

/**
 * Creates a property that validates itself based on bootstrap
 * @param {Object} componentRef The react reference for the html element
 * @param {String} errorMessage Message to display underneath the input of value isn't valid
 * @param {String} errorColor The hex-format color of the alert message if invalid input
 * @param {Boolean} formSubmitted Indicates whether the form containing this input has been submitted
 * @param {String} id Input ID
 * @param {String} inputBackground The CSS color to apply to the input background
 * @param {Function} inputValidator Function that is given the field's current value and should return true if input is valid
 * @param {Function} onChange Function to execute on input change
 * @param {Function} onClickPasswordVisibility Handles any additional action for the input (like changing the input type from 'password' to 'text')
 * @param {Boolean} passwordVisibilityButton Indicates whether a password visibility button should be displayed in the input
 * @param {String} placeholder HTML placeholder
 * @param {Boolean} resetInputForgiveness Boolean THAT SHOULD CHANGE indicating whether the input forgiveness should be set to true (useful for forms that use asynchronous alerts on success)
 * @param {String} successMessage Message to display underneath the input if the value IS valid
 * @param {String} type Input type
 * @param {String} value The current value of the input to be managed by react state
 */
const ValidationInput = ({
  componentRef,
  errorMessage,
  errorColor,
  formSubmitted,
  id,
  inputBackground,
  inputValidator,
  onChange,
  onClickPasswordVisibility,
  passwordVisibilityButton,
  resetInputForgiveness,
  successMessage,
  type,
  value,
  ...restOfInputProps
}) => {
  const [ inputValue, setInputValue ] = useState(null);
  const [ validValue, setValidValue ] = useState(null);
  const [ className, setClassName ] = useState('form-control');
  const [ inputFocus, setInputFocus ] = useState(false);
  const [ passwordVisibility, setPasswordVisibility ] = useState('fa-eye');

  // prevents error message display if a user clicks into a field and immediately out without typing anything
  const [ inputForgiveness, setInputForgiveness ] = useState(true);
  const [ previousFormSubmissionState, setPreviousFormSubmissionState ] = useState(null);

  useEffect(() => {
    if (formSubmitted !== previousFormSubmissionState) {
      setPreviousFormSubmissionState(formSubmitted);
      // This state should indicate that the form has been successfully submitted
      if (formSubmitted === false && previousFormSubmissionState === true) {
        setValidValue(null);
        setClassName('form-control');
        setInputForgiveness(true);
      }
    } 
  }, [formSubmitted, previousFormSubmissionState]);

  useEffect(() => {
    // console.log('inputValidator function:', typeof inputValidator)
    // console.log('useEffect input value:', inputValue)
    // console.log('input focus:', inputFocus)
    if (formSubmitted) {
      setInputForgiveness(false);
    }
    
    // set internal state based on user input
    if (inputValue !== null && !inputFocus && inputForgiveness) {
      setInputForgiveness(false);
    }
    // console.log('input forgiveness:', inputForgiveness);
    if (! inputForgiveness && inputValidator(value)) {
      setValidValue(true);
      setClassName('form-control valid-input');
    } else if (! inputForgiveness) {
      // console.log('validation input:', inputValue, inputValidator(inputValue))
      setValidValue(false);
      setClassName('form-control invalid-input');
    } 
  }, [inputValue, inputFocus, inputForgiveness, formSubmitted, value]);

  const handlePasswordVisibilityClick = e => {
    e.preventDefault();
    setPasswordVisibility(passwordVisibility === 'fa-eye' ? 'fa-eye-slash' : 'fa-eye');
    if (onClickPasswordVisibility && typeof onClickPasswordVisibility === 'function') {
      onClickPasswordVisibility();
    }
  }

  // console.log('className:', className)
  // console.log('valid value:', validValue)
  // console.log('password visibility:', passwordVisibility)

  let Input = 'input';
  if (type === 'textarea') {
    Input = 'textarea';
  }
  // console.log(`standard input valid (${id}):`, validValue);

  return (
    <div>
      <div className="input-container">
        <Input
          type={type}
          value={value}
          className={`text-dark ${className}`}
          id={id}
          onChange={e => {
            // console.log('input value:', e.target.value)
            onChange(e);
            setInputValue(e.target.value)
          }}
          onFocus={() => setInputFocus(true)}
          onBlur={() => setInputFocus(false)}
          ref={componentRef}
          style={{
            backgroundColor: inputBackground || ' #B7B7B7',
            borderColor: inputBackground || '#B7B7B7'
          }}
          {...restOfInputProps}
        />
        {passwordVisibilityButton && (
          <button
            type="button"
            className="password-visibility"
            onClick={handlePasswordVisibilityClick}
          >
            <i className={`far ${passwordVisibility}`}></i>
          </button>
        )}
      </div>
      {validValue === false && (
        <div className="error-feedback" style={{ color: errorColor || '#ffb9af'}}>
          {errorMessage}
        </div>
      )}
    </div>
  );
}

ValidationInput.propTypes = {
  errorMessage: PropTypes.string.isRequired,
  errorColor: PropTypes.string,
  formSubmitted: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inputBackground: PropTypes.string,
  inputValidator: PropTypes.func.isRequired,  // returns true if input is valid
  onChange: PropTypes.func.isRequired,
  onClickPasswordVisibility: PropTypes.func,
  passwordVisibilityButton: PropTypes.bool,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};


export default ValidationInput;
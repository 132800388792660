import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

const PersonProfile = ({
  imgSrc,
  name,
  className
}) => {
  // console.log('person profile image source:', imgSrc)
  return (
    <div className={`person-profile ${className}`}>
      <div style={{ backgroundImage: `url('${imgSrc}')` }} className="profile-img mr-5"></div>
      <p className="profile-name">{name}</p>
    </div>
  );
};

PersonProfile.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string
};

export default PersonProfile;
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { parseFullName } from 'parse-full-name';
import { Link as AnchorLink } from 'react-scroll';

// css
import './index.css';

// images
import InsectImg from './images/Blue Dragonfly.compressed-compressed.jpg';

// redux
import {
  addInsect,
  resetAddInsect,
  prepareInsectUpdate,
  clearInsectPendingUpdateIfNeeded,
  updateInsect,
  updateInsectWithPrimaryImageReplacement
} from '../../state/insects/actions';

// utilities
import {
  computerizedDateFormatter,
  computerizedTimeFormatter,
  getUtcDateTimeString
} from '../../utils/dateFormats';
import fullNameFormatter from '../../utils/nameFormats';
import getCsrfToken from '../../utils/getCsrfToken';
import incrementId from '../../utils/incrementId';
import { handleHttpResponse } from '../../utils/handleFetchHttpErrors';
import detectMobile from '../../utils/detectMobile';

// custom components
import AsyncAlert from '../../components/AsyncAlert';
import InsectImageUploader from './components/InsectImageUploader';
import IdentificationTool from './components/IdentificationTool';
import SideNav from '../../components/SideNav';
import SideNavContainer from '../../components/SideNavContainer';
import SideNavPageContent from '../../components/SideNavPageContent';
import SideNavItem from '../../components/SideNavItem';
import FormSectionHeader from './components/FormSectionHeader';
import StandardFormInput from '../../components/StandardFormInput';
import { Collapse } from 'reactstrap';

const initialInsectFormData = {
  id: '',                             // field value: insect's human-readable ID field value
  finder: '',                         // field value: name of the person that found the insect
  description: '',                    // field value: insect's description
  collectionDate: '',                 // field value: insect's collection date
  collectionTime: '',                 // field value: insect's collection time
  latitude: '',                       // field value: latitude of the insect's collection location
  longitude: '',                      // field value: longitude of the insect's collection location
  locationDescription: '',            // field value: user's collection location description field
  city: '',                           // field value: city in which the insect was collected
  county:'',                          // field value: county in which the insect was collected
  state: '',                          // field value: state in which the insect was collected
  country: '',                        // field value: country in which the insect was collected
  identifier: '',                     // field value: name of the person that identified the insect
  insectImage: null,                  // child component: image of the collected insect
  tsn: '',                            // child component: tsn of the collected image (from itis.gov)
  identificationString: '',           // child component: identification string marking the identification path (from itis.gov)
  identificationStringWithRanks: '',  // child component: identification string with ranks marking identification path (from itis.gov)
  version: 0                          // reset mgt: auto-increments to allow reseting of any self-contained components
};

/**
 * Component controlling the insect entry form, parameters are props coming from redux
 * @param {Object} user The object from redux providing the user information
 * @param {Object} newInsect Information regarding state of new insect submission
 * @param {Func} addInsect Action creator that adds an insect through the API and to the redux state
 */
const InsectEntry = ({
  user,
  newInsect,
  addInsect,
  insectPendingUpdate,
  prepareInsectUpdate,
  updateInsect,
  clearInsectPendingUpdateIfNeeded,
  insectImages,
  updateInsectWithPrimaryImageReplacement
}) => {
  const [ insectFormData, setInsectFormData ] = useState(initialInsectFormData);
  const [ alerts, setAlerts ] = useState({
    insectImage: {
      errorMessage: '',
      visible: false
    },
    addInsect: {
      errorMessage: '',
      visible: false
    },
    updateInsect: {
      errorMessage: '',
      visible: false
    }
  });
  const [ panelOpenStatus, setPanelOpenStatus ] = useState({
    location: false,
    weather: false
  });
  const [ sectionOpenStatus, setSectionOpenStatus ] = useState({
    identification: true,
    capture: ! detectMobile()
  });

  const location = useLocation();
  const editPath = location.pathname.search('edit') !== -1;
  const insertPath = location.pathname.search('entry') !== -1;

  const controller = new AbortController();
  const signal = controller.signal;

  useEffect(() => {
    if (! newInsect.isPending && ! newInsect.errorMessage && insertPath) {
      clearInsectPendingUpdateIfNeeded();
      setupNewInsect();
    }
    return () => controller.abort();
  }, [
    newInsect.isPending,
    newInsect.errorMessage,
    location.pathname
  ]);

  useEffect(() => {
    if (editPath) {
      getInsectPendingUpdateDataIfNeeded(location.pathname);
      if (insectPendingUpdate.originalData) setupInsectPendingUpdate();
    }
    return () => controller.abort();
  }, [
    insectPendingUpdate.originalData,
    location.pathname
  ]);

  /**
   * Grabs the current maximum human-readable ID from the API
   * @return {Promisified String} Current maximum human-readable ID from APi
   */
  const getMaximumHumanReadableId = () => (
    fetch('/api/specimens/maximum-human-readable-id', {
      // signal,
      headers: {
        'x-csrf-token': getCsrfToken()
      }
    })
      .then(handleHttpResponse)
      .then(response => response.data)
      .catch(console.error)
  );

  /**
   * Combines the individual parts of a user's name into a single string
   * @param {Object} user Contains the parts of the user's name
   * @return {String} The user's combined string name
   */
  const createStringName = user => ([
      user.firstName,
      user.middleName,
      user.lastName,
      user.suffix
  ].filter(Boolean).join(' '));

  /**
   * Acquires the GPS location of the user if allowed
   * @return {Promisified Object} User's GPS coordinates of the form {
   *    latitude: 34.555,
   *    longitude: -83.4876
   * }
   */
  const getGps = () => new Promise((resolve, reject) => {
    const nullCoords = {
      latitude: '',
      longitude: ''
    };
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        // object containing { latitude, logitude }
        resolve(position.coords);

      // Can't get position from system
      }, error => {
        console.error('GPS coord error: ', error);
        resolve(nullCoords);
      })

    // Browser does not support GPS coord acquisition
    } else {
      resolve(nullCoords);
    }
  });

  /**
   * Gets the name of the location from the GPS coordinates
   * @param {Object} position GPS coordinates of the form {latitude: 34.555, longitutde: -83.453 }
   * @return {Promisified Object} The named location of the user
   */
  const reverseGeoCode = position => {
    if (position) {
      return fetch(
        `https://nominatim.openstreetmap.org/reverse?lat=${position.latitude}&lon=${position.longitude}&format=json`,
        {
          // signal
        }  
      )
        .then(handleHttpResponse)
        .then(response => ({
          city: response.address.city || '',
          county: response.address.county || '',
          state: response.address.state || '',
          country: response.address.country || ''
        }));
    } else {
      return Promise.resolve({
        city: '',
        county: '',
        state: '',
        country: ''
    });
    }
  };

  /**
   * Gets the full location of the user by grabbing GPS coords and then a named position
   * @return {Promisified Object} The full position of the user in the form {
   *      latitude: '',
   *      longitude: ''
   *      city: '',
   *      county: '',
   *      state: '',
   *      country: ''
   * }
   */
  const getLocation = () => {
    let gpsCoords = {};
    return getGps()
      .then(acquiredGpsCoords => gpsCoords = acquiredGpsCoords)
      .then(() => reverseGeoCode(gpsCoords))
      .then(namedPosition => Promise.resolve({
        ...namedPosition,
        latitude: gpsCoords.latitude,
        longitude: gpsCoords.longitude
      }))
  }

  /**
   * Sets up a new insect for insertion by getting default data and clearing any old data
   *    NOTE:  this function also updates the state
   * @return {Void}
   */
  const setupNewInsect = () => {
    let newInsectFormData = initialInsectFormData;
    // auto-set insect ID
    return getMaximumHumanReadableId()
      .then(maximumHumanReadableId => {
        newInsectFormData = {
          ...newInsectFormData,
          id: maximumHumanReadableId ? incrementId(maximumHumanReadableId) : 'AAA001'
        };
      })
      // .then(() => console.log('new form state after ID set:', newInsectFormData))

      // auto-set finder and identifier to collector name
      .then(() => createStringName(user))
      .then(collectorName => newInsectFormData = {
        ...newInsectFormData,
        finder:collectorName,
        identifier: collectorName
      })
      // .then(() => console.log('new form state after name set:', newInsectFormData))

      // GPS and address
      .then(() => getLocation())
      .then(fullPosition => newInsectFormData = {
        ...newInsectFormData,
        latitude: fullPosition.latitude,
        longitude: fullPosition.longitude,
        city: fullPosition.city,
        county: fullPosition.county,
        state: fullPosition.state,
        country: fullPosition.country
      })
      // .then(() => console.log('new form state after location set:', newInsectFormData))
      // Set date and time
      .then(() => {
        const currentDateTime = new Date();
        newInsectFormData = {
          ...newInsectFormData,
          collectionDate: `${computerizedDateFormatter(currentDateTime)}`,
          collectionTime: `${computerizedTimeFormatter(currentDateTime)}`  
        }
      })
      // .then(() => console.log('new form state immediately before setting it:', newInsectFormData))
      .then(() => setInsectFormData(prevState => ({
        ...prevState,
        ...newInsectFormData,
        version: prevState.version + 1
      })));
  };

  /**
   * Sets up insect data for updating an insect by tapping into the information from 
   *    the redux state:  insectPendingUpdate.originalData
   *    NOTE:  this function updates the state
   * @return {Void}
   */
  const setupInsectPendingUpdate = () => {
    const { originalData: originalInsectData } = insectPendingUpdate;
    setInsectFormData(prevState => ({
      ...prevState,
      id: originalInsectData.humanReadableId || '',
      finder: fullNameFormatter(
        originalInsectData.finderFirstName,
        originalInsectData.finderMiddleName,
        originalInsectData.finderLastName,
        originalInsectData.finderSuffix
      ) || '',
      description: originalInsectData.description || '',
      collectionDate: originalInsectData.collectionDate
        ? computerizedDateFormatter(originalInsectData.collectionDate)
        : '',
      collectionTime: originalInsectData.collectionDate
        ? computerizedTimeFormatter(originalInsectData.collectionDate)
        : '',
      latitude: originalInsectData.latitude || '',
      longitude: originalInsectData.longitude || '',
      locationDescription: originalInsectData.locationDescription || '',
      city: originalInsectData.city || '',
      county:originalInsectData.county || '',
      state: originalInsectData.state || '',
      country: originalInsectData.country || '',
      identifier: fullNameFormatter(
        originalInsectData.identifierFirstName,
        originalInsectData.identifierMiddleName,
        originalInsectData.identifierLastName,
        originalInsectData.identifierSuffix
      ) || '',
      tsn: originalInsectData.tsn || '',
      identificationString: originalInsectData.classificationString || '',
      identificationStringWithRanks: originalInsectData.classificationStringWithRanks || ''
    }));
  }

  /**
   * Determines if data from an insect scheduled for an update is needed based on the pathname
   *    its absence and then acquires data
   * @param {String} pathname The current pathname of the route/page
   */
  const getInsectPendingUpdateDataIfNeeded = pathname => {
    const editPath = pathname.search('edit') !== -1;
    if (editPath && (! insectPendingUpdate || ! insectPendingUpdate.originalData)) {
      const humanReadableId = pathname.split('/').slice(-1)[0];
      return fetch(`/api/specimens/${humanReadableId}`, {
        // signal,
        headers: {
          'x-csrf-token': getCsrfToken()
        }
      })
        .then(response => response.json())
        .then(response => {
          prepareInsectUpdate(response.data[0]);
        })
    } else {
      return Promise.resolve();
    }
  };

  /**
   * Calculates whether an alert should be present or not based on redux data
   * @param {Number} localVisible The number of times that the parent has been rendered
   * @param {Array} reduxRequestObjectArray Contains all of the redux request objects, expecting 'isPending' and 'errorMessage' properties
   * @param {Boolean} successAlert Whether or not the alert in question is a success alert or not
   * @return {Boolean} Whether or not the alert should display
   */
  const calculateAlertVisibilityFromReduxData = (localVisible, reduxRequestObjectArray, successAlert) => {
    if (localVisible) {
      let errorsPresent = 0;
      // if any requests are pending, don't show alert
      for (let i = 0; i < reduxRequestObjectArray.length; i++) {
        if (reduxRequestObjectArray[i].isPending) {
          return false;
        }
      }
      // computer error message
      for (let i = 0; i < reduxRequestObjectArray.length; i++) {
        if (Boolean(reduxRequestObjectArray[i].errorMessage)) {
          errorsPresent++;
        }
      }

      if (successAlert) {
        return errorsPresent > 0
          ? false
          : true;
      } else {
        return errorsPresent > 0
          ? true
          : false;
      }
    } else {
      return false;
    }
  }

  /**
   * Compiles the insect update errors into one message
   */
  const createUpdateInsectErrorMessage = () => {
    let messageArray = [];
    if (insectPendingUpdate.errorMessage) {
      messageArray.push('Error updating data: ' + insectPendingUpdate.errorMessage);
    }
    if (insectImages.deleteStatus.errorMessage) {
      messageArray.push('Error deleting old image: ' + insectImages.deleteStatus.errorMessage);
    }
    if (insectImages.addStatus.errorMessage) {
      messageArray.push('Error adding image: ' + insectImages.addStatus.errorMessage);
    }
    return messageArray.join('; ');
  }

  /**
   * Handles the dismissal of an alert by changing the state of component
   * @param {String} alertName The name of the alert to dismiss, linked to the 'alerts' object state
   * @return {Void}
   */
  const handleInsectAlertToggle = alertName => boolean => {
    setAlerts(prevState => ({
      ...prevState,
      [alertName]: {
        ...prevState[alertName],
        visible: boolean
      }
    }));
  };

  /**
   * Handles the change to the state when a user uploads an image
   * @param {ByteStream} file The file uploaded to the browser
   * @return {Void} (changes state)
   */
  const handleAddInsectImage = file => {
    setInsectFormData(prevState => ({
      ...prevState,
      insectImage: file
    }));
  }

  /**
   * Handles errors thrown by the image upload system
   * @param {String} errorMessage The error message from the image upload system
   * @return {Void}
   */
  const handleImgUploadError = errorMessage => {
    setAlerts(prevState => ({
      ...prevState,
      insectImage: {
        errorMessage,
        visible: true
      }
    }))
  }

  /**
   * Updates the state with form field changes
   * @param {Object} e The event object of the change, used for getting the field ID and the current value
   * @return {Void} (Sets state)
   */
  const handleInsectFormFieldChange = e => {
    // e.preventDefault();
    e.persist();
    setInsectFormData(prevState => ({
      ...prevState,
      [e.target.id]: e.target.value
    }));
  };

  /**
   * Toggles the open/close state of the location and weather panels
   * @param {String} panel The name of panel according to the state (i.e. this is the property that the state keeps the panel's status in)
   * @return {Function} A function that takes an event object that can be called when a panel header is clicked on
   */
  const handlePanelToggle = panel => e => {
    e.preventDefault();
    setPanelOpenStatus(prevState => ({
      ...prevState,
      [panel]: !panelOpenStatus[panel]
    }));
  };

  /**
   * Toggles the different sections open and closed
   * @param {String} section The name of the section to toggle open and closed
   * @return {Function} A function that takes an event object to be given to the event handler 
   */
  const handleSectionToggle = section => e => {
    e.preventDefault();
    // console.log('toggle section');
    setSectionOpenStatus(prevState => ({
      ...prevState,
      [section]: !sectionOpenStatus[section]
    }));
  }

  /**
   * Handles the state change associated with the user interacting with the identification tool
   * @param {Object} identificationObject Contains insect identification information for submission to the API
   *    of the form { tsn: 1234, identificationString: 'Animalia$Bilateria$Protostomia$...'}
   * @return {Void} (changes state)
   */
  const handleSelectInsectIdentification = identificationObject => {
    setInsectFormData(prevState => ({
      ...prevState,
      tsn: identificationObject.tsn,
      identificationString: identificationObject.identificationString,
      identificationStringWithRanks: identificationObject.identificationStringWithRanks
    }));
  }

  /**
   * Transforms all insectFormData (state) into into an http FormData() object for submission
   * @return {Object} FormData() object containing all set insectFormData
   *    EXCEPT the human-readable ID (set conditionally as needed)
   */
  const setupInsectHttpFormData = () => {
    const finderName = parseFullName(insectFormData.finder);
    const identifierName = parseFullName(insectFormData.identifier);
    // send null data by not sending it :P 
    let insectHttpFormData = new FormData();
    if (insectFormData.id) insectHttpFormData.append('humanReadableId', insectFormData.id);
    if (insectFormData.city)insectHttpFormData.append('city', insectFormData.city);
    if (insectFormData.identificationString) insectHttpFormData.append('classificationString', insectFormData.identificationString);
    if (insectFormData.identificationString) insectHttpFormData.append('classificationStringWithRanks', insectFormData.identificationStringWithRanks);
    if (insectFormData.collectionTime) insectHttpFormData.append('collectionDate', getUtcDateTimeString(insectFormData.collectionDate + 'T' + insectFormData.collectionTime));
    if (insectFormData.country) insectHttpFormData.append('country', insectFormData.country);
    if (insectFormData.description) insectHttpFormData.append('description', insectFormData.description);
    if (finderName.first) insectHttpFormData.append('finderFirstName', finderName.first);
    if (finderName.middle) insectHttpFormData.append('finderMiddleName', finderName.middle);
    if (finderName.last) insectHttpFormData.append('finderLastName', finderName.last);
    if (finderName.suffix) insectHttpFormData.append('finderSuffix', finderName.suffix);
    if (identifierName.first) insectHttpFormData.append('identifierFirstName', identifierName.first);
    if (identifierName.middle) insectHttpFormData.append('identifierMiddleName', identifierName.middle);
    if (identifierName.last) insectHttpFormData.append('identifierLastName', identifierName.last);
    if (identifierName.suffix) insectHttpFormData.append('identifierSuffix', identifierName.suffix);
    if (insectFormData.latitude) insectHttpFormData.append('latitude', insectFormData.latitude);
    if (insectFormData.locationDescription) insectHttpFormData.append('locationDescription', insectFormData.locationDescription);
    if (insectFormData.longitude) insectHttpFormData.append('longitude', insectFormData.longitude);
    if (insectFormData.state) insectHttpFormData.append('state', insectFormData.state);
    if (insectFormData.tsn) insectHttpFormData.append('tsn', insectFormData.tsn);
    if (insectFormData.insectImage) insectHttpFormData.append('images', insectFormData.insectImage);

    return insectHttpFormData;
  }

  /**
   * Handles the redux and local state changes associated with a user submitting the form
   *    to add an insect to the API
   * @param {Object} e Event object from user submitting form to add insect
   */
  const handleAddInsect = e => {
    e.preventDefault();
    let insectHttpFormData = setupInsectHttpFormData();
    addInsect(insectHttpFormData)
      .finally(() => setAlerts(prevState => ({
        ...prevState,
        addInsect: {
          ...prevState[addInsect],
          visible: true
        }
      })));
  }

  /**
   * Handles the state changes associated with a user updating an insect
   * @param {Object} e The event object containing information about the form submission
   * @return {Void} 
   */
  const handleUpdateInsect = e => {
    e.preventDefault();
    let insectHttpFormData = setupInsectHttpFormData();

    let images = insectPendingUpdate.originalData.images;
    let currentImageId = images.length
          ? images.filter(image => image.primaryImage === 1)[0].imageId
          : null;

    let updateInsectPromise;
    if (insectFormData.insectImage) {
      updateInsectPromise = updateInsectWithPrimaryImageReplacement(
        insectFormData.id,
        currentImageId,
        insectHttpFormData
      );
    } else {
      updateInsectPromise = updateInsect(insectFormData.id, insectHttpFormData);
    }

    return updateInsectPromise
      .finally(() => setAlerts(prevState => ({
        ...prevState,
        updateInsect: {
          ...prevState[updateInsect],
          visible: true
        }
      })));
  };
  
  // console.log('insect entry form state:', { insectFormData, alerts, panelOpenStatus });

  return (
    <SideNavContainer>
      <SideNav>
        <ul className="side-nav-list">
          <li>
            <SideNavItem
              to="identification-section"
              offset={-100}
              activeClass="active-side-nav-item"
              className=""
            >
              Identification Data
            </SideNavItem>
          </li>
          <li>
            <SideNavItem
              to="capture-section"
              offset={-100}
              activeClass="active-side-nav-item"
              className=""
            >
              Capture Data
            </SideNavItem>
            <ul>
              <li>
                <SideNavItem
                  to="date-and-time-section"
                  offset={-100}
                  activeClass="active-side-nav-item"
                  className=""
                >
                  Date and Time
                </SideNavItem>
              </li>
              <li>
                <SideNavItem
                  to="location-section"
                  offset={-100}
                  activeClass="active-side-nav-item"
                  className=""
                >
                  Location
                </SideNavItem>
              </li>
              <li>
                <SideNavItem
                  to="other-section"
                  offset={-100}
                  activeClass="active-side-nav-item"
                  className=""
                >
                  Other
                </SideNavItem>
              </li>
            </ul>
          </li>

        </ul>
      </SideNav>

      <SideNavPageContent>
        <h1>Insect Entry</h1>
        <form onSubmit={e => e.preventDefault()}>

          {/* Identification Information */}
          <section id="identification-section">
            <FormSectionHeader
              onClick={handleSectionToggle('identification')}
              sectionIsOpen={sectionOpenStatus.identification}
            >
              Identification Data
            </FormSectionHeader>

            <Collapse
              isOpen={sectionOpenStatus.identification}
            >
              {/* Insect Image */}
              <InsectImageUploader
                key={insectFormData.version}
                className="mt-0.5 mb-1.5 mb-md-1.75"
                backgroundImage={InsectImg}
                onError={handleImgUploadError}
                setInsectImage={handleAddInsectImage}
                defaultImage={
                  insectPendingUpdate &&
                  insectPendingUpdate.originalData &&
                  insectPendingUpdate.originalData.images.length > 0
                    ? `/api/specimens/${insectPendingUpdate.originalData.humanReadableId}/images/${insectPendingUpdate.originalData.images.filter(image => image.primaryImage == 1)[0].imageId}/raw`
                    : null
                }
              />

              {/* Description */}
              <StandardFormInput
                label="Description"
                type="text"
                autoComplete="off"
                id="description"
                value={insectFormData.description}
                onChange={handleInsectFormFieldChange}
              />

              {/* Identification Tool */}
              <IdentificationTool
                key={insectFormData.version + 1}
                setIdentification={handleSelectInsectIdentification}
                startingTsn={
                  insectPendingUpdate &&
                  insectPendingUpdate.originalData
                    ? insectPendingUpdate.originalData.tsn
                    : null
                }
              />

              {/* Identifier */}
              <StandardFormInput
                label="Identifier"
                type="text"
                autoComplete="off"
                id="identifier"
                value={insectFormData.identifier}
                onChange={handleInsectFormFieldChange}
              />
            </Collapse>
          </section>

          <section id="capture-section">
            <FormSectionHeader
              // id="capture-section"
              onClick={handleSectionToggle('capture')}
              sectionIsOpen={sectionOpenStatus.capture}
            >
              Capture Data
            </FormSectionHeader>

            <Collapse
              id="capture-section"
              isOpen={sectionOpenStatus.capture}
            >
              <section id="date-and-time-section">
                <h3 className="form-section-header">Date and Time</h3>
                <StandardFormInput
                  label="Collection Date"
                  type="date"
                  id="collectionDate"
                  autoComplete="off"
                  value={insectFormData.collectionDate}
                  onChange={handleInsectFormFieldChange}
                />

                <StandardFormInput
                  label="Collection Time"
                  type="time"
                  id="collectionTime"
                  autoComplete="off"
                  value={insectFormData.collectionTime}
                  onChange={handleInsectFormFieldChange}
                />
              </section>

              <section id="location-section">
                <h3 id="location-section" className="form-section-header mt-4.5">Location</h3>
                <StandardFormInput
                  label="Description"
                  type="text"
                  id="locationDescription"
                  autoComplete="off"
                  value={insectFormData.locationDescription}
                  onChange={handleInsectFormFieldChange}
                />

                <StandardFormInput
                  label="GPS Latitude"
                  type="text"
                  id="latitude"
                  autoComplete="off"
                  value={insectFormData.latitude}
                  onChange={handleInsectFormFieldChange}
                />

                <StandardFormInput
                  label="GPS Longitude"
                  type="text"
                  id="longitude"
                  autoComplete="off"
                  value={insectFormData.longitude}
                  onChange={handleInsectFormFieldChange}
                />

                <StandardFormInput
                  label="City"
                  type="text"
                  id="city"
                  autoComplete="off"
                  value={insectFormData.city}
                  onChange={handleInsectFormFieldChange}
                />

                <StandardFormInput
                  label="County"
                  type="text"
                  id="county"
                  autoComplete="off"
                  value={insectFormData.county}
                  onChange={handleInsectFormFieldChange}
                />

                <StandardFormInput
                  label="State"
                  type="text"
                  id="state"
                  autoComplete="off"
                  value={insectFormData.state}
                  onChange={handleInsectFormFieldChange}
                />

                <StandardFormInput
                  label="Country"
                  type="text"
                  id="country"
                  autoComplete="off"
                  value={insectFormData.country}
                  onChange={handleInsectFormFieldChange}
                />


                {/* Weather Information */}
                {/* <FormPanel
                  icon={<i className="fas fa-cloud-sun-rain"></i>}
                  header="Weather"
                  onClick={handlePanelToggle('weather')}
                  isOpen={panelOpenStatus.weather}
                >
                  <div className="form-group">
                    <label htmlFor="weather-clouds">Clouds</label>
                    <select className="form-control" id="weather-clouds">
                      <option>N</option>
                      <option>S</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="weather-precipitation">Precipitation</label>
                    <select className="form-control" id="weather-precipitation">
                      <option>N</option>
                      <option>S</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <label htmlFor="weather-Temperature">Temperature</label>
                    <div className="input-group">
                      <input type="number" className="form-control" aria-label="temperature" />
                      <div className="input-group-append">
                        <span className="input-group-text">&#8457;</span>
                      </div>
                    </div>
                  </div>
                </FormPanel> */}
              </section>

              <section id="other-section">
                <h3 className="form-section-header mt-4.5">
                  Other
                </h3>

                {/* ID */}
                <StandardFormInput
                  label="ID"
                  type="text"
                  id="id"
                  autoComplete="off"
                  value={insectFormData.id}
                  onChange={handleInsectFormFieldChange}
                />

                {/* Finder */}
                <StandardFormInput
                  label="Finder"
                  type="text"
                  id="finder"
                  autoComplete="off"
                  value={insectFormData.finder}
                  onChange={handleInsectFormFieldChange}
                />
              </section>
            </Collapse>
          </section>

          {editPath && (
            <button
              type="submit"
              className="insect-entry-submit-btn btn btn-lg btn-primary my-1.5"
              disabled={
                insectPendingUpdate.isPending ||
                insectImages.addStatus.isPending ||
                insectImages.deleteStatus.isPending
              }
              onClick={handleUpdateInsect}
            >
              Update Insect {insectPendingUpdate.isPending && <i className="fas fa-circle-notch fa-spin"></i>}
            </button>
          ) || (
            <button
              type="submit"
              className="insect-entry-submit-btn btn btn-lg btn-primary my-1.5"
              disabled={newInsect.isPending}
              onClick={handleAddInsect}
            >
              Add Insect {newInsect.isPending && <i className="fas fa-circle-notch fa-spin"></i>}
            </button>
          )}
        </form>

        {/* Image Upload error */}
        <AsyncAlert
          color="danger"
          isOpen={alerts.insectImage.visible && Boolean(alerts.insectImage.errorMessage)}
          changeIsOpen={handleInsectAlertToggle('insectImage')}
          visibleTime={5}
        >
          <span className="fa-stack" style={{verticalAlign: 'middle'}}><i className="fas fa-bug fa-stack-1x"></i><i className="fas fa-ban fa-stack-2x"></i></span> {alerts.insectImage.errorMessage}
        </AsyncAlert>

        {/* Success and fail alerts for insect addition */}
        <AsyncAlert
          color="success"
          isOpen={calculateAlertVisibilityFromReduxData(alerts.addInsect.visible, [newInsect], true)}
          changeIsOpen={handleInsectAlertToggle('addInsect')}
          visibleTime={5}
        >
          <i className="fas fa-bug"></i> Successfully added new insect!
        </AsyncAlert>
        <AsyncAlert
          color="danger"
          isOpen={calculateAlertVisibilityFromReduxData(alerts.addInsect.visible, [newInsect])}
          changeIsOpen={handleInsectAlertToggle('addInsect')}
          visibleTime={5}
        >
          <span className="fa-stack" style={{verticalAlign: 'middle'}}><i className="fas fa-bug fa-stack-1x"></i><i className="fas fa-ban fa-stack-2x"></i></span> Failed to add insect: {newInsect.errorMessage}
        </AsyncAlert>

        {/* Success and fail alerts for updating insect */}
        <AsyncAlert
          color="success"
          isOpen={calculateAlertVisibilityFromReduxData(alerts.updateInsect.visible, [
            insectPendingUpdate,
            insectImages.deleteStatus,
            insectImages.addStatus
          ], true)}
          changeIsOpen={handleInsectAlertToggle('updateInsect')}
          visibleTime={5}
        >
          <i className="fas fa-bug"></i> Successfully updated insect!
        </AsyncAlert>
        <AsyncAlert
          color="danger"
          // Basically, show this error message if an error occurs with updating data, deleting an image, or adding an image
          isOpen={calculateAlertVisibilityFromReduxData(alerts.updateInsect.visible, [
            insectPendingUpdate,
            insectImages.deleteStatus,
            insectImages.addStatus
          ])}
          changeIsOpen={handleInsectAlertToggle('updateInsect')}
          visibleTime={5}
        >
          <span className="fa-stack" style={{verticalAlign: 'middle'}}><i className="fas fa-bug fa-stack-1x"></i><i className="fas fa-ban fa-stack-2x"></i></span> {createUpdateInsectErrorMessage()}
        </AsyncAlert>
      </SideNavPageContent>
    </SideNavContainer>
  )
}

InsectEntry.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = ({
  userLoginState: {user},
  insects: {
    collection,
    newInsect,
    insectPendingUpdate,
    insectImages
  }
}) => ({
  user,
  collection,
  newInsect,
  insectPendingUpdate,
  insectImages
});

const mapDispatchToProps = dispatch => ({
  addInsect: insectFormData => dispatch(addInsect(insectFormData)),
  resetAddInsect: () => dispatch(resetAddInsect()),
  prepareInsectUpdate: insect => dispatch(prepareInsectUpdate(insect)),
  updateInsect: (humanReadableId, insectFormData) => dispatch(updateInsect(humanReadableId, insectFormData)),
  updateInsectWithPrimaryImageReplacement: (humanReadableId, currentImageId, formData) => dispatch(updateInsectWithPrimaryImageReplacement(humanReadableId, currentImageId, formData)),
  clearInsectPendingUpdateIfNeeded: () => dispatch(clearInsectPendingUpdateIfNeeded()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsectEntry);
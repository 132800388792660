import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import LoginForm from './components/LoginForm';

// css
import './index.css';
import SmallFormBoxPageContent from '../../components/SmallFormBoxPageContent';
import Alert from '../../components/Alert';

const Login = ({ loginError }) => {
  return (
    <SmallFormBoxPageContent title="Login">
      {loginError && (
        <Alert text={loginError} className="alert-danger" style={{ width: '100%' }} />
      )}
      <LoginForm />
    </SmallFormBoxPageContent>
  );
};

const mapStateToProps = (state) => ({
  loginError: state.userLoginState.errorMessage,
});

export default connect(
  mapStateToProps
)(Login);
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { login } from '../../../../state/login/actions';

import './index.css';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

/**
 * This is a mixed component for now...
 * TODO: make this a non-mixed component
 */


const LoginForm = ({
  dispatch,
  requestPending,
  userLoggedIn
}) => {
  let history = useHistory();
  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/collection' } };

  const email = useRef(null);
  const password = useRef(null);
  const remember = useRef(null);

  useEffect(() => {
    email.current.focus();
  }, []);

  useEffect(() => {
    if (userLoggedIn) {
      history.replace(from);
    }
  }, [
    userLoggedIn
  ]);

  return (
    <div>
      <form
        onSubmit={e => {
          e.preventDefault();
          // prevent blank password entry
          if (!email.current.value.trim() && !password.current.value) {
            return;
          }

          // TODO: handle email submission by detecting pattern
          dispatch(login({
            email: email.current.value.trim(),
            password: password.current.value,
            remember: remember.current.checked
          }))
            .catch(error => {console.log('login error', error)});
        }}
      >
        <div className="form-group security-form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="text"
            autoComplete="email"
            className="form-control"
            id="email"
            aria-describedby="email"
            // placeholder="Enter Username or Email"
            ref={email}
            // autoFocus
          />
        </div>
        
        <div className="form-group security-form-group">
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            autoComplete="current-password"
            className="form-control"
            id="password"
            aria-describedby="password"
            // placeholder="*********"
            ref={password}
          />
        </div>

        <div className="form-group custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id="remember-me"
            ref={remember}
          />
          <label className="custom-control-label" htmlFor="remember-me">Remember me</label>
        </div>

        <button
          type="submit"
          className="btn btn-lg btn-tertiary w-100"
          disabled={requestPending}
        >
          <strong>
            {requestPending && <i className="fas fa-circle-notch fa-spin"></i>} Log In
          </strong>
        </button>
      </form>

      <div className="row mt-1.5">
        <div className="col-6">
          <Link
            to="/password-reset-request"
            className="text-white"
          >
            Forgot Password?
          </Link>
        </div>
        <div className="col-6 text-right">
          <Link
            to="/sign-up"
            className="text-white"
          >
            Create Account
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ userLoginState: { requestPending, userLoggedIn }}) => ({
  requestPending,
  userLoggedIn
})

export default connect(
  mapStateToProps
)(LoginForm);
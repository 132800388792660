import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

/**
 * Intended to be used with <SideNavContainer>
 * e.g.
 * <SideNavContainer>
 *   <SideNav>
 *     <SideNavItem></SideNavItem>
 *   </SideNav>
 *   <SideNavPageContent></SideNavPageContent>
 * </SideNavContainer>
 */
const SideNavPageContent = ({
  children,
  className
}) => {
  return (
    <div className={`col-12 col-lg-9 pl-lg-6 pt-6 pt-sm-7 pt-md-8.5 ${className}`}>
      {children}
    </div>
  );
};

SideNavPageContent.propTypes = {
  className: PropTypes.string
};

export default SideNavPageContent;
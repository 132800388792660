import React, { Component } from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

/**
 * Warning!!!! required to be in row
 */
export default class IndividualData extends Component {
  render() {
    const { dataKey, dataValue } = this.props;

    return (
      <div className="individual-modal-data row">
        <hr className="my-0.75 my-md-1"/>
        <div className="col-5 col-md-4">
          <strong>{dataKey}</strong>
        </div>
        <div className="col-7 col-md-8">
          {dataValue}
        </div>
      </div>
    );
  }
}

IndividualData.propTypes = {
  dataKey: PropTypes.string.isRequired,
  dataValue: PropTypes.string
};
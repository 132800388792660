// promisified version from https://gist.github.com/gf3/132080/da50b34e982bcecf7c1376b1eeed35e9f2aacb19
/**
 * Usage:
 * import promisifiedJsonp from jsonp
 * promisifiedJsonp('https://jsonp.example.com/?parameter=something')
 *    .then(data => doSomething(data))
 */
export default (uniqueId => url => new Promise((resolve, reject) => {
  let script = document.createElement('script');
  let jsonpCallback = "jsonpCallback" + uniqueId++;
  script.src = url + '&json.wrf=' + jsonpCallback;
  window[jsonpCallback] = data => {
    resolve(data);
    script.remove();
    delete window[jsonpCallback];
  }
  document.body.appendChild(script);
}))(0);

export const jsonp = (unique => (url, callback) => {
    // required to forcefully attach the jsonp callback function to the DOM window
    let jsonCallback = 'jsonpCallback' + unique++;
    window[jsonCallback] = function(data) {
      if (window[jsonCallback]) delete window[jsonCallback];
      callback(data);
      script.remove();
    }
    // console.log('json callback:', window[jsonCallback]);
    let script = document.createElement("script");
    script.setAttribute(
      'src',
      url + '&json.wrf=' + jsonCallback
    );
    document.body.appendChild(script);
})(0);
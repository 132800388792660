import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// css
import './index.css';

const SmallFormBox = ({ title, children }) => {
  return (
    <section className="small-form-box-section bg-quinary pt-6 pt-md-10 px-1">
      <div className="small-form-box mx-auto mb-1 text-white py-3 px-1.5">
        <h1 className="mb-1.5">{title}</h1>
        {children}
      </div>
    </section>
  );
};

export default SmallFormBox;
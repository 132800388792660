import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import './index.css';

const CtaSection = () => {
  return (
    <section className="cta-section bg-quaternary">
      <div className="container">
        <hr />
        <div className="row py-3 align-items-center">
          <div className="col-12 col-md-8 text-md-left text-center pb-3 pb-md-0">
            <strong className="cta-message">Start making your insect collecting experience even more enjoyable today!</strong>
          </div>
          <div className="col-12 col-md-4 text-center ">
            <Link to="/sign-up" className="btn btn-lg btn-secondary py-1 px-1.5"><strong>Sign Up Now</strong></Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
import './index.css';
import React, { Component, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  fileSignatureIsImg,
  getImageRotation
} from '../../../../utils/ImageReader';

import detectMobile from '../../../../utils/detectMobile';
import getCsrfToken from '../../../../utils/getCsrfToken';

/**
 * Warning!!!!
 * Depends on Image Reader utilities
 */
const InsectImageUploader = ({
  className,
  backgroundImage,
  onError,
  setInsectImage,
  defaultImage
}) => {
  const [ insectImgPreview, setInsectImgPreview ] = useState(null),
        [ insectImgRotationCorrection, setInsectImgRotation ] = useState(null),
        [ defaultImgSrc, setDefaultImgSrc ] = useState(null);

  useEffect(() => {
    if (defaultImage) {
      fetch(defaultImage, {
        headers: {
          'x-csrf-token': getCsrfToken()
        }
      })
        .then(response => response.blob())
        .then(blob => setDefaultImgSrc(URL.createObjectURL(blob)));
    }
  }, [
    defaultImage
  ]);

  const imgFileInput = useRef();

  /**
   * Redirects the click on the image upload system onto the actual file input
   */
  const simulateFileInputClick = () => {
    imgFileInput.current.click();
  }

  /**
   * Sets the state to contain a byte string of the image URL for preview
   * @param {Object} file The file object from the DOM
   */
  const setImagePreviewState = (file, rotation) => {
    // console.log('attempting image preview');
    let reader = new FileReader();

    reader.addEventListener('load', () => {
      setInsectImgPreview(reader.result);
      setInsectImgRotation((360 - rotation) % 360);
    }, false);

    reader.readAsDataURL(file);
  };

  /**
   * Handles an image upload by user
   * Checks file validity as image and sets state to contain image and preview
   * @param {Object} e The event object for the file input
   */
  const handleFileInput = e => {
    let file = e.target.files[0];

    // sets file for parent state to have access to for form submission
    setInsectImage(file);

    // check if the file signature is an image
    fileSignatureIsImg(file)
      .then(getImageRotation)
      .then(rotation => setImagePreviewState(file, rotation))

      // otherwise, catch the error and trigger the onError event
      .catch(error => {
        if (onError) onError(error);
      });
  };

  let imgUrl,
      imgClass,
      imgRotation = insectImgRotationCorrection !== NaN
        ? insectImgRotationCorrection
        : 0;

  if (insectImgPreview) {
    // console.log('setting custom image')
    imgUrl = insectImgPreview;
    imgClass = 'insect-img-upload';

  } else if (defaultImgSrc) {
      imgUrl = defaultImgSrc;
      imgClass = 'insect-img-upload';

  } else {
    imgUrl = backgroundImage;
    imgClass = 'insect-img-upload insect-img-upload-foreground insect-img-zoom';
  }

  let imgUploadRequest;
  if (insectImgPreview || defaultImgSrc) {
    imgUploadRequest = 'Change Image';
  } else if (detectMobile()) {
    imgUploadRequest = 'Take a Picture';
  } else {
    imgUploadRequest = 'Upload Image';
  }

  return (
    <div className={`upload-img-row ${className}`} onClick={simulateFileInputClick}>
      <div
        className="insect-img-upload-container rounded-circle"
        style={{ transform: `rotate(${imgRotation}deg)` }}
      >
        <div
          className={imgClass}
          style={{ backgroundImage: `url('${imgUrl}')` }}
        ></div>
        {!insectImgPreview && !defaultImgSrc && (<i className="fas fa-camera"></i>)}
      </div>
      <div className="ml-1.5 h3 mb-0">{imgUploadRequest}</div>
      <input
        type="file"
        name="images"
        style={{display: 'none'}}
        ref={imgFileInput}
        onChange={handleFileInput}
      />
    </div>
  );
};

InsectImageUploader.propTypes = {
  className: PropTypes.string,
  backgroundImage: PropTypes.string.isRequired,
  onError: PropTypes.func,        // function called 
  setInsectImage: PropTypes.func, // function for setting image state in parent
  defaultImage: PropTypes.string  // img url
};

export default InsectImageUploader;
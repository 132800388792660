import React from 'react';
import PropTypes from 'prop-types';
import IdentificationCell from '../IdentificationCell';
import LocationCell from '../LocationCell';

const StandardLabelTableRow = ({
  insectRowSet,
  cellsPerInsect,
  cellType
}) => {
  // console.log('insect row set size:', insectRowSet.length)
  return (
    <tr>
      {insectRowSet.map((insect, i) => {
        if (insect.cellType === 'location') {
          return <LocationCell
            key={i}
            insect={insect}
          />;
        } else if (insect.cellType === 'identification') {
          return <IdentificationCell
            key={i}
            insect={insect}
          />;
        }
        // if (cellsPerInsect === 2) {
        //   return (
        //     <React.Fragment
        //       key={i}
        //     >
        //       <LocationCell
        //         key={i}
        //         insect={insect}
        //       />
        //       <IdentificationCell
        //         key={5000 + i}
        //         insect={insect}
        //       />
        //     </React.Fragment>
        //   )
        // } else if (cellType === 'location') {
        //   return <LocationCell
        //     key={i}
        //     insect={insect}
        //   />;
        // } else if (cellType === 'identification') {
        //   return <IdentificationCell
        //     key={i}
        //     insect={insect}
        //   />;
        // }
      })}
    </tr>
  )
};

StandardLabelTableRow.propTypes = {
  insectRowSet: PropTypes.array.isRequired,
  cellsPerInsect: PropTypes.number.isRequired,
  cellType: PropTypes.string
}

export default StandardLabelTableRow;
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import PhoneNewInsectImg from './images/Phone New Insect-compressed.png';
import PhonePictureImg from './images/Phone Picture-compressed.png';
import PhoneCollectionImg from './images/Phone Collection-compressed.png';

import HeroSection from './components/HeroSection';
import FeatureSection from './components/FeatureSection';
import CtaSection from './components/CtaSection';

export default () => {
  return (
    <div>
      <HeroSection />
      <FeatureSection
        className="bg-quinary"
        headerText="Record Your Catch in the Field"
        subText="Rapidly log an insect's capture information with GPS coordinates, location, and datetime stamps added <strong>automatically</strong>"
        featureImgSrc={PhoneNewInsectImg}
        featureImgAlt="App's new insect entry"
        imgFirst={true}
        backgroundColor="#E5E5E4"
      />
      <FeatureSection
        className="bg-primary"
        headerText="Add a Picture and Description"
        subText="Taking a quick picture or adding a short description makes classifying the correct insect later a breeze"
        featureImgSrc={PhonePictureImg}
        featureImgAlt="Take a picture with your phone"
        imgFirst={false}
        backgroundColor="#205D67"
        textColor="white"
      />
      <FeatureSection
        className="bg-quaternary"
        headerText="Your Entire Collection at Your Fingertips"
        subText="Quickly browse your entire collection from anywhere with a mobile-friendly design"
        featureImgSrc={PhoneCollectionImg}
        featureImgAlt="Browse your collection"
        imgFirst={true}
        backgroundColor="#D8EBB5"
        textColor="black"
      />
      <CtaSection />
    </div>
    // <div className="container-fluid">
    //   <h1>Home</h1>
    //   {process.env.NODE_ENV === 'development' && (
    //     <h2>Development</h2>
    //   ) || (
    //     <h2>Production</h2>
    //   )}
    // </div>
  );
};
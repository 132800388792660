import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// css
import './index.css';

// redux
import { prepareInsectUpdate } from '../../../../../../state/insects/actions';

const InsectModalHeader = ({
  insect,
  imgSrc,
  toggleModal,
  onDeleteInsect: handleDeleteInsect,
  prepareInsectUpdate
}) => {
  return (
    // {/* Modal Header */}
    <div className="individual-insect-modal-header modal-header bg-quinary pt-2 px-2">
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={toggleModal}
      >
        <span aria-hidden="true">&times;</span>
      </button>

      <div className="row">
        <div className="col-4 col-md-6 insect-modal-img-container">
          {imgSrc
            ? <img src={imgSrc} alt={insect.humanReadableId} className="insect-modal-img img-fluid" />
            : <div className="text-center h-100"><i className="far fa-image fa-3x m-auto" style={{color: 'grey'}}></i></div>
          }
        </div>
        <div className="col-8 col-md-6 d-flex flex-column">
          <h1>{insect.classificationString.split('$').slice(-1)[0]}</h1>
          <h2><strong>ID</strong>: {insect.humanReadableId}</h2>
          <div className="mt-auto d-none d-sm-block">
            <Link
              to={`/edit/${insect.humanReadableId}`}
              className="btn btn-lg btn-tertiary"
              onClick={e => prepareInsectUpdate(insect)}
            >
              <i className="fas fa-pencil-alt"></i> Edit
            </Link>
            <button
              className="btn btn-lg btn-tertiary-border text-dark ml-1.5 ml-md-2"
              onClick={e => {
                e.stopPropagation();
                toggleModal();
                handleDeleteInsect();
              }}
            >
              <i className="fas fa-trash-alt"></i> Delete
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex d-sm-none justify-content-between mt-1.5">
        <Link
          to={`/edit/${insect.humanReadableId}`}
          className="btn btn-tertiary w-100"
          onClick={e => prepareInsectUpdate(insect)}
        >
          <i className="fas fa-pencil-alt"></i> Edit
        </Link>
        <button
          className="btn btn-tertiary-border text-dark ml-2 w-100"
          onClick={e => {
            e.stopPropagation();
            toggleModal();
            handleDeleteInsect();
          }}
        >
          <i className="fas fa-trash-alt"></i> Delete
        </button>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  prepareInsectUpdate: insect => dispatch(prepareInsectUpdate(insect))
});

export default connect(
  null,
  mapDispatchToProps
)(InsectModalHeader);
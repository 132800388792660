import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

// images
import PhonePictureImg from './images/Phone Picture-compressed.png';
import PhoneAutomaticDataImg from './images/Phone Automatic Data-compressed.png';
import LabelsImg from './images/Labels-compressed.png';

const HowToSection = () => {
  return (
    <section className="how-to-section bg-quaternary py-6">
      <div className="container">
        <h1 className="text-center mb-3.5">Easy to use in the field and afterwards</h1>
        <div className="row">
          <div className="col-12 col-lg-4 mb-4.5 mb-lg-0">
            <div className="how-to-img-container mb-3">
              <img src={PhonePictureImg} alt="" className="img-fluid how-to-img" />
            </div>
            <p className="how-to-text text-center">Adding a picture or short description to an insect allows easy specimen recognition for later identification</p>
          </div>
          <div className="col-12 col-lg-4 mb-4.5 mb-lg-0">
            <div className="how-to-img-container mb-3">
              <img src={PhoneAutomaticDataImg} alt="" className="img-fluid how-to-img" />
            </div>
            <p className="how-to-text text-center">When adding a new insect, location, date, and time form fields auto-populate to make in-field data recording a breeze</p>
          </div>
          <div className="col-12 col-lg-4">
            <div className="how-to-img-container mb-3">
              <img src={LabelsImg} alt="" className="img-fluid how-to-img" style={{ maxWidth: 250 }} />
            </div>
            <p className="how-to-text text-center">A unique ID stored with each insect record links its label to its digital entry</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HowToSection;
import React, { Component, useEffect } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { verifyLoginIfNeeded } from '../../state/login/actions';

import Navigation from '../Navigation';
import Footer from '../Footer';
import DefaultPageLayout from '../DefaultPageLayout';

const DefaultRoute = ({
  children,
  verifyLoginIfNeeded,
  verifyTokenStatusIfNeeded,
  userLoggedIn,
  token,
  ...rest
}) => {
  useEffect(() => {
    verifyLoginIfNeeded();
  });

  return (
    <Route
      {...rest}
      render={() => (
        <DefaultPageLayout>
          {children}
        </DefaultPageLayout>
      )}
    />
  );
};

const mapStateToProps = ({userLoginState: {userLoggedIn, requestPending, token}}, ownProps) => ({
  component: ownProps.component,
  userLoggedIn,
  requestPending,
  token
});

const mapDispatchToProps = dispatch => ({
  verifyLoginIfNeeded: () => dispatch(verifyLoginIfNeeded()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DefaultRoute);
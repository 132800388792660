/**
 * STATE TODOS
 * * create a component of the state to handle insect entry
 *      ** this seems like a great way to handle saving and breaking everything up into smaller pieces
 */

import {
    REQUEST_LOGIN,
    SUCCESSFUL_LOGIN,
    FAIL_LOGIN,
    REQUEST_LOGOUT,
    SUCCESSFUL_LOGOUT,
    FAIL_LOGOUT,
} from './actions';

const manageAuth = (state = {
    credentials: null,
    requestPending: false,

    // TODO: change to false when working on the login system again!!
    userLoggedIn: false,
    token: null,
    user: null,
    errorMessage: null,
    loginStatusChecked: false,

}, action) => {
    switch (action.type) {
        case REQUEST_LOGIN:
            return Object.assign({}, state, {
                credentials: action.credentials,
                requestPending: true,
                userLoggedIn: false,
            });
        case SUCCESSFUL_LOGIN:
            return Object.assign({}, state, {
                token: action.userData.token,
                user: {...action.userData},
                requestPending: false,
                loginStatusChecked: true,
                userLoggedIn: true,
                errorMessage: null
            });
        case FAIL_LOGIN:
            return Object.assign({}, state, {
                requestPending: false,
                loginStatusChecked: true,
                userLoggedIn: false,
                token: null,
                errorMessage: action.message,
                user: null
            });
        case REQUEST_LOGOUT:
            return Object.assign({}, state, {
                // explicitly not messing with credentials and user in case logout fails
                requestPending: true,
                userLoggedIn: true,
            });
        case SUCCESSFUL_LOGOUT:
            return Object.assign({}, state, {
                credentials: undefined,
                user: undefined,
                // loginStatusChecked: false,
                requestPending: false,
                userLoggedIn: false,
                token: null
            });
        case FAIL_LOGOUT:
            return Object.assign({}, state, {
                requestPending: false,
                userLoggedIn: true,
                errorMessage: action.message
            });
        default:
            return state;
    }
};

export default manageAuth;

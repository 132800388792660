import { useState, useEffect } from 'react';


// React hook from https://usehooks.com/useWindowSize/
export default () => {
    const [ windowSize, setWindowSize ] = useState({
        height: undefined,
        width: undefined
    });
    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                height: window.innerHeight,
                width: window.innerWidth
            });
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // call immediately to populate windowSize
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowSize;
};
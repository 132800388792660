import React from 'react';
import PropTypes from 'prop-types';
import { dmsGpsFormatter } from '../../../../utils/gpsFormats';
import { insectLabelDateFormatter } from '../../../../utils/dateFormats';
import getCountryByName from '../../../../utils/countryCodes';
import { labelNameFormatter } from '../../../../utils/nameFormats';


const LocationCell = ({
  insect
}) => {
  let locationString = [];
  let countryObject = getCountryByName(insect.country)[0];
  if (insect.country) locationString.push(
    countryObject
      ? countryObject.iso3
      : ''
  );
  if (insect.state) locationString.push(insect.state);
  if (insect.county) locationString.push(insect.county);
  locationString = locationString.filter(Boolean).join(', ');

  const finder = labelNameFormatter(
    insect.finderFirstName,
    insect.finderMiddleName,
    insect.finderLastName,
    insect.finderSuffix
  );

  let dateFinderLine = [
    insectLabelDateFormatter(insect.collectionDate),
    finder
  ].filter(Boolean).join(' ');

  return (
    <td>
      {/* div forces cell width */}
      <div>
        <p>{insect.humanReadableId}</p>
        {insect.latitude && insect.longitude && (
          <p>{dmsGpsFormatter(insect.latitude, insect.longitude)}</p>
        )}
        {locationString && (
          <p>{locationString}</p>
        )}
        {dateFinderLine && (
          <p>{dateFinderLine}</p>
        )}
      </div>
    </td>
  )
};

LocationCell.propTypes = {
  insect: PropTypes.object.isRequired
};

export default LocationCell;
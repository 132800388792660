import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

// custom components
import ValidationInput from '../ValidationInput';

/**
 * Standard format for input in the website
 * @param {String} className The CSS classes to apply to the component
 * @param {Object} componentRef The prop to pass through references to the input
 * @param {String} id The ID of the input used to link the label to the input
 * @param {String} inputBackground The CSS color to apply to the input background
 * @param {String} label The label for the input
 * @param {Function} onChange The function to run when the input value is changed
 * @param {String} type The HTML input type
 * @param {String|Number} value The value of the input
 * ****
 * @param {Boolean} validation Determines if the input should validate its value
 * @param {String} errorMessage (For Validation) Message to display underneath the input of value isn't valid
 * @param {String} errorColor (For Validation) The hex-format color of the alert message if invalid input
 * @param {Boolean} formSubmitted (For Validation) Boolean informing the input of the forms submission
 * @param {Function} inputValidator (For Validation) Function that is given the field's current value and should return true if input is valid
 * @param {Function} onClickPasswordVisibility (For Validation) Function to run in addition to making the password visible
 * @param {Boolean} passwordVisibilityButton (For Validation) Boolean determining if a button to make the password button should be present
 * @param {Boolean} resetInputForgiveness (For Validation) Boolean THAT SHOULD CHANGE indicating whether the input forgiveness should be set to true (useful for forms that use asynchronous alerts on success)
 * @param {String} successMessage (For Validation) Message to display underneath the input if the value IS valid
 */
const StandardFormInput = ({
  className,
  componentRef,
  id,
  inputBackground,
  label,
  onChange: handleChange,
  value,
  type,

  validation,
  errorMessage,
  errorColor,
  formSubmitted,
  inputValidator,
  onClickPasswordVisibility,
  passwordVisibilityButton,
  resetInputForgiveness,
  successMessage,
  ...restOfProps
}) => {
  return (
    <div className={`standard-form-input form-group mb-1.25 mb-md-1.75 ${className}`}>
      {validation && (
        <div>
          <label htmlFor={id}>{label}</label>
          <ValidationInput
            id={id}
            type={type}
            value={value}
            aria-describedby={label}
            onChange={handleChange}
            inputValidator={inputValidator}
            errorMessage={errorMessage}
            errorColor={errorColor}
            formSubmitted={formSubmitted}
            passwordVisibilityButton={passwordVisibilityButton}
            onClickPasswordVisibility={onClickPasswordVisibility}
            inputBackground={inputBackground}
            componentRef={componentRef}
            resetInputForgiveness={resetInputForgiveness}
            {...restOfProps}
          />
        </div>
      ) || (
        <div>
          <label htmlFor={id}>{label}</label>
          <input
            id={id}
            className="form-control"
            style={{
              backgroundColor: inputBackground || ' #B7B7B7',
              borderColor: inputBackground || '#B7B7B7'
            }}
            type={type}
            aria-describedby={label}
            value={value}
            onChange={handleChange}
            ref={componentRef}
            {...restOfProps}
          />
        </div>
      )}
    </div>
  );
};

StandardFormInput.propTypes = {
  className: PropTypes.string,
  componentRef: PropTypes.object,
  id: PropTypes.string.isRequired,
  inputBackground: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  type: PropTypes.string,

  validation: PropTypes.bool,
  errorMessage: PropTypes.string,
  errorColor: PropTypes.string,
  formSubmitted: PropTypes.bool,
  inputValidator: PropTypes.func,
  onClickPasswordVisibility: PropTypes.func,
  passwordVisibilityButton: PropTypes.bool,
  resetInputForgiveness: PropTypes.bool,
  successMessage: PropTypes.string,
};

export default StandardFormInput;
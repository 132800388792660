import { handleHttpResponse } from '../../utils/handleFetchHttpErrors'
import getCsrfToken from '../../utils/getCsrfToken';

/* ============================
 * Add Insect Image
 * ============================ */
export const ADD_INSECT_IMAGE_REQUEST = 'ADD_INSECT_IMAGE_REQUEST';
export const ADD_INSECT_IMAGE_SUCCESS = 'ADD_INSECT_IMAGE_SUCCESS';
export const ADD_INSECT_IMAGE_FAIL = 'ADD_INSECT_IMAGE_FAIL';

export const addInsectImage = (humanReadableId, formData) => {
    return dispatch => {
        dispatch({ type: ADD_INSECT_IMAGE_REQUEST, humanReadableId });
        // console.log('redux adding image form data:', formData)
        return fetch(`/api/specimens/${humanReadableId}/images`, {
            headers: {
                'x-csrf-token': getCsrfToken()
            },
            method: 'POST',
            body: formData
        })
            .then(handleHttpResponse)
            // .then(response => response.text())
            // .then(response => dispatch({ type: ADD_INSECT_IMAGE_FAIL, message: response}))
            .then(response => dispatch({
                type: ADD_INSECT_IMAGE_SUCCESS,
                humanReadableId,
                insectImage: response.data,
                message: response.message
            }))
            .catch(error => {
                console.error(error);
                return dispatch({ type: ADD_INSECT_IMAGE_FAIL, message: error.message })
            });
    }
};

/* ============================
 * Delete Insect Image
 * ============================ */
export const DELETE_INSECT_IMAGE_REQUEST = 'DELETE_INSECT_IMAGE_REQUEST';
export const DELETE_INSECT_IMAGE_SUCCESS = 'DELETE_INSECT_IMAGE_SUCCESS';
export const DELETE_INSECT_IMAGE_FAIL = 'DELETE_INSECT_IMAGE_FAIL';

export const deleteInsectImage = (humanReadableId, imageId) => {
    return dispatch => {
        // console.log('redux dispatching insect delete request')
        dispatch({ type: DELETE_INSECT_IMAGE_REQUEST, humanReadableId });
        return fetch(`/api/specimens/${humanReadableId}/images/${imageId}`, {
            headers: {
                'x-csrf-token': getCsrfToken()
            },
            method: 'DELETE'
        })
            .then(handleHttpResponse)
            .then(response => dispatch({
                type: DELETE_INSECT_IMAGE_SUCCESS,
                humanReadableId,
                imageId,
                message: response.message
            }))
            .catch(error => {
                console.error(error);
                return dispatch({ type: DELETE_INSECT_IMAGE_FAIL, message: error.message })
            });
    }
}


/* ============================
 * Replace Primary Insect Image
 * ============================ */
export const replacePrimaryInsectImage = (humanReadableId, currentImageId, formData) => {
    return dispatch => {
        // console.log('redux replace image form data:', formData)
        // console.log('redux replace image currentImageID:', currentImageId)
        let replaceImagePromise = Promise.resolve();
        if (currentImageId) {
            // console.log('redux replace image currentImageId promise setup:', currentImageId);
            replaceImagePromise = replaceImagePromise
                .then(() => dispatch(deleteInsectImage(humanReadableId, currentImageId)))
        }
        return replaceImagePromise
            .then(() => dispatch(addInsectImage(humanReadableId, formData)));
    }
}
import './index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import caseInsensitiveStringSearch from '../../utils/caseInsensitiveStringSearch';

// redux actions
import {
  getCollectionIfNeeded,
  selectInsectGroup,
  unselectInsectGroup,
  deleteInsects,
  deleteQueue,
  toggleSelectedInsect
} from '../../state/insects/actions';

// utilities
import useWindowSize from '../../utils/useWindowSize';

// custom components
import { FixedSizeList as List } from 'react-window';
import { ReactWindowScroller, WindowScroller } from 'react-window-scroller';
import InsectModal from './components/InsectModal';
import LoadingCircle from '../../components/LoadingCircle';
import DeleteWarning from './components/DeleteWarning';
import CollectionHeader from './components/CollectionHeader';
import AsyncAlert from '../../components/AsyncAlert';
import CollectionSelectAll from './components/CollectionSelectAll';
import IndividualInsectRowWrapper from './components/IndividualInsectRowWrapper';
// import IndividualInsect from './components/IndividualInsect';
import SearchModal from './components/SearchModal';

// insect card heights for window sizes GREATER than the one listed
const INSECT_CARD_RESPONSIVE_SIZES = [{
  windowSize: 992,
  itemSize: 208
}, {
  windowSize: 576,
  itemSize: 164
}, {
  windowSize: 0,
  itemSize: 142
}]

const Collection = ({
  collection,
  collectionRequest,
  getCollectionIfNeeded,
  selectedInsects,
  toggleSelectedInsect,
  selectInsectGroup,
  unselectInsectGroup,
  deleteInsects,
  deleteQueue
}) => {
  const [ individualInsectModalState, setIndividualInsectModalState ] = useState({
    open: false,          // determines if the insect modal should be open
    humanReadableId: ''   // The human-readable ID of the insect in the collection whose information is be displayed in the modal
  });
  const [ insectSearchState, setInsectSearchState ] = useState({
    modalOpen: false,      // boolean    : determines if the search modal should be open
    humanReadableId: '',   // field value: string to compare against the human-readable ID of insects in the collection
    identification: '',    // field value: string to compare against the classification string of insects in the collection
    location: '',          // field value: string to compare against the collection location of insects in the collection
    startDate: '',         // field value: date to begin the collection date range for searching for insects in the collection
    endDate: '',           // field value: date to end the collection date range for searching for insects in the collection
    searchTimer: null,     // search mgt : setTimeout ID to prevent searching until the final keystroke event (typing has ceased)
    searchPerformed: false // search mgt : determines whether a search has been performed.  Prevents empty search field from excessively running other function
  });
  const [ insectsToBeDisplayed, setInsectsToBeDisplayed ] = useState({});
  const [ deleteInsectsState, setDeleteInsectsState ] = useState({
    deleteTimer: null,            // setTimeout ID preventing immediate deletion in case the user wishes to undo action
    noInsectsAlertVisible: false, // determines the visibility of the 'no insects selected' alert
    queueAlertVisible: false,     // determines the visibility of the alert showing errors after a delete queue is finished
    insectsToDelete: null         // array of insects that have been prepped for deletion, allows reversal without depending on selected insect
  });
  const [ allInsectsChecked, setAllInsectsChecked ] = useState(false);
  const [ windowWidth, setWindowWidth ] = useState(null);
  const [ insectCardHeight, setInsectCardHeight ] = useState(INSECT_CARD_RESPONSIVE_SIZES[0].itemSize);


  // Immediately get the windowsize through a custom hook
  const windowSize = useWindowSize();

  // get collection on first load
  useEffect(() => {getCollectionIfNeeded()}, []);

  /**
   * Sets the insect card height based on the window size being tracked by the window resize event
   * @effect Changes the height of the insect card
   */
  useEffect(() => {
    for (let i = 0; i < INSECT_CARD_RESPONSIVE_SIZES.length; i++) {
      if (windowSize.width > INSECT_CARD_RESPONSIVE_SIZES[i].windowSize) {
        if (insectCardHeight !== INSECT_CARD_RESPONSIVE_SIZES[i].itemSize) {
          setInsectCardHeight(INSECT_CARD_RESPONSIVE_SIZES[i].itemSize);
        }

        // stop going through the other possible insect card heights as soon as the first one is found
        break;
      }
    }
  }, [windowSize.width]);

  /**
   * Insect search and collection effect
   * @effect sets all insects to be displayed if no search terms, otherwise
   *    restricts display insects to those matching search terms (through timeout for typing)
   */
  useEffect(() => {
    // always clear the timer if it exists as everything below will re-calculate
    //    which insects are to be displayed
    
    // if there are no search terms filled out, display all insects that are NOT
    //    queued up for deletion
    if (
      ! insectSearchState.humanReadableId &&
      ! insectSearchState.identification &&
      ! insectSearchState.location &&
      ! insectSearchState.startDate &&
      ! insectSearchState.endDate &&
      ! insectSearchState.searchPerformed
    ) {
        // console.log('running display function')
        if (insectSearchState.searchTimer) clearTimeout(insectSearchState.searchTimer);
        setInsectsToBeDisplayed(
        Object.keys(collection).reduce((displayObject, humanReadableId) => {
          // Don't display insects that are queued up for deletion
          if (deleteInsectsState.insectsToDelete && deleteInsectsState.insectsToDelete.length > 0) {
            displayObject[humanReadableId] = ! deleteInsectsState.insectsToDelete.includes(humanReadableId);
          } else {
            displayObject[humanReadableId] = true;
          }
          return displayObject;
        }, {})
      );
    }
  }, [
    collection,
    insectSearchState.humanReadableId,
    insectSearchState.identification,
    insectSearchState.location,
    insectSearchState.startDate,
    insectSearchState.endDate, // assuming that state updates are always immutable
    deleteInsectsState
  ]);

  /**
   * Insect 'select all' effect
   * @effect Triggers the selection or unselection of all insects by using the redux state
   */
  useEffect(() => {
    if (allInsectsChecked) {
      // console.log('redux selecting insect group');
      selectInsectGroup(insectsToBeDisplayed);
    } else if (allInsectsChecked === false) {
      // console.log('redux UNselecting insect group');
      unselectInsectGroup(insectsToBeDisplayed);
    }
  }, [allInsectsChecked]);

  /**
   * Effect of having errors in the delete queue
   * @effect Re-displays all the insects that had a deletion error and couldn't be deleted
   */
  useEffect(() => {
    if (! deleteQueue.isPending && deleteQueue.errorMessage) {
      handleDeleteQueueError();
    }
  }, [
    deleteQueue.isPending,
    deleteQueue.errorMessage
  ])

  /**
   * Determines if the user-submitted human-readable ID matches the one submitted by the user
   * @param {String} searchTerm The human-readable ID submitted by the user for searching
   * @param {String} humanReadableId The human-readable ID of the insect from the database
   * @return {Boolean} Match or not
   */
  const matchHumanReadableId = (searchTerm, humanReadableId) => {
    // console.log('human readable ID search:', searchTerm);
    // console.log('human readable ID:', humanReadableId);
    if (! searchTerm) {
      return true;
    } else if (searchTerm && caseInsensitiveStringSearch(searchTerm, humanReadableId)) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Determines if a user-submitted taxon name matches any of the taxa names in a single insect specimen's identification
   * @param {String} searchTerm The user-submitted taxon name
   * @param {String} identification The $ delimited string of the insect's identification from the database
   * @return {Boolean} Match or not
   */
  const matchInsectClassification = (searchTerm, identification) => {
    // console.log('classification search:', searchTerm);
    if (! searchTerm) {
      return true;
    } else if (searchTerm && caseInsensitiveStringSearch(searchTerm, identification)) {
      // console.log('return true classification match')
      return true;
    } else {
      // console.log('return false classification match')
      return false;
    }
  }

  /**
   * Determines if an insect's collection location matches the location the user submitted
   * @param {String} searchTerm The location that the user typed in
   * @param {Array} locationArray The unordered array of location information combined from the insect object
   * @return {Boolean} Whether the insect's collection location matches the user's search or not
   */
  const matchLocation = (searchTerm, locationArray) => {
    if (! searchTerm) {
      return true;

    } else {
      // tokenize search term
      // place all enumerated locations (city, state, etc.) into an array for searching
      // for each token, search all possible enumerated locations
      // remove enumerated location once matched to decrease search space
      const tokenizedSearchTerm = searchTerm
        .split(/,/g)
        .filter(token => token !== '')
        .map(token => token.trim());

      // For a proper match, then all of the tokens in the search term should match at least one 
      //    of the location terms
      let matches = 0;
      for (let i = 0; i < tokenizedSearchTerm.length; i++) {
        for (let j = 0; j < locationArray.length; j++) {
          if (caseInsensitiveStringSearch(tokenizedSearchTerm[i], locationArray[j])) {
            matches++;
          }
        }
      }
      return matches >= tokenizedSearchTerm.length;
    }
  }

  /**
   * Determines if a specimen's collection date falls within a given range
   * @param {String} startDate The start date for the search range
   * @param {String} endDate The end date for the search range
   * @param {String} collectionDate The date the specimen was collected
   * @return {Boolean} Whether or not the collection date is within the given range
   */
  const matchDate = (startDate, endDate, collectionDate) => {
    // console.log('start date search:', startDate);
    // console.log('end date search:', endDate);
    // console.log('collection date:', collectionDate);

    // Trick to force JS date object to use correct date with appropriate time zone (https://www.sunzala.com/why-the-javascript-date-is-one-day-off/)
    const startDateObject = new Date(startDate + ' 00:00:00'),
          endDateObject = new Date(endDate + ' 23:59:59'),
          collectionDateObject = new Date(collectionDate);
    
    if (startDate && collectionDateObject < startDateObject) {
      // console.log('start date (', startDateObject, ') greater than collection date (', collectionDateObject, ')')
      return false;
    } else if (endDate && collectionDateObject > endDateObject) {
      // console.log('end date (', endDateObject, ') greater than collection date (', collectionDateObject, ')')
      return false;
    } else {
      // console.log('collection date (', collectionDateObject, ') not in range (', startDateObject,' - ', endDateObject,')')
      return true;
    }
  }

  /**
   * Determines if an insect matches the search terms submitted by the user in teh input fields on the page
   * @param {Object} insect The insect's collection object from the redux collection state
   * @param {Object} searchTerms The search terms defined by the inputs on the page and destructured
   *    at the beginning of the function
   * @return {Boolean} Whether or not the insect matches the search criteria
   */
  const matchSearchTerms = (insect, searchTerms) => {
    const {
      humanReadableId,
      identification,
      location,
      startDate,
      endDate
    } = searchTerms;
    if (
      matchHumanReadableId(humanReadableId, insect.humanReadableId) &&
      matchInsectClassification(identification, insect.classificationString) &&
      matchLocation(location, [insect.city, insect.county, insect.state, insect.country]) &&
      matchDate(startDate, endDate, insect.collectionDate)
    ) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Searches for insects meeting the search term criteria
   * @param {Object} collection The same object as the redux collection
   * @param {Object} searchTerms The search terms defined by the inputs on the page (destructured
   *    in the matchSearchTerms function)
   * @return {Object} human-readable IDs as keys with boolean values determining if they
   *    should be displayed
   */
  const searchInsects = (collection, searchTerms) => {
    return Object.keys(collection).reduce((displayObject, humanReadableId) => {
      if (matchSearchTerms(collection[humanReadableId], searchTerms)) {
        displayObject[humanReadableId] = true;
      } else {
        displayObject[humanReadableId] = false;
      }
      return displayObject;
    }, {})
  }

  /**
   * Toggles the individual insect modal open and closed (opposite of the current state)
   * @return {Void} (changes state)
   */
  const handleToggleIndividualInsectModal = () => setIndividualInsectModalState(prevState => ({
    ...prevState,
    open: ! prevState.open
  }))

  /**
   * Changes the modal state to reflect the human-readable ID of the insect clicked on
   * @param {String} humanReadableId The human-readable ID of the insect row clicked on
   * @return {Void} (changes state)
   */
  const handleClickInsect = humanReadableId => e => {e.persist(); setIndividualInsectModalState(prevState => ({
    ...prevState,
    open: true,
    humanReadableId
  }))};

  /**
   * Controls the checkbox (or other UI element) that selects and unselects all insects
   * @return {Void} (changes state)
   */
  const handleToggleCheckAllInsects = () => setAllInsectsChecked(! allInsectsChecked);

  /**
   * Toggles the search modal open and closed (opposite of current state)
   * @return {Void} (changes state)
   */
  const handleToggleSearchModal = () => setInsectSearchState(prevState => ({
    ...prevState,
    modalOpen: ! prevState.modalOpen
  }));

  /**
   * Changes the insect search state to reflect the user input of a search term
   *    and sets up for searching an insect when typing is finished
   * @param {Object} e Event object associated with input element for search
   * @return {Void} (changes state)
   */
  // const handleSearchFieldChange = e => {
  //   e.persist();
  //   if (insectSearchState.searchTimer) {
  //     clearTimeout(insectSearchState.searchTimer);
  //   }
  //   setInsectSearchState(prevState => ({
  //     ...prevState,
  //     [e.target.id]: e.target.value,
  //     searchTimer: setTimeout(
  //       () => setInsectsToBeDisplayed(
  //         searchInsects(collection, {
  //           humanReadableId: prevState.humanReadableId,
  //           identification: prevState.identification,
  //           location: prevState.location,
  //           startDate: prevState.startDate,
  //           endDate: prevState.endDate,
  //           [e.target.id]: e.target.value
  //         })
  //       ),
  //       500
  //     )
  //   }))
  // };

  /**
   * Changes the insect search state to reflect the user input of a search term
   * @param {Object} e The event object associated with input elements for search
   * @return {Void} (changes state)
   */
  const handleSearchFieldChange = e => {
    e.persist();
    setInsectSearchState(prevState => ({
      ...prevState,
      searchPerformed: true,
      [e.target.id]: e.target.value
    }));
  };
  
  /**
   * Changes the insects to be displayed based on the search criteria
   * @param {Object} e The event object containing information about the search form submission
   * @return {Void} (changes state)
   */
  const handleSearchFormSubmit = e => {
    e.preventDefault();
    setInsectsToBeDisplayed(
      searchInsects(collection, {
        humanReadableId: insectSearchState.humanReadableId,
        identification: insectSearchState.identification,
        location: insectSearchState.location,
        startDate: insectSearchState.startDate,
        endDate: insectSearchState.endDate
      })
    );
  };

  /**
   * Deletes an arbitrary number of insects identified by human-readable ID submitted in an array
   * @param {Array} humanReadableIdArray The list human-readable IDs of the insects to delete
   * @return {Void} (changes state)
   */
  const handleDeleteInsects = humanReadableIdArray => {
    // If anything is left over (a timer is still running), simply clear the timer
    //    and immediately delete what was scheduled
    // Essentially, running a second delete makes the first one irreversible
    if (deleteInsectsState.deleteTimer) {
      clearTimeout(deleteInsectsState.deleteTimer);
      deleteInsects(deleteInsectsState.insectsToDelete);
    }

    setInsectsToBeDisplayed(prevState => ({
      ...prevState,
      ...humanReadableIdArray.reduce((noDisplayObject, humanReadableId) => {
        noDisplayObject[humanReadableId] = false;
        return noDisplayObject;
      }, {})
    }));

    setDeleteInsectsState(prevState => ({
      ...prevState,
      insectsToDelete: humanReadableIdArray,
      queueAlertVisible: true,
      deleteTimer: setTimeout(() => {
        deleteInsects(humanReadableIdArray);

        // reset delete timer at the very end so that alert goes away, which uses the delete timer
        //    to determine whether or not it should display
        setDeleteInsectsState(prevState => ({ ...prevState, deleteTimer: null}))
      }, 5000)
    }))
  }

  /**
   * Prepares the selected insects to be deleted by removing them from display and setting a timer
   *    for deletion
   * @return {Void} (changes state)
   */
  const handleDeleteSelectedInsect = () => {
    // If anything is left over (a timer is still running), simply clear the timer
    //    and immediately delete what was scheduled
    // Essentially, running a second delete makes the first one irreversible
    if (deleteInsectsState.deleteTimer) {
      clearTimeout(deleteInsectsState.deleteTimer);
      deleteInsects(deleteInsectsState.insectsToDelete);
    };

    let selectedInsectsArray = Object.keys(selectedInsects).filter(humanReadableId => (
      selectedInsects[humanReadableId] === true
    ));
    if (selectedInsectsArray.length === 0) {
      handleNoInsectsToDeleteError(true);

    } else {
      handleDeleteInsects(selectedInsectsArray);
    }
  };

  /**
   * Stops the deletion timer (stopping insect deletion) and re-displays all insects
   *    that had been queued up for deletion
   * @return {Void} (changes state)
   */
  const handleUndoDelete = () => {
    clearTimeout(deleteInsectsState.deleteTimer);
    setDeleteInsectsState(prevState => ({
      ...prevState,
      deleteTimer: null
    }));

    // NOTE: this assumes that the user has not changed search criteria during the delete waiting period
    //    if the user has, then the following code will ignore the search criteria and display any insects
    //    the were to be deleted
    setInsectsToBeDisplayed(prevState => ({
      ...prevState,

      // NOTE: it is ok to use the redux 'selectedInsects' object to re-display insects prepped for 
      //    deletion because they were removed from display, preventing the user from affecting
      //    their selection state
      ...deleteInsectsState.insectsToDelete.reduce((toDisplayObject, humanReadableId) => {
        toDisplayObject[humanReadableId] = true;
        return toDisplayObject;
      }, {})
    }))
  }

  /**
   * Handles the visibility of the 'no insects selected' alert
   * @param {Boolean} boolean Whether or not the 'no insects selected' alert should be displayed
   * @return {Void} (changes state)
   */
  const handleNoInsectsToDeleteError = boolean => setDeleteInsectsState(prevState => ({
    ...prevState,
    noInsectsAlertVisible: boolean
  }));

  /**
   * Re-displays any insects that failed to be deleted
   * @return {Void} (changes state)
   */
  const handleDeleteQueueError = () => {
    setInsectsToBeDisplayed(prevState => ({
      ...prevState,
      ...deleteQueue.queue.reduce((toDisplayInsects, deleteQueueInsect) => {
        if (deleteQueueInsect.errorMessage) {
          toDisplayInsects[deleteQueueInsect.id] = true;
        }
        return toDisplayInsects;
      }, {})
    }))
  };

  /**
   * Handles the display state of the delete queue alert
   * @param {Boolean} boolean Whether or not the delete queue alert should be shown
   * @return {Void} (changes state)
   */
  const handleDeleteQueueAlertDisplay = boolean => setDeleteInsectsState(prevState => ({
    ...prevState,
    queueAlertVisible: boolean
  }));

  // console.log('collection state:', { individualInsectModalState, insectSearchState, insectsToBeDisplayed, deleteInsectsState, allInsectsChecked })
  // console.log('insect card height:', insectCardHeight);
  return (
    <section className="collection-section bg-quinary pt-6 pt-sm-7 pt-md-8.5">
      <div className="container">
        {! collectionRequest.isPending && collection && individualInsectModalState.open && individualInsectModalState.humanReadableId && (
          <InsectModal
            modalOpen={individualInsectModalState.open}
            toggleModal={handleToggleIndividualInsectModal}
            insect={collection[individualInsectModalState.humanReadableId]}
            onDeleteInsect={() => handleDeleteInsects([individualInsectModalState.humanReadableId])}
          />
        )}

        { insectSearchState.modalOpen && (
          <SearchModal
            modalOpen={insectSearchState.modalOpen}
            toggleModal={handleToggleSearchModal}
            onSearchFieldChange={handleSearchFieldChange}
            insectSearchState={insectSearchState}
            onSearchFormSubmit={handleSearchFormSubmit}
          />
        )}

        {/* Header section with actions */}
        <CollectionHeader
          onDeleteClick={handleDeleteSelectedInsect}
          onSearchClick={handleToggleSearchModal}
        />

        {/* insect table */}
        {/* Notes:
            * hide image on small display
            * hide ID on md display 
        */}
        <CollectionSelectAll
          checked={allInsectsChecked}
          onSelect={handleToggleCheckAllInsects}
          collectionSize={Object.keys(insectsToBeDisplayed).filter(id => insectsToBeDisplayed[id]).length}
        />


        {! collectionRequest.isPending && collection && (
          <div className="d-flex">
            <ReactWindowScroller>
              {({ ref, outerRef, style, onScroll }) => {
                const humanReadableIds = Object.keys(insectsToBeDisplayed).filter(id => insectsToBeDisplayed[id]);
                return (
                  <List
                    ref={ref}
                    outerRef={outerRef}
                    style={style}
                    height={window.innerHeight}
                    itemCount={humanReadableIds.length}
                    // itemSize={184}
                    itemSize={insectCardHeight}
                    onScroll={onScroll}
                    itemData={{
                      humanReadableIds,
                      onSelectInsect: toggleSelectedInsect,
                      onClickInsect: handleClickInsect,
                      onDeleteInsect: handleDeleteInsects
                    }}
                  >
                    {IndividualInsectRowWrapper}
                  </List>
                )
              }}
            </ReactWindowScroller>


            <DeleteWarning
              deleteTimer={deleteInsectsState.deleteTimer}
              onUndoDeleteClick={handleUndoDelete}
            />

            {/* No insects selected to delete */}
            <AsyncAlert
              color="danger"
              isOpen={deleteInsectsState.noInsectsAlertVisible}
              changeIsOpen={handleNoInsectsToDeleteError}
              visibleTime={5}
            >
              <i className="fas fa-exclamation-circle"></i><span style={{marginLeft: 5}}>Please select some insects to delete . . .</span>
            </AsyncAlert>

            {/* delete queue finished with errors */}
            <AsyncAlert
              color="danger"
              isOpen={! deleteQueue.isPending && deleteQueue.errorMessage && deleteInsectsState.queueAlertVisible}
              changeIsOpen={handleDeleteQueueAlertDisplay}
              visibleTime={5}
            >
              These insects could not be deleted: {
                deleteQueue &&
                deleteQueue.queue &&
                deleteQueue.queue.length !== 0 &&
                deleteQueue.queue
                  .filter(insect => Boolean(insect.message))
                  .map(insect => insect.id)
                  .join(', ')
              }
            </AsyncAlert>
          </div>
        ) || (
          <LoadingCircle
            className="collection-loading mt-3.5 mt-sm-9.5"
            text="Loading..."
          />
        )}
      </div>
    </section>
  );
};

const mapStateToProps = ({ insects: {collection, collectionRequest, selectedInsects, deleteQueue}}) => ({
  collection,
  collectionRequest,
  selectedInsects,
  deleteQueue
});

const mapDispatchToProps = dispatch => ({
  getCollectionIfNeeded: () => dispatch(getCollectionIfNeeded()),
  toggleSelectedInsect: humanReadableId => dispatch(toggleSelectedInsect(humanReadableId)),
  selectInsectGroup: humanReadableIdArray => dispatch(selectInsectGroup(humanReadableIdArray)),
  unselectInsectGroup: humanReadableIdArray => dispatch(unselectInsectGroup(humanReadableIdArray)),
  deleteInsects: humanReadableIdArray => dispatch(deleteInsects(humanReadableIdArray))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Collection);
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import isEmptyObject from '../../../../utils/isEmptyObject';
import isValidEmail from '../../../../utils/isValidEmail';
import ValidationInput from '../../../../components/ValidationInput';

// css
import './index.css';

const SignUpForm = ({
  onFormFieldChange: handleFormFieldChange,
  onSubmit: handleSubmit,
  formValues,
  signUpRequestPending
}) => {

  const [ formSubmitted, setFormSubmitted ] = useState(false);
  const [ passwordFieldType, setPasswordFieldType ] = useState('password');

  const emailField = useRef(null);

  useEffect(() => {
    emailField.current.focus();
  }, []);

  /**
   * Checks whether an input is filled with a value
   * @param {string} value The value to check if 'filled' with data
   * @return {boolean} whether or not the input is filled
   */
  const validateFilledInput = () => value => {
    return value
      ? true
      : false;
  }

  const onClickPasswordVisibility = () => setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password');

  const {
    email,
    password,
    fullName
  } = formValues;

  return (
    <div>
      <form onSubmit={e => {setFormSubmitted(true); handleSubmit(e)}}>

        {/* email */}
        <div className="form-group sign-up-group">
          <label htmlFor="email">Email:</label>
          <ValidationInput
            type="text"
            id="email"
            autoComplete="email"
            aria-describedby="email"
            value={email}
            onChange={handleFormFieldChange('email')}
            inputValidator={value => isValidEmail(value)}
            errorMessage="Email in the form user@domain.tld required"
            formSubmitted={formSubmitted}
            componentRef={emailField}
          />
        </div>

        {/* password */}
        <div className="form-group sign-up-group">
          <label htmlFor="password">Password:</label>
          <ValidationInput
            type={passwordFieldType}
            passwordVisibilityButton
            onClickPasswordVisibility={onClickPasswordVisibility}
            id="password"
            autoComplete="new-password"
            aria-describedby="password"
            value={password}
            onChange={handleFormFieldChange('password')}
            inputValidator={validateFilledInput()}
            errorMessage="Required"
            formSubmitted={formSubmitted}
          />
        </div>

        <div className="form-group sign-up-group">
          <label htmlFor="full-name">Full Name (optional):</label>
          <input
            type="text"
            id="full-name"
            autoComplete="name"
            className="form-control"
            aria-describedby="full-name"
            value={fullName}
            onChange={handleFormFieldChange('fullName')}
          />
          <small className="form-text">Only used to auto-fill form fields when adding a new insect</small>
        </div>

        <button
          type="submit"
          className="btn btn-lg btn-tertiary w-100 mt-1.5"
          disabled={signUpRequestPending}
        >
          <strong>
            {signUpRequestPending && <i className="fas fa-circle-notch fa-spin"></i>} Create Account
          </strong>
        </button>
      </form>
    </div>
  );
}

export default SignUpForm;

SignUpForm.propTypes = {
  formValues: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    password: PropTypes.string,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    suffix: PropTypes.string,
  }).isRequired,
  onFormFieldChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
}
export const fullNameFormatter = (firstName, middleName, lastName, suffix) => {
    return [
        firstName,
        middleName,
        lastName,
        suffix
    ].filter(Boolean).join(' ');
};

export const labelNameFormatter = (
    firstName,
    middleName,
    lastName,
    suffix
) => {
    let abbreviatedFirstName;
    if (firstName) {
        abbreviatedFirstName = firstName.slice(0, 1) + '.';
    }
    return [
        abbreviatedFirstName,
        lastName,
        suffix
    ].filter(Boolean).join(' ');
}

export default fullNameFormatter;
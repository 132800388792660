import React from 'react';
import PropTypes from 'prop-types';
import StandardLabelTableRow from '../StandardLabelTableRow';

const StandardLabelTable = ({
  insectSet,
  rowsPerTable,
  columnsPerTable,
  cellsPerInsect,
  cellType
}) => {
  let preparedInsectSet;
  // Add cell type to each insect based on whether there is 1 or 2 cells per insect
  if (cellsPerInsect === 1) {
    // console.log('1 cell per insect');
    preparedInsectSet = insectSet.map(currentInsect => ({
        ...currentInsect,
        cellType
    }));

  } else if (cellsPerInsect === 2) {
    // console.log('2 cells per insect');
    preparedInsectSet = insectSet.reduce((totalSet, currentInsect) => {
      return totalSet.concat([
        {
          ...currentInsect,
          cellType: 'location'
        },
        {
          ...currentInsect,
          cellType: 'identification'
        }
      ]);
    }, []);
  }
  let insectRowSets = preparedInsectSet.reduce((insectRowSets, currentInsect, i) => {
    let insectRowNumber = Math.floor(i / columnsPerTable);
    insectRowSets[insectRowNumber] = insectRowSets[insectRowNumber] || [];
    insectRowSets[insectRowNumber].push(currentInsect);
    return insectRowSets;
  }, []);

  // console.log('insect row sets:', insectRowSets)
  return (
    <div className="table-responsive">
      <table className="classic-label-table">
        <tbody>
          {insectRowSets.map((insectRowSet, i) =>
            <StandardLabelTableRow
              key={i}
              insectRowSet={insectRowSet}
              cellsPerInsect={cellsPerInsect}
              cellType={cellType}
            />
          )}
        </tbody>
      </table>
    </div>
  )
};

StandardLabelTable.propTypes = {
  insectSet: PropTypes.array.isRequired,
  rowsPerTable: PropTypes.number.isRequired,
  columnsPerTable: PropTypes.number.isRequired,
  cellsPerInsect: PropTypes.number.isRequired,
  cellType: PropTypes.string
}

export default StandardLabelTable;
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

// images
import RestApiImg from './images/RESTful API-compressed.png';

const ApiSection = () => {
  return (
    <section className="api-section bg-primary py-4.5 py-lg-10">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-5 text-white api-description mb-4.5 mb-lg-0">
            <h1 className="mb-3 mb-md-5">Programmer-Friendly</h1>
            <p className="mb-3 mb-md-5">Built on the foundation of an open RESTful API, Data Bugs is programmer-friendly.  This architecture makes it easy to upload any metadata from previously captured insects or use the data from a collection for any other purpose.</p>
            <div className="d-flex justify-content-center justify-content-lg-start">
              <a href="https://app.swaggerhub.com/apis/informagician/data_bugs/2.0.0" className="btn btn-lg btn-tertiary d-inline-flex align-items-center">Visit Documentation <i className="fas fa-external-link-alt ml-1"></i></a>
            </div>
          </div>
          <div className="col-12 col-lg-6 offset-lg-1 text-center">
            <img src={RestApiImg} alt="Computer with API code" className="img-fluid api-img" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApiSection;
import React from 'react';
import PropTypes from 'prop-types';

import './index.css';

import DataStreamingImg from './images/Data Streaming while Insect Collecting-compressed.png';

const AboutHeroSection = () => {
  return (
    <section className="about-hero-section bg-secondary pt-md-15.5 pb-md-10 pt-8 pb-4">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-lg-6 text-center mb-3 mb-lg-0 px-3">
            <img src={DataStreamingImg} alt="making collected insects digital" className="img-fluid about-img" />
          </div>
          <div className="col-12 col-lg-5">
            <h1 className="text-white about-hero-header mb-1.25 mb-md-3">Automate <strong>data</strong> collection during <strong>insect</strong> collection</h1>
            <h2 className="text-white about-hero-subtext">Data Bugs streamlines the critical process of insect metadata collection.  A responsive design simplifies both in-field data recording and additional data entry afterwards.  A public API allows rapid storage of bulk information.</h2>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutHeroSection;
import './index.css';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import IndividualTaxon from './components/IndividualTaxon';

const TaxonSelection = ({
  totalTaxa,
  startingTaxonNumber,
  taxa,
  incrementResults,
  onSelectTaxon: handleTaxonSelection
}) => {
  let previousDisabled = false,
      nextDisabled = false;

  // console.log('search taxa:', taxa);
  if (totalTaxa === 0) {
    return <div className="alert alert-warning"><i className="fas fa-exclamation-triangle"></i> No results</div>;
  
  } else {
    // console.log('taxon index:', taxonIndex)
    let incrementScalar = 10;

    if (startingTaxonNumber === 0) {
      previousDisabled = true;
    }
    if (startingTaxonNumber + incrementScalar >= totalTaxa) {
      nextDisabled = true;
    }
    return (
      <div className="mb-2.25">  
        {/* Searchable taxa */}
        {taxa.map((taxon, i) => (
          <IndividualTaxon
            key={i}
            onClick={handleTaxonSelection(taxon.tsn)}
            hierarchyLevel={taxon.rank}
            taxonName={taxon.nameWInd}
            commonName={taxon.vernacular}
          />
        ))}
        {/* Navigation */}
        {totalTaxa > incrementScalar && (
          <div className="mb-3">
            <button
              className="btn btn-secondary left-nav-btn"
              onClick={incrementResults(-incrementScalar)}
              disabled={previousDisabled}
            >
              <i className="fas fa-chevron-left"></i> Previous
            </button>
            <button
              className="btn btn-secondary right-nav-btn"
              onClick={incrementResults(incrementScalar)}
              disabled={nextDisabled}
            >
              Next <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        )}
      </div>
    )
  }
};

TaxonSelection.propTypes = {
  totalTaxa: PropTypes.number,
  startingTaxonNumber: PropTypes.number,
  taxa: PropTypes.array.isRequired,
  incrementResults: PropTypes.func.isRequired,
  getChildren: PropTypes.func
};

export default TaxonSelection;
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

const TechnologyHighlight = ({
  href,
  imgSrc,
  imgAlt,
  altSize,
  className
}) => {
  let imgClass = 'technology-img';
  if (altSize) {
    imgClass = 'technology-img-alt';
  }
  return (
    <div className={`col-6 col-md-3 text-center mb-3 mb-md-4.5 ${className}`}>
      <a href={href}>
        <img src={imgSrc} alt={imgAlt} className={`img-fluid ${imgClass}`} />
      </a>
    </div>
  );
};

TechnologyHighlight.propTypes = {
  href: PropTypes.string.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  altSize: PropTypes.bool,
  className: PropTypes.string
};

export default TechnologyHighlight;
import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { combineReducers } from 'redux';
import { createLogger } from 'redux-logger';

import manageAuth from './login/reducers';
import manageInsects from './insects/reducers';

let middleWare = [thunkMiddleware];

if (process.env.NODE_ENV === 'development') {
  const loggerMiddleware = createLogger();
  middleWare.push(loggerMiddleware);
}

const store = createStore(
  combineReducers({
      userLoginState: manageAuth,
      insects: manageInsects
  }),
  applyMiddleware(...middleWare)
);

export default store;
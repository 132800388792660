import React, { useState, useEffect } from 'react';

// css
import './index.css';

// images
import CollectingQuestionsImg from './images/Collecting Questions-compressed.png';

// utils
import isValidEmail from '../../utils/isValidEmail';

// custom components
import StandardFormInput from '../../components/StandardFormInput'
import { handleHttpResponse } from '../../utils/handleFetchHttpErrors';
import AsyncAlert from '../../components/AsyncAlert';
import config from '../../config';

const Contact = () => {
  const SUBJECT_FIELD_BOTTOM_MARGIN_DEFAULT = 'mb-1.25 mb-md-1.75',
        OTHER_SUBJECT_VISIBILITY_DEFAULT = 'd-none',
        SUBJECT_DROPDOWN_VALUES = [
          'Question',
          'Feature Request',
          'Bug',
          'Other'
        ],
        FREE_TEXT_FIELD_DROPDOWN_VALUE = SUBJECT_DROPDOWN_VALUES[SUBJECT_DROPDOWN_VALUES.length - 1];
  
  const [ contactFormState, setContactFormState ] = useState({
    nameValue: '',
    affilationValue: '',
    emailValue: '',
    subjectValue: 'Question',
    otherSubjectValue: '',
    messageValue: '',
    subjectFieldBottomMargin: SUBJECT_FIELD_BOTTOM_MARGIN_DEFAULT, // uses bootstrap classes
    otherSubjectVisibility: OTHER_SUBJECT_VISIBILITY_DEFAULT, // uses bootstrap classes
    formSubmissionAttempted: false,
    formPending: false,
    formSubmitted: false,
    formError: '',
    formAlertVisible: false
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LcQerAUAAAAAPq82AqHRK3m5VKlIeg6t6CHihub';
    document.body.appendChild(script);

    return () => {
      script.remove();
      Array.from(document.getElementsByTagName('script'))
        .filter(script => script.src.includes('recaptcha'))
        .map(script => script.remove());

      document.getElementsByClassName('grecaptcha-badge')[0].parentNode.remove()
    }
  }, []);

  useEffect(() => {
    if (contactFormState.subjectValue === FREE_TEXT_FIELD_DROPDOWN_VALUE) {
      setContactFormState(prevState => ({
        ...prevState,
        otherSubjectVisibility: 'd-block',
        subjectFieldBottomMargin: 'mb-1'
      }));
    } else {
      setContactFormState(prevState => ({
        ...prevState,
        otherSubjectVisibility: OTHER_SUBJECT_VISIBILITY_DEFAULT,
        subjectFieldBottomMargin: SUBJECT_FIELD_BOTTOM_MARGIN_DEFAULT
      }));
    }
  }, [contactFormState.subjectValue])

  /**
   * Handles the contact form field updates
   * @param {Object} e The information about the event triggering the field change
   */
  const handleContactFormFieldChange = e => {
    const formFieldName = e.target.id,
          formFieldValue = e.target.value;
    setContactFormState(prevState => ({
      ...prevState,
      [formFieldName]: formFieldValue
    }));
  };

  /**
   * Changes the visibility status of the asynchronous alert
   * @param {Boolean} visiblity Whether or not the asynchronous alert should be visible
   * @return {Void} (changes state)
   */
  const handleAlertVisibility = visiblity => {
    setContactFormState(prevState => ({
      ...prevState,
      formAlertVisible: visiblity
    }))
  }


  /**
   * Submits the form data to the Data Bugs contact api and updates the form submission state with the progress
   * @param {String} captchaToken The google captcha token verifying human-agent is submitting form
   * @return {Void} (changes state)
   */
  const handleFormSubmissionToApi = captchaToken => {
    setContactFormState(prevState => ({
      ...prevState,
      formSubmissionAttempted: true,
      formSubmitted: false
    }));

    if (
      contactFormState.emailValue &&
      isValidEmail(contactFormState.emailValue) &&
      contactFormState
    ) {
      setContactFormState(prevState => ({
        ...prevState,
        formPending: true
      }));

      fetch(`/api/contacts`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          senderName: contactFormState.nameValue,
          senderEmailAddress: contactFormState.emailValue,
          senderAffiliation: contactFormState.affilationValue,
          messageSubject: contactFormState.subjectValue === FREE_TEXT_FIELD_DROPDOWN_VALUE
            ? contactFormState.otherSubjectValue
            : contactFormState.subjectValue,
          messageBody: contactFormState.messageValue,
          captchaToken
        })
      })
        .then(handleHttpResponse)
        .then(response => setContactFormState(prevState => ({
          ...prevState,
          nameValue: '',
          affilationValue: '',
          emailValue: '',
          subjectValue: 'Question',
          messageValue: '',
          formSubmissionAttempted: false
        })))
        .catch(error => setContactFormState(prevState => ({
          ...prevState,
          formError: error.message
        })))
        .finally(() => setContactFormState(prevState => ({
          ...prevState,
          formPending: false,
          formAlertVisible: true,
          formSubmitted: true
        })));
    }
  };

  /**
   * Submits the form data to the Data Bugs contact api
   * @param {Object} e The object containing the information about the form submission
   * @return {void} (changes state)
   */
  const handleFormSubmit = e => {
    e.preventDefault();
    grecaptcha.ready(() => {
      grecaptcha.execute(config.reCaptchaSiteKey, {action: 'contact'})
        .then(token => handleFormSubmissionToApi(token));
    })
  }

  // console.log(contactFormState);
  return (
    <section className="contact-section bg-primary pt-7 pt-md-9">
      <div className="container">
        <div className="row">
          <div className="col-12 col-xl-6 col-xxxl-5 text-white">
            <h1>Contact</h1>
            <p>Questions, comments, feature requests?  Just let us know below!</p>
            <form>
              <StandardFormInput
                label="Name (optional)"
                id="nameValue"
                type="text"
                autoComplete="name"
                className="text-white"
                inputBackground="#5EAAB6"
                value={contactFormState.nameValue}
                onChange={handleContactFormFieldChange}
              />
              <StandardFormInput
                label="Affiliation (optional)"
                id="affilationValue"
                type="text"
                autoComplete="organization"
                className="text-white"
                inputBackground="#5EAAB6"
                value={contactFormState.affilationValue}
                onChange={handleContactFormFieldChange}
              />
              <StandardFormInput
                validation
                inputValidator={isValidEmail}
                formSubmitted={contactFormState.formSubmissionAttempted}
                errorMessage="Email in the form name@domain.tld required"
                label="Email"
                id="emailValue"
                type="text"
                autoComplete="email"
                className="text-white"
                inputBackground="#5EAAB6"
                value={contactFormState.emailValue}
                onChange={handleContactFormFieldChange}
              />

              <div className={`form-group contact-subject ${contactFormState.subjectFieldBottomMargin}`}>
                <label htmlFor="subjectValue">Subject (optional)</label>
                <select
                  id="subjectValue"
                  className="form-control"
                  value={contactFormState.subjectValue}
                  onChange={handleContactFormFieldChange}
                >
                  {SUBJECT_DROPDOWN_VALUES.map(value => (
                    <option
                      value={value}
                      key={value}
                    >
                      {value}
                    </option>
                  ))}
                </select>
              </div>

              <StandardFormInput
                label=""
                id="otherSubjectValue"
                type="text"
                autoComplete="off"
                className={`text-white ${contactFormState.otherSubjectVisibility}`}
                inputBackground="#5EAAB6"
                value={contactFormState.otherSubjectValue}
                onChange={handleContactFormFieldChange}
              />

              <StandardFormInput
                validation
                inputValidator={Boolean}
                formSubmitted={contactFormState.formSubmissionAttempted}
                errorMessage="A message is required to submit the contact form"
                label="Message"
                type="textarea"
                id="messageValue"
                autoComplete="off"
                rows="7"
                inputBackground="#5EAAB6"
                value={contactFormState.messageValue}
                onChange={handleContactFormFieldChange}    
                resetInputForgiveness={contactFormState.formSubmitted && ! Boolean(contactFormState.formError)}            
              />

              <button
                type="submit"
                className="btn btn-lg btn-tertiary contact-btn my-2 px-4"
                onClick={handleFormSubmit}
                disabled={contactFormState.formPending}
              >
                <strong>
                {contactFormState.formPending && <i className="fas fa-circle-notch fa-spin"></i>} Send
                </strong>
              </button>
            </form>

            <AsyncAlert
              color="danger"
              isOpen={Boolean(contactFormState.formError) && contactFormState.formAlertVisible}
              changeIsOpen={handleAlertVisibility}
              visibleTime={5}
            >
              <i className="fas fa-bug"></i> {contactFormState.formError}
            </AsyncAlert>
          </div>

          <div className="col-6 col-xxxl-7 text-center d-none d-xl-block pt-7">
            <img src={CollectingQuestionsImg} alt="Collecting questions" className="collecting-questions-img img-fluid" />
          </div>
        </div>
      </div>
    </section>
  )
};

export default Contact;
import getStateByName from './stateAbbreviations';
import getCountryByName from './countryCodes';

export const locationFormatter = (city, state) => {
    if (city && state) {
        return `${city}, ${getStateByName(state)[0].abbreviation}`;
    } else if (! city && state) {
        return `${getStateByName(state)[0].abbreviation}`;
    } else {
        `unknown location`;
    }
};

export const locationWithCountryFormatter = (city, state, country) => {
    if (country) {
        return `${locationFormatter(city, state)} ${getCountryByName(country)[0].iso3}`;
    } else {
        return locationFormatter(city, state);
    }
}

export default locationFormatter;
import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import config from '../../config';
import isValidEmail from '../../utils/isValidEmail';
import SignUpForm from './components/SignUpForm';
import SmallFormBoxPageContent from '../../components/SmallFormBoxPageContent';
import { parseFullName } from 'parse-full-name';
import Alert from '../../components/Alert';

const SignUp = ({}) => {
  const [ form, setForm ] = useState({
    email: '',
    password: '',
    fullName: ''
  });
  const [ createCollectorResponse, setCreateCollectorResponse ] = useState({});
  const [ signUpRequestPending, setSignUpRequestPending ] = useState(false);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.google.com/recaptcha/api.js?render=6LcQerAUAAAAAPq82AqHRK3m5VKlIeg6t6CHihub';
    document.body.appendChild(script);

    return () => {
      script.remove();
      Array.from(document.getElementsByTagName('script'))
        .filter(script => script.src.includes('recaptcha'))
        .map(script => script.remove());

      document.getElementsByClassName('grecaptcha-badge')[0].parentNode.remove()
    }
  }, []);

  const handleFormFieldChange = field => e => {
    e.preventDefault();
    setForm({ ...form, [field]: e.target.value });
  };

  const createCollector = token => {
    // parse form field data
    const {
      email,
      password,
      fullName
    } = form;

    const {
      first: firstName,
      middle: middleName,
      last: lastName,
      suffix
    } = parseFullName(fullName);

    return fetch('/api/collectors', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        email,
        password,
        firstName,
        middleName,
        lastName,
        suffix,
        captchaToken: token
      })
    }).then(response => response.json())
  };

  const handleFormSubmit = e => {
    // don't allow actual form submission
    e.preventDefault();

    const {
      username,
      email,
      password,
      passwordConfirm
    } = form;

    // console.log('prepping to submit form')
    // console.log('username &&',username)
    // console.log('email &&',email)
    // console.log('isValidEmail(email) &&',isValidEmail(email))
    // console.log('password',password)

    // make API request create account only if form not already submitted
    // and appropriate fields filled out appropriately
    if (
      ! signUpRequestPending &&
      email &&
      isValidEmail(email) &&
      password
    ) {
      // console.log('form submission')
      setSignUpRequestPending(true);

      // Run google's recaptcha
      grecaptcha.ready(() => {
        grecaptcha.execute(config.reCaptchaSiteKey, {action: 'homepage'})

          // create collector (google re-captcha token needed)
          .then(token => createCollector(token))
          .then(response => {
            // update state to reflect completed form submission
            // and results of collector creation
            setCreateCollectorResponse(prevState => ({
              ...prevState,
              ...response
            }));
            setSignUpRequestPending(false);
          });
      });
    }
  }

  return (
    <SmallFormBoxPageContent title="Sign Up">
      {/* Display error */}
      {createCollectorResponse.error === true && (
        <Alert
          className="alert-danger"
          faIcon="fa-exclamation-circle"
          text={`Error: ${createCollectorResponse.message}`}
        />
      )}

      {/* Display form if error or no submission */}
      {(createCollectorResponse.error === undefined || createCollectorResponse.error === true) && (
          <SignUpForm
            onFormFieldChange={handleFormFieldChange}
            onSubmit={handleFormSubmit}
            formValues={form}
            signUpRequestPending={signUpRequestPending}
          />
      )}

      {/* Display success message and no form */}
      {createCollectorResponse.error === false && (
        <Alert
          className="alert-success w-100"
          faIcon="fa-check-circle"
          text={`Success: ${createCollectorResponse.message}`}
        />
      )}
    </SmallFormBoxPageContent>
  );
}

export default SignUp;
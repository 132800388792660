import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import config from '../../config';

import { verifyLoginIfNeeded, logoutIfNeeded } from '../../state/login/actions';
import { getCollection } from '../../state/insects/actions';
import Navigation from '../Navigation';
import Footer from '../Footer';
import DefaultPageLayout from '../DefaultPageLayout';

/**
 * Create a Route that checks for login status
 * Only allows access to route if user logged in
 * Redirects to the login page if user not logged in
 */
const AuthorizedRoute = ({
  children,
  loginStatusChecked,
  requestPending,
  userLoggedIn,
  collector,
  token,
  dispatch,
  getCollection,
  verifyLoginIfNeeded,
  logoutIfNeeded,
  ...rest
}) => {
  let tokenExpiration;
  if (token) {
    // put token in milliseconds for direct JS time comparison
    try {
      tokenExpiration = jwt.verify(token, config.jwt.publicKey).exp * 1000;
    } catch (error) {
      // console.log('time', tokenExpiration - (new Date()).getTime());
      // console.log('login token error')
      tokenExpiration = Number.NEGATIVE_INFINITY;
    }
  }
  useEffect(() => {
    verifyLoginIfNeeded();
    if ((new Date()).getTime() > tokenExpiration) {
      logoutIfNeeded();
    }
  });
  // console.log('collector', collector)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // console.log('login request pending:', requestPending)
        // console.log('token status:', token);
        // console.log('login status checked:', loginStatusChecked);
        if (userLoggedIn && (new Date()).getTime() < tokenExpiration) {
          return (
            <DefaultPageLayout>
              {children}
            </DefaultPageLayout>
          )
        } else if (loginStatusChecked) {
          // console.log('redirecting out of authorized route')
          return (
            <Redirect
              push
              to={{
                pathname: '/login',
                state: { from: location }
              }}
            />
          )
        }
      }}
    />
  );
}

const mapStateToProps = (
  { 
    userLoginState: {
      loginStatusChecked,
      userLoggedIn,
      requestPending,
      token
    },
    collector,
  },
  ownProps
) => ({
  component: ownProps.component,
  loginStatusChecked,
  userLoggedIn,
  requestPending,
  token,
  collector
});

const mapDispatchToProps = dispatch => ({
  getCollection: () => dispatch(getCollection()),
  verifyLoginIfNeeded: () => dispatch(verifyLoginIfNeeded()),
  logoutIfNeeded: () => dispatch(logoutIfNeeded())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthorizedRoute);
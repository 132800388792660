import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// images
import logoWhite from './images/net v1.2 (white logo).svg';

// css
import './index.css';

const Footer = ({ userLoggedIn }) => {
  return (
    <footer className="bg-secondary py-0.75 py-md-1.25 footer d-print-none">
      <div className="container d-md-flex align-items-center text-center">
        <Link to={userLoggedIn ? "/collection" : "/"} className="navbar-brand mr-0 mr-md-1 pt-1 pt-md-0"><img src={logoWhite} alt="logo without words" className="footer-logo" /></Link>
        <ul className="footer-nav d-block d-md-flex text-center mr-auto">
          <li className="px-md-1 pt-1.5 py-md-0">
            <Link to="/about" className="footer-nav-link">About</Link>
          </li>
          <li className="px-md-1 pt-1 pb-1.5 py-md-0">
            <Link to="/contact" className="footer-nav-link">Contact</Link>
          </li>
        </ul>
        <ul className="footer-copyright ml-auto pb-0.5 pb-md-0">
          <li className="nav-item">
            &copy; 2020 Tim Kennell Jr.
          </li>
        </ul>
      </div>
    </footer>
  );
};

const mapStateToProps = ({ userLoginState: { userLoggedIn }}) => ({
  userLoggedIn
});

export default connect(
  mapStateToProps,
  null
)(Footer);
import './index.css';
import React from 'react';
import PropTypes from 'prop-types';

const IndividualTaxon = ({
  hierarchyLevel,
  taxonName,
  commonName,
  onClick: handleClick
}) => {
  let commonNameString;
  if (Array.isArray(commonName)) {
    commonNameString = commonName
      .map(name => name.split('$'))
      .filter(name => name[2] === 'English')
      .map(name => name[1])
      .join(', ');
  } else if (commonName && ! Array.isArray(commonName)) {
    commonNameString = commonName;
  }
  
  if (! commonName || ! commonNameString ) {
    commonNameString = 'No common name';
  }

  return (
    <div
      className="individual-taxon alert border-0 text-white"
      onClick={handleClick}
    >
      <p>{hierarchyLevel} - {taxonName}</p>
      <p><small>{commonNameString}</small></p>
      <i className="fas fa-caret-right navigation"></i>
    </div>
  );
}

IndividualTaxon.propTypes = {
  hierarchyLevel: PropTypes.string.isRequired,
  taxonName: PropTypes.string.isRequired,
  commonName: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array
   ]) // In the form from an itis.gov query
}

export default IndividualTaxon;
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

// custom components
import { Modal } from 'reactstrap';

/**
 * Creates a basic modal using the reactstrap library and formats it for the Data Bugs website
 *    Intended to be used with the <BasicModalHeader />, <BasiModalBody />, and <BasicModalFooter /> components
 * @param {Boolean} isOpen Determines whether or not the modal is open
 * @param {Function} toggle Function toggles the modal open and closed based on clicks to event areas (outside modal)
 * @param {String} className The classes to apply to the modal
 */
const BasicModal = ({
  isOpen,
  toggle,
  className,
  children
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} className={`basic-modal ${className}`}>
      {children}
    </Modal>
  );
};

BasicModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default BasicModal;
import React, { Component } from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

const SmallFormBoxInput = ({
  label,
  helperText,
  id,
  className,
  value,
  onChange: handleChange,
  componentRef,
  ...restOfProps
}) => {
  return (
    <div className={`form-group small-form-box-input-group ${className}`}>
      <label htmlFor={id}>{label}</label>
      <input
        id={id}
        className="form-control"
        aria-describedby={label}
        value={value}
        onChange={handleChange}
        ref={componentRef}
        {...restOfProps}
      />
      {helperText && <small className="form-text">{helperText}</small>}
    </div>
  );
};

SmallFormBoxInput.propTypes = {
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
};

export default SmallFormBoxInput;
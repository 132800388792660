import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

const FormSectionHeader = ({
  onClick: handleClick,
  sectionIsOpen,
  id,
  className,
  children
}) => {
  let iconState = 'fa-chevron-right';
  if (sectionIsOpen) {
    iconState = 'fa-chevron-down';
  }
  return (
    <h2
      id={id}
      className={`form-section-header d-inline-flex align-items-center w-100 my-1 my-md-1.5 ${className}`}
      onClick={handleClick}
    >
      {children} <i className={`section-header-icon-status fas ${iconState} ml-1`}></i>
    </h2>
  );
};

FormSectionHeader.propTypes = {
  onClick: PropTypes.func,
  sectionIsOpen: PropTypes.bool,
  id: PropTypes.string,
  className: PropTypes.string
};

FormSectionHeader.defaultProps = {
  sectionIsOpen: false
};

export default FormSectionHeader;
import React from 'react';

// css
import './index.css';

// custom components
import BasicModal from '../../../../components/BasicModal';
import BasicModalHeader from '../../../../components/BasicModalHeader';
import BasicModalBody from '../../../../components/BasicModalBody';
import BasicModalFooter from '../../../../components/BasicModalFooter';

const PrintingHelpModal = ({
  isOpen,
  toggle,
  className
}) => {
  return (
     <BasicModal
      isOpen={isOpen}
      toggle={toggle}
     >
      <BasicModalHeader
        toggle={toggle}
      >
        <h1>Printing Help</h1>
      </BasicModalHeader>
      <BasicModalBody
        className="printing-help-modal-body"
      >
        <h2>Expected Label Paper Size</h2>
        <ul className="list-unstyled">
          <li><strong>Standard Labels</strong>:  8.5" x 11"</li>
          <li><strong>Odonata Labels</strong>:  3" x 5"</li>
        </ul>

        <h2 className="pt-2">Changing Printer Settings</h2>
        <p>Each browser is a little different, but here are some basic steps to tell the printer which paper size to use.</p>
        <ol>
          <li>Click the "Print Labels" button</li>
          <li>Check that the "Destination" is the appropriate printer</li>
          <li>Locate "More Settings"</li>
          <li>Find "Paper Size"</li>
          <li>Select the appropriate size ("Letter" = 8.5" x 11")</li>
        </ol>
      </BasicModalBody>
      <BasicModalFooter />
    </BasicModal>
  )
};

export default PrintingHelpModal;
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

// utils
import { handleHttpResponse } from '../../utils/handleFetchHttpErrors';

// custom components
import SmallFormBoxPageContent from '../../components/SmallFormBoxPageContent';
import SmallFormBoxInput from '../../components/SmallFormBoxInput';
import AsyncAlert from '../../components/AsyncAlert';

const PasswordResetRequest = () => {
  let email = useRef(null);
  const [ emailValue, setEmailValue ] = useState('');
  const [ formSubmissionState, setFormSubmissionState ] = useState({
    formSubmitted: false,
    submissionPending: false,
    formError: '',
    formErrorAlertVisible: false
  });

  useEffect(() => {
    email.current.focus();
  }, []);

  /**
   * Turns off the alert visibility error
   */
  const handleFormSubmissionErrorAlertVisibility = () => setFormSubmissionState(prevState => ({
    ...prevState,
    formErrorAlertVisible: false
  }));

  /**
   * Submits the information in the password reset form
   * @param {Object} e The event object containing the information about the submit button click
   * @return {Void} (changes state)
   */
  const handleFormSubmit = e => {
    e.preventDefault();
    setFormSubmissionState(prevState => ({
      ...prevState,
      formSubmissionPending: true
    }))
    fetch('/api/collectors/password-reset-token', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        email: emailValue
      })
    })
      .then(handleHttpResponse)
      .then(response => setFormSubmissionState(prevState => ({
        ...prevState,
        formError: '',
        formErrorAlertVisible: false,
        formSubmissionPending: false,
        formSubmitted: true
      })))
      .catch(error => setFormSubmissionState(prevState => ({
        ...prevState,
        formError: error.message,
        formErrorAlertVisible: true,
        formSubmissionPending: false,
        formSubmitted: true
      })));
  };

  // console.log('password reset request form:', emailValue, formSubmissionState);

  return (
    <SmallFormBoxPageContent title="Password Reset Request">
      {(! formSubmissionState.formSubmitted || Boolean(formSubmissionState.formError)) && (
        <div>
          <SmallFormBoxInput
            label="Email"
            helperText="Please provide the email associated with the account for which you would like to reset the password.  If the account exists, we will send a password reset link to the email address."
            id="password-reset-email"
            type="text"
            value={emailValue}
            onChange={e => setEmailValue(e.target.value)}
            autoComplete="email"
            componentRef={email}
          />

          <button
            type="submit"
            className="btn btn-lg btn-tertiary w-100"
            onClick={handleFormSubmit}
            disabled={formSubmissionState.submissionPending}
          >
            {formSubmissionState.submissionPending && <i className="fas fa-circle-notch fa-spin"></i>} <strong>Submit</strong>
          </button>

          <AsyncAlert
            color="danger"
            isOpen={Boolean(formSubmissionState.formError) && formSubmissionState.formErrorAlertVisible}
            changeIsOpen={handleFormSubmissionErrorAlertVisibility}
            visibleTime={5}
          >
            <i className="fas fa-bug"></i> {formSubmissionState.formError}
          </AsyncAlert>
        </div>
      ) || (
        <div className="alert alert-success">
          <i className="fas fa-check-circle"></i> Successfully submitted the form.  If the email is associated with an account, you will receive a password reset link in your inbox.
        </div>
      )}
    </SmallFormBoxPageContent>
  );
};

export default PasswordResetRequest;
const countries = [{
    "iso2":"AF",
    "name":"Afghanistan",
    "iso3":"AFG"

},{
    "iso2":"AX",
    "name":"Aland Islands",
    "iso3":"ALA"

},{
    "iso2":"AL",
    "name":"Albania",
    "iso3":"ALB"

},{
    "iso2":"DZ",
    "name":"Algeria",
    "iso3":"DZA"

},{
    "iso2":"AS",
    "name":"American Samoa",
    "iso3":"ASM"

},{
    "iso2":"AD",
    "name":"Andorra",
    "iso3":"AND"

},{
    "iso2":"AO",
    "name":"Angola",
    "iso3":"AGO"

},{
    "iso2":"AI",
    "name":"Anguilla",
    "iso3":"AIA"

},{
    "iso2":"AQ",
    "name":"Antarctica",
    "iso3":"ATA"

},{
    "iso2":"AG",
    "name":"Antigua And Barbuda",
    "iso3":"ATG"

},{
    "iso2":"AR",
    "name":"Argentina",
    "iso3":"ARG"

},{
    "iso2":"AM",
    "name":"Armenia",
    "iso3":"ARM"

},{
    "iso2":"AW",
    "name":"Aruba",
    "iso3":"ABW"

},{
    "iso2":"AU",
    "name":"Australia",
    "iso3":"AUS"

},{
    "iso2":"AT",
    "name":"Austria",
    "iso3":"AUT"

},{
    "iso2":"AZ",
    "name":"Azerbaijan",
    "iso3":"AZE"

},{
    "iso2":"BS",
    "name":"Bahamas",
    "iso3":"BHS"

},{
    "iso2":"BH",
    "name":"Bahrain",
    "iso3":"BHR"

},{
    "iso2":"BD",
    "name":"Bangladesh",
    "iso3":"BGD"

},{
    "iso2":"BB",
    "name":"Barbados",
    "iso3":"BRB"

},{
    "iso2":"BY",
    "name":"Belarus",
    "iso3":"BLR"

},{
    "iso2":"BE",
    "name":"Belgium",
    "iso3":"BEL"

},{
    "iso2":"BZ",
    "name":"Belize",
    "iso3":"BLZ"

},{
    "iso2":"BJ",
    "name":"Benin",
    "iso3":"BEN"

},{
    "iso2":"BM",
    "name":"Bermuda",
    "iso3":"BMU"

},{
    "iso2":"BT",
    "name":"Bhutan",
    "iso3":"BTN"

},{
    "iso2":"BO",
    "name":"Bolivia",
    "iso3":"BOL"

},{
    "iso2":"BA",
    "name":"Bosnia And Herzegovina",
    "iso3":"BIH"

},{
    "iso2":"BW",
    "name":"Botswana",
    "iso3":"BWA"

},{
    "iso2":"BV",
    "name":"Bouvet Island",
    "iso3":"BVT"

},{
    "iso2":"BR",
    "name":"Brazil",
    "iso3":"BRA"

},{
    "iso2":"IO",
    "name":"British Indian Ocean Territory",
    "iso3":"IOT"

},{
    "iso2":"BN",
    "name":"Brunei Darussalam",
    "iso3":"BRN"

},{
    "iso2":"BG",
    "name":"Bulgaria",
    "iso3":"BGR"

},{
    "iso2":"BF",
    "name":"Burkina Faso",
    "iso3":"BFA"

},{
    "iso2":"BI",
    "name":"Burundi",
    "iso3":"BDI"

},{
    "iso2":"KH",
    "name":"Cambodia",
    "iso3":"KHM"

},{
    "iso2":"CM",
    "name":"Cameroon",
    "iso3":"CMR"

},{
    "iso2":"CA",
    "name":"Canada",
    "iso3":"CAN"

},{
    "iso2":"CV",
    "name":"Cape Verde",
    "iso3":"CPV"

},{
    "iso2":"KY",
    "name":"Cayman Islands",
    "iso3":"CYM"

},{
    "iso2":"CF",
    "name":"Central African Republic",
    "iso3":"CAF"

},{
    "iso2":"TD",
    "name":"Chad",
    "iso3":"TCD"

},{
    "iso2":"CL",
    "name":"Chile",
    "iso3":"CHL"

},{
    "iso2":"CN",
    "name":"China",
    "iso3":"CHN"

},{
    "iso2":"CX",
    "name":"Christmas Island",
    "iso3":"CXR"

},{
    "iso2":"CC",
    "name":"Cocos (Keeling) Islands",
    "iso3":"CCK"

},{
    "iso2":"CO",
    "name":"Colombia",
    "iso3":"COL"

},{
    "iso2":"KM",
    "name":"Comoros",
    "iso3":"COM"

},{
    "iso2":"CG",
    "name":"Congo",
    "iso3":"COG"

},{
    "iso2":"CD",
    "name":"Congo, Democratic Republic",
    "iso3":"COD"

},{
    "iso2":"CK",
    "name":"Cook Islands",
    "iso3":"COK"

},{
    "iso2":"CR",
    "name":"Costa Rica",
    "iso3":"CRI"

},{
    "iso2":"CI",
    "name":"Cote D'Ivoire",
    "iso3":"CIV"

},{
    "iso2":"HR",
    "name":"Croatia",
    "iso3":"HRV"

},{
    "iso2":"CU",
    "name":"Cuba",
    "iso3":"CUB"

},{
    "iso2":"CY",
    "name":"Cyprus",
    "iso3":"CYP"

},{
    "iso2":"CZ",
    "name":"Czech Republic",
    "iso3":"CZE"

},{
    "iso2":"DK",
    "name":"Denmark",
    "iso3":"DNK"

},{
    "iso2":"DJ",
    "name":"Djibouti",
    "iso3":"DJI"

},{
    "iso2":"DM",
    "name":"Dominica",
    "iso3":"DMA"

},{
    "iso2":"DO",
    "name":"Dominican Republic",
    "iso3":"DOM"

},{
    "iso2":"EC",
    "name":"Ecuador",
    "iso3":"ECU"

},{
    "iso2":"EG",
    "name":"Egypt",
    "iso3":"EGY"

},{
    "iso2":"SV",
    "name":"El Salvador",
    "iso3":"SLV"

},{
    "iso2":"GQ",
    "name":"Equatorial Guinea",
    "iso3":"GNQ"

},{
    "iso2":"ER",
    "name":"Eritrea",
    "iso3":"ERI"

},{
    "iso2":"EE",
    "name":"Estonia",
    "iso3":"EST"

},{
    "iso2":"ET",
    "name":"Ethiopia",
    "iso3":"ETH"

},{
    "iso2":"FK",
    "name":"Falkland Islands (Malvinas)",
    "iso3":"FLK"

},{
    "iso2":"FO",
    "name":"Faroe Islands",
    "iso3":"FRO"

},{
    "iso2":"FJ",
    "name":"Fiji",
    "iso3":"FJI"

},{
    "iso2":"FI",
    "name":"Finland",
    "iso3":"FIN"

},{
    "iso2":"FR",
    "name":"France",
    "iso3":"FRA"

},{
    "iso2":"GF",
    "name":"French Guiana",
    "iso3":"GUF"

},{
    "iso2":"PF",
    "name":"French Polynesia",
    "iso3":"PYF"

},{
    "iso2":"TF",
    "name":"French Southern Territories",
    "iso3":"ATF"

},{
    "iso2":"GA",
    "name":"Gabon",
    "iso3":"GAB"

},{
    "iso2":"GM",
    "name":"Gambia",
    "iso3":"GMB"

},{
    "iso2":"GE",
    "name":"Georgia",
    "iso3":"GEO"

},{
    "iso2":"DE",
    "name":"Germany",
    "iso3":"DEU"

},{
    "iso2":"GH",
    "name":"Ghana",
    "iso3":"GHA"

},{
    "iso2":"GI",
    "name":"Gibraltar",
    "iso3":"GIB"

},{
    "iso2":"GR",
    "name":"Greece",
    "iso3":"GRC"

},{
    "iso2":"GL",
    "name":"Greenland",
    "iso3":"GRL"

},{
    "iso2":"GD",
    "name":"Grenada",
    "iso3":"GRD"

},{
    "iso2":"GP",
    "name":"Guadeloupe",
    "iso3":"GLP"

},{
    "iso2":"GU",
    "name":"Guam",
    "iso3":"GUM"

},{
    "iso2":"GT",
    "name":"Guatemala",
    "iso3":"GTM"

},{
    "iso2":"GG",
    "name":"Guernsey",
    "iso3":"GGY"

},{
    "iso2":"GN",
    "name":"Guinea",
    "iso3":"GIN"

},{
    "iso2":"GW",
    "name":"Guinea-Bissau",
    "iso3":"GNB"

},{
    "iso2":"GY",
    "name":"Guyana",
    "iso3":"GUY"

},{
    "iso2":"HT",
    "name":"Haiti",
    "iso3":"HTI"

},{
    "iso2":"HM",
    "name":"Heard Island & Mcdonald Islands",
    "iso3":"HMD"

},{
    "iso2":"VA",
    "name":"Holy See (Vatican City State)",
    "iso3":"VAT"

},{
    "iso2":"HN",
    "name":"Honduras",
    "iso3":"HND"

},{
    "iso2":"HK",
    "name":"Hong Kong",
    "iso3":"HKG"

},{
    "iso2":"HU",
    "name":"Hungary",
    "iso3":"HUN"

},{
    "iso2":"IS",
    "name":"Iceland",
    "iso3":"ISL"

},{
    "iso2":"IN",
    "name":"India",
    "iso3":"IND"

},{
    "iso2":"ID",
    "name":"Indonesia",
    "iso3":"IDN"

},{
    "iso2":"IR",
    "name":"Iran, Islamic Republic Of",
    "iso3":"IRN"

},{
    "iso2":"IQ",
    "name":"Iraq",
    "iso3":"IRQ"

},{
    "iso2":"IE",
    "name":"Ireland",
    "iso3":"IRL"

},{
    "iso2":"IM",
    "name":"Isle Of Man",
    "iso3":"IMN"

},{
    "iso2":"IL",
    "name":"Israel",
    "iso3":"ISR"

},{
    "iso2":"IT",
    "name":"Italy",
    "iso3":"ITA"

},{
    "iso2":"JM",
    "name":"Jamaica",
    "iso3":"JAM"

},{
    "iso2":"JP",
    "name":"Japan",
    "iso3":"JPN"

},{
    "iso2":"JE",
    "name":"Jersey",
    "iso3":"JEY"

},{
    "iso2":"JO",
    "name":"Jordan",
    "iso3":"JOR"

},{
    "iso2":"KZ",
    "name":"Kazakhstan",
    "iso3":"KAZ"

},{
    "iso2":"KE",
    "name":"Kenya",
    "iso3":"KEN"

},{
    "iso2":"KI",
    "name":"Kiribati",
    "iso3":"KIR"

},{
    "iso2":"KR",
    "name":"Korea",
    "iso3":"KOR"

},{
    "iso2":"KW",
    "name":"Kuwait",
    "iso3":"KWT"

},{
    "iso2":"KG",
    "name":"Kyrgyzstan",
    "iso3":"KGZ"

},{
    "iso2":"LA",
    "name":"Lao People's Democratic Republic",
    "iso3":"LAO"

},{
    "iso2":"LV",
    "name":"Latvia",
    "iso3":"LVA"

},{
    "iso2":"LB",
    "name":"Lebanon",
    "iso3":"LBN"

},{
    "iso2":"LS",
    "name":"Lesotho",
    "iso3":"LSO"

},{
    "iso2":"LR",
    "name":"Liberia",
    "iso3":"LBR"

},{
    "iso2":"LY",
    "name":"Libyan Arab Jamahiriya",
    "iso3":"LBY"

},{
    "iso2":"LI",
    "name":"Liechtenstein",
    "iso3":"LIE"

},{
    "iso2":"LT",
    "name":"Lithuania",
    "iso3":"LTU"

},{
    "iso2":"LU",
    "name":"Luxembourg",
    "iso3":"LUX"

},{
    "iso2":"MO",
    "name":"Macao",
    "iso3":"MAC"

},{
    "iso2":"MK",
    "name":"Macedonia",
    "iso3":"MKD"

},{
    "iso2":"MG",
    "name":"Madagascar",
    "iso3":"MDG"

},{
    "iso2":"MW",
    "name":"Malawi",
    "iso3":"MWI"

},{
    "iso2":"MY",
    "name":"Malaysia",
    "iso3":"MYS"

},{
    "iso2":"MV",
    "name":"Maldives",
    "iso3":"MDV"

},{
    "iso2":"ML",
    "name":"Mali",
    "iso3":"MLI"

},{
    "iso2":"MT",
    "name":"Malta",
    "iso3":"MLT"

},{
    "iso2":"MH",
    "name":"Marshall Islands",
    "iso3":"MHL"

},{
    "iso2":"MQ",
    "name":"Martinique",
    "iso3":"MTQ"

},{
    "iso2":"MR",
    "name":"Mauritania",
    "iso3":"MRT"

},{
    "iso2":"MU",
    "name":"Mauritius",
    "iso3":"MUS"

},{
    "iso2":"YT",
    "name":"Mayotte",
    "iso3":"MYT"

},{
    "iso2":"MX",
    "name":"Mexico",
    "iso3":"MEX"

},{
    "iso2":"FM",
    "name":"Micronesia, Federated States Of",
    "iso3":"FSM"

},{
    "iso2":"MD",
    "name":"Moldova",
    "iso3":"MDA"

},{
    "iso2":"MC",
    "name":"Monaco",
    "iso3":"MCO"

},{
    "iso2":"MN",
    "name":"Mongolia",
    "iso3":"MNG"

},{
    "iso2":"ME",
    "name":"Montenegro",
    "iso3":"MNE"

},{
    "iso2":"MS",
    "name":"Montserrat",
    "iso3":"MSR"

},{
    "iso2":"MA",
    "name":"Morocco",
    "iso3":"MAR"

},{
    "iso2":"MZ",
    "name":"Mozambique",
    "iso3":"MOZ"

},{
    "iso2":"MM",
    "name":"Myanmar",
    "iso3":"MMR"

},{
    "iso2":"NA",
    "name":"Namibia",
    "iso3":"NAM"

},{
    "iso2":"NR",
    "name":"Nauru",
    "iso3":"NRU"

},{
    "iso2":"NP",
    "name":"Nepal",
    "iso3":"NPL"

},{
    "iso2":"NL",
    "name":"Netherlands",
    "iso3":"NLD"

},{
"iso2":"AN","name":"Netherlands Antilles"
},{
    "iso2":"NC",
    "name":"New Caledonia",
    "iso3":"NCL"

},{
    "iso2":"NZ",
    "name":"New Zealand",
    "iso3":"NZL"

},{
    "iso2":"NI",
    "name":"Nicaragua",
    "iso3":"NIC"

},{
    "iso2":"NE",
    "name":"Niger",
    "iso3":"NER"

},{
    "iso2":"NG",
    "name":"Nigeria",
    "iso3":"NGA"

},{
    "iso2":"NU",
    "name":"Niue",
    "iso3":"NIU"

},{
    "iso2":"NF",
    "name":"Norfolk Island",
    "iso3":"NFK"

},{
    "iso2":"MP",
    "name":"Northern Mariana Islands",
    "iso3":"MNP"

},{
    "iso2":"NO",
    "name":"Norway",
    "iso3":"NOR"

},{
    "iso2":"OM",
    "name":"Oman",
    "iso3":"OMN"

},{
    "iso2":"PK",
    "name":"Pakistan",
    "iso3":"PAK"

},{
    "iso2":"PW",
    "name":"Palau",
    "iso3":"PLW"

},{
    "iso2":"PS",
    "name":"Palestinian Territory, Occupied",
    "iso3":"PSE"

},{
    "iso2":"PA",
    "name":"Panama",
    "iso3":"PAN"

},{
    "iso2":"PG",
    "name":"Papua New Guinea",
    "iso3":"PNG"

},{
    "iso2":"PY",
    "name":"Paraguay",
    "iso3":"PRY"

},{
    "iso2":"PE",
    "name":"Peru",
    "iso3":"PER"

},{
    "iso2":"PH",
    "name":"Philippines",
    "iso3":"PHL"

},{
    "iso2":"PN",
    "name":"Pitcairn",
    "iso3":"PCN"

},{
    "iso2":"PL",
    "name":"Poland",
    "iso3":"POL"

},{
    "iso2":"PT",
    "name":"Portugal",
    "iso3":"PRT"

},{
    "iso2":"PR",
    "name":"Puerto Rico",
    "iso3":"PRI"

},{
    "iso2":"QA",
    "name":"Qatar",
    "iso3":"QAT"

},{
    "iso2":"RE",
    "name":"Reunion",
    "iso3":"REU"

},{
    "iso2":"RO",
    "name":"Romania",
    "iso3":"ROU"

},{
    "iso2":"RU",
    "name":"Russian Federation",
    "iso3":"RUS"

},{
    "iso2":"RW",
    "name":"Rwanda",
    "iso3":"RWA"

},{
    "iso2":"BL",
    "name":"Saint Barthelemy",
    "iso3":"BLM"

},{
    "iso2":"SH",
    "name":"Saint Helena",
    "iso3":"SHN"

},{
    "iso2":"KN",
    "name":"Saint Kitts And Nevis",
    "iso3":"KNA"

},{
    "iso2":"LC",
    "name":"Saint Lucia",
    "iso3":"LCA"

},{
    "iso2":"MF",
    "name":"Saint Martin",
    "iso3":"MAF"

},{
    "iso2":"PM",
    "name":"Saint Pierre And Miquelon",
    "iso3":"SPM"

},{
    "iso2":"VC",
    "name":"Saint Vincent And Grenadines",
    "iso3":"VCT"

},{
    "iso2":"WS",
    "name":"Samoa",
    "iso3":"WSM"

},{
    "iso2":"SM",
    "name":"San Marino",
    "iso3":"SMR"

},{
    "iso2":"ST",
    "name":"Sao Tome And Principe",
    "iso3":"STP"

},{
    "iso2":"SA",
    "name":"Saudi Arabia",
    "iso3":"SAU"

},{
    "iso2":"SN",
    "name":"Senegal",
    "iso3":"SEN"

},{
    "iso2":"RS",
    "name":"Serbia",
    "iso3":"SRB"

},{
    "iso2":"SC",
    "name":"Seychelles",
    "iso3":"SYC"

},{
    "iso2":"SL",
    "name":"Sierra Leone",
    "iso3":"SLE"

},{
    "iso2":"SG",
    "name":"Singapore",
    "iso3":"SGP"

},{
    "iso2":"SK",
    "name":"Slovakia",
    "iso3":"SVK"

},{
    "iso2":"SI",
    "name":"Slovenia",
    "iso3":"SVN"

},{
    "iso2":"SB",
    "name":"Solomon Islands",
    "iso3":"SLB"

},{
    "iso2":"SO",
    "name":"Somalia",
    "iso3":"SOM"

},{
    "iso2":"ZA",
    "name":"South Africa",
    "iso3":"ZAF"

},{
    "iso2":"GS",
    "name":"South Georgia And Sandwich Isl.",
    "iso3":"SGS"

},{
    "iso2":"ES",
    "name":"Spain",
    "iso3":"ESP"

},{
    "iso2":"LK",
    "name":"Sri Lanka",
    "iso3":"LKA"

},{
    "iso2":"SD",
    "name":"Sudan",
    "iso3":"SDN"

},{
    "iso2":"SR",
    "name":"Suriname",
    "iso3":"SUR"

},{
    "iso2":"SJ",
    "name":"Svalbard And Jan Mayen",
    "iso3":"SJM"

},{
    "iso2":"SZ",
    "name":"Swaziland",
    "iso3":"SWZ"

},{
    "iso2":"SE",
    "name":"Sweden",
    "iso3":"SWE"

},{
    "iso2":"CH",
    "name":"Switzerland",
    "iso3":"CHE"

},{
    "iso2":"SY",
    "name":"Syrian Arab Republic",
    "iso3":"SYR"

},{
    "iso2":"TW",
    "name":"Taiwan",
    "iso3":"TWN"

},{
    "iso2":"TJ",
    "name":"Tajikistan",
    "iso3":"TJK"

},{
    "iso2":"TZ",
    "name":"Tanzania",
    "iso3":"TZA"

},{
    "iso2":"TH",
    "name":"Thailand",
    "iso3":"THA"

},{
    "iso2":"TL",
    "name":"Timor-Leste",
    "iso3":"TLS"

},{
    "iso2":"TG",
    "name":"Togo",
    "iso3":"TGO"

},{
    "iso2":"TK",
    "name":"Tokelau",
    "iso3":"TKL"

},{
    "iso2":"TO",
    "name":"Tonga",
    "iso3":"TON"

},{
    "iso2":"TT",
    "name":"Trinidad And Tobago",
    "iso3":"TTO"

},{
    "iso2":"TN",
    "name":"Tunisia",
    "iso3":"TUN"

},{
    "iso2":"TR",
    "name":"Turkey",
    "iso3":"TUR"

},{
    "iso2":"TM",
    "name":"Turkmenistan",
    "iso3":"TKM"

},{
    "iso2":"TC",
    "name":"Turks And Caicos Islands",
    "iso3":"TCA"

},{
    "iso2":"TV",
    "name":"Tuvalu",
    "iso3":"TUV"

},{
    "iso2":"UG",
    "name":"Uganda",
    "iso3":"UGA"

},{
    "iso2":"UA",
    "name":"Ukraine",
    "iso3":"UKR"

},{
    "iso2":"AE",
    "name":"United Arab Emirates",
    "iso3":"ARE"

},{
    "iso2":"GB",
    "name":"United Kingdom",
    "iso3":"GBR"

},{
    "iso2":"US",
    "name":"United States",
    "iso3":"USA"

},{
    "iso2":"UM",
    "name":"United States Outlying Islands",
    "iso3":"UMI"

},{
    "iso2":"UY",
    "name":"Uruguay",
    "iso3":"URY"

},{
    "iso2":"UZ",
    "name":"Uzbekistan",
    "iso3":"UZB"

},{
    "iso2":"VU",
    "name":"Vanuatu",
    "iso3":"VUT"

},{
    "iso2":"VE",
    "name":"Venezuela",
    "iso3":"VEN"

},{
    "iso2":"VN",
    "name":"Viet Nam",
    "iso3":"VNM"

},{
    "iso2":"VG",
    "name":"Virgin Islands, British",
    "iso3":"VGB"

},{
    "iso2":"VI",
    "name":"Virgin Islands, U.S.",
    "iso3":"VIR"

},{
    "iso2":"WF",
    "name":"Wallis And Futuna",
    "iso3":"WLF"

},{
    "iso2":"EH",
    "name":"Western Sahara",
    "iso3":"ESH"

},{
    "iso2":"YE",
    "name":"Yemen",
    "iso3":"YEM"

},{
    "iso2":"ZM",
    "name":"Zambia",
    "iso3":"ZMB"

},{
    "iso2":"ZW",
    "name":"Zimbabwe",
    "iso3":"ZWE"
}];

const getCountryByName = name => {
    return countries.filter(country =>
        country.name.toLowerCase().includes(name.toLowerCase()) ||
        name.toLowerCase().includes(country.name.toLowerCase())
    );
};

export default getCountryByName;
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';

// css
import './index.css';

// utils
import isValidEmail from '../../utils/isValidEmail';
import getCsrfToken from '../../utils/getCsrfToken';

// custom components
import SideNavContainer from '../../components/SideNavContainer';
import SideNav from '../../components/SideNav';
import SideNavItem from '../../components/SideNavItem';
import SideNavPageContent from '../../components/SideNavPageContent';
import StandardFormInput from '../../components/StandardFormInput';
import { handleHttpResponse } from '../../utils/handleFetchHttpErrors';
import CurrentPasswordModal from './components/CurrentPasswordModal';
import AsyncAlert from '../../components/AsyncAlert';

const UserSettings = ({
  user
}) => {

  const [ emailFormState, setEmailFormState ] = useState({
    emailValue: ''
  })
  const [ nameFormState, setNameFormState ] = useState({
    firstNameValue: '',
    middleNameValue: '',
    lastNameValue: '',
    suffixValue: ''
  });
  const [ passwordFormState, setPasswordFormState ] = useState({
    newPasswordValue: ''
  });
  const [ formSubmissionState, setFormSubmissionState ] = useState({
    formName: '',
    formSubmissionAttempted: false,
    formPending: false,
    formSubmitted: false,
    formError: '',
    formAlertVisible: false
  })
  const [ currentPasswordModalState, setCurrentPasswordModalState ] = useState({
    isOpen: false,
    onSubmit: () => {return;}
  });
  const [ currentPasswordFieldValue, setCurrentPasswordFieldValue ] = useState('');
  const [ passwordFieldType, setPasswordFieldType ] = useState('password');

  useEffect(() => {
    // set email value here
    setEmailFormState(prevState => ({
      ...prevState,
      emailValue: user.email
    }));
    setNameFormState(prevState => ({
      ...prevState,
      firstNameValue: user.firstName,
      middleNameValue: user.middleName,
      lastNameValue: user.lastName,
      suffixValue: user.suffix
    }))
  }, [ user ]);

  /**
   * Verifies that an input field has a value
   * @param {Any} value The value of the input field
   * @return {Boolean} Whether or not the input field has a value
   */
  const validateFilledInput = value => Boolean(value);

  /**
   * Toggles the password fields between 'password' and 'text'
   * @param {String} field The name of the key in the 'passwordFieldType' state
   * @param {Object} e The event object containing information about the event click
   * @return {Void} (changes state)
   */
  const handlePasswordVisibilityClick = e => setPasswordFieldType(passwordFieldType === 'password' ? 'text' : 'password');

  /**
   * Toggles the current password modal open and closed
   * @param {Object} e The event object containing information about the event associated with opening and closing 
   *    the current modal
   * @return {Void} (changes state)
   */
  const toggleCurrentPasswordModal = e => setCurrentPasswordModalState(prevState => ({
    ...prevState,
    isOpen: ! prevState.isOpen
  }));

  /**
   * Updates the form state to reflect changes in the input field identified by its HTML id attribute
   * @param {Object} e The event object containing information about the input field change
   * @return {Void} (changes state)
   */
  const handleEmailFormFieldChange = e => {
    const formFieldId = e.target.id,
          formFieldValue = e.target.value;
    setEmailFormState(prevState => ({
      ...prevState,
      [ formFieldId ]: formFieldValue
    }));
  };

  /**
   * Updates the form state to reflect changes in the input field identified by its HTML id attribute
   * @param {Object} e The event object containing information about the input field change
   * @return {Void} (changes state)
   */
  const handleNameFormFieldChange = e => {
    const formFieldId = e.target.id,
          formFieldValue = e.target.value;
    setNameFormState(prevState => ({
      ...prevState,
      [ formFieldId ]: formFieldValue
    }));
  };

  /**
   * Updates the form state to reflect changes in the input field identified by its HTML id attribute
   * @param {Object} e The event object containing information about the input field change
   * @return {Void} (changes state)
   */
  const handlePasswordFormFieldChange = e => {
    const formFieldId = e.target.id,
          formFieldValue = e.target.value;
    setPasswordFormState(prevState => ({
      ...prevState,
      [ formFieldId ]: formFieldValue
    }));
  };

  /**
   * Submits the information for each form by submitting the appropriate data and updating the appropriate state based on the function provided
   * @param {Function} stateUpdateFunction The 'useState' update function that sets the form's state throughout the submission process
   * @param {Object} data The object of data to submit to the API
   * @param {Array} requiredFields The fields of the data object that are required to be filled
   */
  const handleFormSubmission = (formName, data, requiredFields) => e => {
    e.preventDefault();
    setFormSubmissionState(prevState => ({
      ...prevState,
      formName,
      formSubmitted: false,
      formSubmissionAttempted: true
    }));

    if (requiredFields.every(field => Boolean(data[field]))) {
      setFormSubmissionState(prevState => ({
        ...prevState,
        formPending: true
      }));

      setCurrentPasswordModalState(prevState => ({
        ...prevState,
        isOpen: true,

        // prepping a function to be used by the modal submit button
        onSubmit: currentPassword => e => {
          e.preventDefault();
          // console.log('data to submit:', {
          //   ...data,
          //   currentPassword
          // })
          fetch(`/api/collectors/${user.collectorId}`, {
            method: 'PUT',
            headers: {
              'content-type': 'application/json',
              'x-csrf-token': getCsrfToken()
            },
            body: JSON.stringify({
              ...data,
              currentPassword // this is a closure and won't work
            })
          })
            .then(handleHttpResponse)
            .then(response => setFormSubmissionState(prevState => ({
                ...prevState,
                formSubmitted: true,
                formPending: false,
                formError: '',
                formAlertVisible: true,
                formSubmissionAttempted: false
            })))
            .catch(error => setFormSubmissionState(prevState => ({
              ...prevState,
              formPending: false,
              formSubmitted: true,
              formError: error.message,
              formAlertVisible: true
            })))
            .finally(() => setCurrentPasswordFieldValue(''));
        }
      }));
    }
  };

  const handleFormErrorAlertVisibility = visibility => setFormSubmissionState(prevState => ({
    ...prevState,
    formAlertVisible: visibility
  }));

  // console.log('user settings state:', nameFormState, passwordFormState, passwordFieldType, currentPasswordModalState, 'current password:', currentPasswordFieldValue);

  const EMAIL_FORM = 'Email';
  const NAME_FORM = 'Name';
  const PASSWORD_FORM = 'Password';
  return (
    <SideNavContainer>
      <SideNav>
        <ul className="user-settings-side-nav">
          <li>
            <SideNavItem
              to="personal-information-settings"
              offset={-100}
              activeClass="active-user-setting-nav-item"
            >
              Personal Information
            </SideNavItem>
          </li>
          <li>
            <SideNavItem
              to="password-settings"
              offset={-100}
              activeClass="active-user-setting-nav-item"
            >
              Password
            </SideNavItem>
          </li>
        </ul>
      </SideNav>

      <SideNavPageContent>
        <CurrentPasswordModal
          isOpen={currentPasswordModalState.isOpen}
          toggle={toggleCurrentPasswordModal}
          currentPasswordValue={currentPasswordFieldValue}
          onChangeCurrentPassword={e => setCurrentPasswordFieldValue(e.target.value)}
          onSubmitUserSettingsForm={currentPasswordModalState.onSubmit}
        />

        <section id="personal-information-settings">
          <h1>Personal Information</h1>
          <form>
            <h2 className="mt-3">Email</h2>
            <StandardFormInput
              validation
              label="Email"
              id="emailValue"
              type="text"
              autoComplete="email"
              value={emailFormState.emailValue}
              onChange={handleEmailFormFieldChange}
              inputValidator={isValidEmail}
              errorMessage="Email in the form user@domain.tld is required"
              errorColor="red"
              formSubmitted={formSubmissionState.formName === NAME_FORM && formSubmissionState.formSubmissionAttempted}
            />

            <button
              type="submit"
              className="btn btn-lg btn-primary user-settings-submit-btn mt-1.5"
              onClick={handleFormSubmission(
                EMAIL_FORM,
                {
                  email: emailFormState.emailValue
                },
                [ 'email' ]
              )}
            >
              <strong>Update Email</strong>
            </button>
          </form>

          <form>
            <h2 className="mt-3">Name</h2>
            <p className="mb-1.5"><small>Your name is only used to auto-fill form fields when adding a new insect</small></p>

            <StandardFormInput
              label="First Name"
              id="firstNameValue"
              type="text"
              autoComplete="given-name"
              value={nameFormState.firstNameValue}
              onChange={handleNameFormFieldChange}
            />
            <StandardFormInput
              label="Middle Name"
              id="middleNameValue"
              type="text"
              autoComplete="additional-name"
              value={nameFormState.middleNameValue}
              onChange={handleNameFormFieldChange}
            />
            <StandardFormInput
              label="Last Name"
              id="lastNameValue"
              type="text"
              autoComplete="family-name"
              value={nameFormState.lastNameValue}
              onChange={handleNameFormFieldChange}
            />
            <StandardFormInput
              label="Suffix"
              id="suffixValue"
              type="text"
              autoComplete="honorific-suffix"
              value={nameFormState.suffixValue}
              onChange={handleNameFormFieldChange}
            />

            <button
              type="submit"
              className="btn btn-lg btn-primary user-settings-submit-btn mt-1.5"
              onClick={handleFormSubmission(
                NAME_FORM,
                {
                  firstName: nameFormState.firstNameValue,
                  middleName: nameFormState.middleNameValue,
                  lastName: nameFormState.lastNameValue,
                  suffix: nameFormState.suffixValue
                },
                []
              )}
            >
              <strong>Update Name</strong>
            </button>
          </form>
        </section>
        
        <section id="password-settings" className="mt-5">
          <h1>Password</h1>
          <form>
            <StandardFormInput
              validation
              passwordVisibilityButton
              onClickPasswordVisibility={handlePasswordVisibilityClick}
              label="New Password"
              id="newPasswordValue"
              type={passwordFieldType}
              autoComplete="new-password"
              value={passwordFormState.newPasswordValue}
              onChange={handlePasswordFormFieldChange}
              inputValidator={validateFilledInput}
              errorMessage="Required"
              errorColor="red"
              formSubmitted={formSubmissionState.formName === PASSWORD_FORM && formSubmissionState.formSubmissionAttempted}
            />

            <button
              type="submit"
              className="btn btn-lg btn-primary user-settings-submit-btn mt-1.5 mb-1.5"
              onClick={handleFormSubmission(
                PASSWORD_FORM,
                {
                  newPassword: passwordFormState.newPasswordValue
                },
                [ 'newPassword' ]
              )}
            >
              <strong>Update Password</strong>
            </button>
          </form>
        </section>

        <AsyncAlert
          color="danger"
          isOpen={Boolean(formSubmissionState.formError) && formSubmissionState.formAlertVisible}
          changeIsOpen={handleFormErrorAlertVisibility}
          visibleTime={5}
        >
          <i className="fas fa-bug"></i> {formSubmissionState.formError}
        </AsyncAlert>

        <AsyncAlert
          color="success"
          isOpen={! Boolean(formSubmissionState.formError) && formSubmissionState.formAlertVisible}
          changeIsOpen={handleFormErrorAlertVisibility}
          visibleTime={5}
        >
          <i className="fas fa-check-circle"></i> {formSubmissionState.formName} successfully updated
        </AsyncAlert>
      </SideNavPageContent>
    </SideNavContainer>
  );
};

const mapStateToProps = ({ userLoginState: { user }}) => ({
  user
});

export default connect(
  mapStateToProps,
  null
)(UserSettings);
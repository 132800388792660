import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// custom css
// import './index.css';

// custom props
import IndividualInsect from '../IndividualInsect';


const IndividualInsectRowWrapper = ({
  // required by the react-window package
  data,
  index,
  style,

  // custom props
  collection,
  selectedInsects
}) => {
  // console.log('insect row wrapper data:', data);
  // console.log('insect row wrapper index:', index);
  // console.log('insect row wrapper style:', style);
  // console.log('insect row wrapper collection:', collection);
  const {
    humanReadableIds,
    onSelectInsect: handleSelectInsect, // function that toggles the checkbox on an insect
    onClickInsect: handleClickInsect,   // function that toggles the insect modal
    onDeleteInsect: handleDeleteInsect  // function that handles the deletion of one or more insects
  } = data;
  const humanReadableId = humanReadableIds[index];
  const imgLocation = collection[humanReadableId].images.length
    ? `/api/${humanReadableId}/images/${collection[humanReadableId].images.filter(image => image.primaryImage === 1)[0].imageId}/raw`
    : null;
  return (
    <div style={style}>
      <IndividualInsect
        checked={selectedInsects[humanReadableId]}
        imgLocation={imgLocation}
        insect={collection[humanReadableId]}
        onSelectInsect={() => handleSelectInsect(humanReadableId)}
        onClickInsect={handleClickInsect(humanReadableId)}
        onDeleteInsect={() => handleDeleteInsect([humanReadableId])}
      />
      {/* {collection[humanReadableId]} */}
    </div>
  )
};

IndividualInsectRowWrapper.propTypes = {
  data: PropTypes.shape({
    humanReadableIds: PropTypes.arrayOf(PropTypes.string),
    onSelectInsect: PropTypes.func.isRequired,
    onClickInsect: PropTypes.func.isRequired,
    onDeleteInsect: PropTypes.func.isRequired
  }).isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object.isRequired
};

const mapStateToProps = ({ insects: { collection, selectedInsects }}) => ({
  collection,
  selectedInsects
});

export default connect(
  mapStateToProps
)(IndividualInsectRowWrapper);
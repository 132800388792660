import React, { Component, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import jwt from 'jsonwebtoken';
import config from '../../config';

import { verifyLoginIfNeeded } from '../../state/login/actions';
import Navigation from '../Navigation';
import Footer from '../Footer';
import DefaultPageLayout from '../DefaultPageLayout';

/**
 * Create a Route that checks for login status
 * Only allows access to route if user not logged in
 * Redirects to the homepage if user logged in
 */
const UnauthorizedRoute = ({
  verifyLoginIfNeeded,
  user,
  loginStatusChecked,
  requestPending,
  userLoggedIn,
  children,
  ...rest
}) => {
  useEffect(() => {
    verifyLoginIfNeeded();
  })

  // verify token expiration
  let tokenExpiration;
  if (user) {
    // put token in milliseconds for direct JS time comparison
    try {
      tokenExpiration = jwt.verify(user, config.jwt.publicKey).exp * 1000;
    } catch(error) {
      tokenExpiration = Infinity;
      // console.error('token error:', error);
    }
  }
  // console.log('checking login status:', userLoggedIn)
  // console.log('checking token status:', tokenExpiration)

  return (
    <Route
      {...rest}
      render={({ location }) => {
        // if (loginStatusChecked && userLoggedIn && (new Date()).getTime() < tokenExpiration) {
        if (loginStatusChecked && userLoggedIn && ! requestPending) {
          return (
            <Redirect
              push
              to={{
                pathname: "/collection",
                state: { from: location }
              }}
            />
          )
        } else if (loginStatusChecked) {
          return (
            <DefaultPageLayout>
              {children}
            </DefaultPageLayout>
          )
        }
      }}
    />
  );
};

const mapStateToProps = ({userLoginState: {loginStatusChecked, userLoggedIn, requestPending, user}}, ownProps) => ({
  component: ownProps.component,
  loginStatusChecked,
  userLoggedIn,
  requestPending,
  user
});

const mapDispatchToProps = dispatch => ({
  verifyLoginIfNeeded: () => dispatch(verifyLoginIfNeeded()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnauthorizedRoute);
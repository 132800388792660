import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

/**
 * Creates the footer for the basic modal
 */
const BasicModalFooter = ({
  className,
  children
}) => {
  return (
    <div className={`basic-modal-footer modal-footer bg-quinary ${className}`}>
      {children}
    </div>
  );
};

BasicModalFooter.propTypes = {
  className: PropTypes.string
}

export default BasicModalFooter;
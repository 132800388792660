const flexiblyCreateDateObject = date => {
    let dateObject;
    if (date.constructor === String) {
        dateObject = new Date(date);
    } else if (date.constructor === Date) {
        dateObject = date;
    } else {
        throw new Error('only dates or strings are allowed')
    }
    return dateObject;
}

export default dateString => {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    const dateObject = new Date(dateString);
    return `${monthNames[dateObject.getMonth()].slice(0,3)} ${dateObject.getDate()}, ${dateObject.getFullYear()}`;
}

export const computerizedDateFormatter = date => {
    let dateObject = flexiblyCreateDateObject(date);
    return `${dateObject.getFullYear()}-${('0' + (dateObject.getMonth() + 1)).slice(-2)}-${('0' + dateObject.getDate()).slice(-2)}`;
};

export const computerizedTimeFormatter = date => {
    const dateObject = flexiblyCreateDateObject(date);
    return `${('0' + dateObject.getHours()).slice(-2)}:${('0' + dateObject.getSeconds()).slice(-2)}`;
};

export const getUtcDateTimeString = date => {
    let dateObject = flexiblyCreateDateObject(date);
    let utcDateObject = new Date(dateObject.getTime() + dateObject.getTimezoneOffset() * 60000);
    return `${computerizedDateFormatter(utcDateObject)} ${computerizedTimeFormatter(utcDateObject)}`;
};

export const insectLabelDateFormatter = date => {
    const romanNumeralMonths = [ 'i', 'ii', 'iii', 'iv', 'v', 'vii', 'viii', 'ix', 'x', 'xi', 'xii' ];
    let dateObject = flexiblyCreateDateObject(date);
    return `${dateObject.getDate()}.${romanNumeralMonths[dateObject.getMonth()]}.${dateObject.getFullYear()}`;
}
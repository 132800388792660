export default response => {
    if (! response.ok) {
        // console.log('server error response:', response);
        if (process.env.NODE_ENV === 'production') {
            throw Error(`Server Error ${response.status}: ${response.statusText}`);
        } else {

            throw Error(`Server Error: ${response.url} ${response.status}: (${response.statusText})`)
        }
    } else {
        return response;
    }
}

export const handleHttpResponse = response => {
    let contentType = response.headers.get('content-type');
    if (contentType.includes('application/json')) {
        return handleJsonResponse(response);
    } else if (contentType.includes('text/html')) {
        return handleTextResponse(response);
    } else {
        throw new Error(`Content-Type: ${contentType} is not supported`)
    }
}

const handleJsonResponse = response => {
    return response.json()
        .then(json => {
            if (response.ok && ! json.error) {
                return json;
            } else {
                // console.log('json response error:', json);
                return Promise.reject(Object.assign({}, json, {
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url
                }))
            }
        })
};

const handleTextResponse = response => {
    return response.text()
        .then(text => {
            if (response.ok) {
                return text;
            } else {
                return Promise.reject(Object.assign({
                    status: response.status,
                    statusText: response.statusText,
                    url: response.url,
                    text,
                    message: `Server Error ${response.status} (${response.statusText}): ${response.url}`
                }))
            }
        })
}
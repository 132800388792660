import React, { Component, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// css
import './index.css';

// redux
import { prepareInsectUpdate } from '../../../../state/insects/actions';

// utils
import { dmsGpsFormatter } from '../../../../utils/gpsFormats';
import { locationWithCountryFormatter } from '../../../../utils/locationFormats';
import dateFormatter from '../../../../utils/dateFormats';
import fullNameFormatter from '../../../../utils/nameFormats';
import { jsonifyClassificationStringWithRanks } from '../../../../utils/classificationTraversal';
import getCsrfToken from '../../../../utils/getCsrfToken';
import { Modal } from 'reactstrap';

// custom components
import IndividualData from './components/IndividualData';
import InsectModalHeader from './components/InsectModalHeader';


const InsectModal = ({
  className,
  modalOpen,
  toggleModal,
  insect,
  onDeleteInsect: handleDeleteInsect
}) => {
  const [ imgSrc, setImgSrc ] = useState(null);
  useEffect(() => {
    if (insect.images.length > 0) {
      let imgUrl = `/api/specimens/${insect.humanReadableId}/images/${insect.images.filter(image => image.primaryImage === 1)[0].imageId}/raw`;
      fetch(imgUrl, {
        headers: {
          'x-csrf-token': getCsrfToken()
        }
      })
        .then(response => response.blob())
        .then(blob => setImgSrc(URL.createObjectURL(blob)));
    } else {
      setImgSrc(null);
    }
  }, [
    insect.images.length
  ]);

  let mostSpecificInsectClassification = jsonifyClassificationStringWithRanks(insect.classificationStringWithRanks).slice(-1)[0];

  return (
    <Modal isOpen={modalOpen} toggle={toggleModal} className={`individual-insect-modal ${className}`}>
      <InsectModalHeader
        insect={insect}
        imgSrc={imgSrc}
        onDeleteInsect={handleDeleteInsect}
        toggleModal={toggleModal}
      />

      {/* Modal Body */}
      <div className="modal-body bg-quinary px-2">
        <h2>Capture Data</h2>
        <IndividualData dataKey="GPS" dataValue={dmsGpsFormatter(insect.latitude, insect.longitude)} />
        <IndividualData dataKey="Location" dataValue={locationWithCountryFormatter(insect.city, insect.state, insect.country)} />
        <IndividualData dataKey="Date" dataValue={dateFormatter(insect.collectionDate)} />
        <IndividualData dataKey="Finder" dataValue={fullNameFormatter(insect.finderFirstName, insect.finderMiddleName, insect.finderLastName, insect.finderSuffix)} />

        <h2 className="mt-3">Identification Data</h2>
        <IndividualData dataKey="Taxon" dataValue={mostSpecificInsectClassification.taxon} />
        <IndividualData dataKey="Rank" dataValue={mostSpecificInsectClassification.rank} />
        <IndividualData dataKey="Description" dataValue={insect.description} />
        <IndividualData dataKey="Identifier" dataValue={fullNameFormatter(insect.identifierFirstName, insect.identifierMiddleName, insect.identifierLastName, insect.identifierSuffix)} />

      </div>
      <div className="modal-footer bg-quinary"></div>
    </Modal>
  );
}

InsectModal.propTypes = {
  className: PropTypes.string,
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  insect: PropTypes.object.isRequired
};

const mapDispatchToProps = dispatch => ({
  prepareInsectUpdate: insect => dispatch(prepareInsectUpdate(insect))
});

export default connect(
  null,
  mapDispatchToProps
)(InsectModal);
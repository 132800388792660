import React from 'react';
import PropTypes from 'prop-types';
import FeatureSectionText from './components/FeatureSectionText';
import FeatureSectionImg from './components/FeatureSectionImage';

import './index.css';

const FeatureSection = ({
  headerText,
  subText,
  featureImgSrc,
  featureImgAlt,
  imgFirst,
  textColor,
  className
}) => {
  let FirstComponent = <FeatureSectionImg
    featureImgSrc={featureImgSrc}
    featureImgAlt={featureImgAlt}
  />;
  
  let SecondComponent = <FeatureSectionText
    headerText={headerText}
    subText={subText}
    textColor={textColor}
  />;

  if (! imgFirst) {
    [ FirstComponent, SecondComponent ] = [ SecondComponent, FirstComponent ];
  }
  return (
    <section className={"py-4.5 py-md-8 " + className}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-12 col-md-6 pb-3.5 pb-md-0">
            {FirstComponent}
          </div>
          <div className="col-12 col-md-6">
            {SecondComponent}
          </div>
        </div>
      </div>
    </section>
  );
};

FeatureSection.propTypes = {
  headerText: PropTypes.string.isRequired,
  subText: PropTypes.string.isRequired,
  featureImgSrc: PropTypes.string.isRequired,
  featureImgAlt: PropTypes.string.isRequired,
  imgFirst: PropTypes.bool,
  textColor: PropTypes.string,
  className: PropTypes.string
};

FeatureSection.defaultProps = {
  imgFirst: true,
};

export default FeatureSection;
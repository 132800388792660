import './index.css';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Alert } from 'reactstrap';

import isInsectSelected from '../../../../utils/isInsectSelected';

const DeleteWarning = ({
  selectedInsects,
  deleteTimer,
  onUndoDeleteClick: handleUndoDeleteClick
}) => {
  const [ deleteInsectWarningVisible, setDeleteInsectWarningVisible ] = useState(deleteTimer ? true : false);
  const handleToggleDeleteInsectWarning = () => setDeleteInsectWarningVisible(! deleteInsectWarningVisible)

  useEffect(() => {
    if (deleteTimer) {
      setDeleteInsectWarningVisible(true);
    }
  }, [deleteTimer])

  return (
    <Alert
      className="async-alert"
      color="danger"
      fade={true}
      isOpen={deleteTimer && deleteInsectWarningVisible ? true : false}
      toggle={handleToggleDeleteInsectWarning}
      style={{whiteSpace: 'no-wrap'}}
    >
      <button
        className="btn btn-danger mr-3"
        onClick={handleUndoDeleteClick}
      >
        <i className="fas fa-undo"></i> Undo
      </button>
      <i className="fas fa-circle-notch fa-spin"></i> Deleting insects
    </Alert>
  )
};

DeleteWarning.propTypes = {
  selectedInsects: PropTypes.object.isRequired,
  deleteTimer: PropTypes.number,
  onUndoDeleteClick: PropTypes.func
}

const mapStateToProps = ({ insects: {selectedInsects} }, ownProps) => ({
  selectedInsects,
  deleteTimer: ownProps.deleteTimer,
  onUndoDeleteClick: ownProps.onUndoDeleteClick
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DeleteWarning);
import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter,
  Route,
  Redirect,
  Switch
} from 'react-router-dom';
import { Provider, connect } from 'react-redux';
import store from './state/store';

import './assets/css/custom.min.css';

import DefaultRoute from './components/DefaultRoute';
import AuthorizedRoute from './components/AuthorizedRoute';
import UnauthorizedRoute from './components/UnauthorizedRoute';
import ScrollToTop from './components/ScrollToTop';

import Home from './views/Home';
import Login from './views/Login';
import SignUp from './views/SignUp';
import Collection from './views/Collection';
import InsectEntry from './views/InsectEntry';
import Labels from './views/Labels';
import About from './views/About';
import PasswordResetRequest from './views/PasswordResetRequest';
import PasswordReset from './views/PasswordReset';
import UserSettings from './views/UserSettings';
import Contact from './views/Contact';

// const Home = React.lazy(() => import('./views/Home'));
// const Login = React.lazy(() => import('./views/Login'));
// const SignUp = React.lazy(() => import('./views/SignUp'));
// const Collection = React.lazy(() => import('./views/Collection'));
// const InsectEntry = React.lazy(() => import('./views/InsectEntry'));
// const Labels = React.lazy(() => import('./views/Labels'));
// const About = React.lazy(() => import('./views/About'));
// const PasswordResetRequest = React.lazy(() => import('./views/PasswordResetRequest'));
// const PasswordReset = React.lazy(() => import('./views/PasswordReset'));
// const UserSettings = React.lazy(() => import('./views/UserSettings'));
// const Contact = React.lazy(() => import('./views/Contact'));

// import App from './app';

class App extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Switch>
        <DefaultRoute exact path="/about">
          <About />
        </DefaultRoute>
        <DefaultRoute exact path="/contact">
          <Contact />
        </DefaultRoute>

        <UnauthorizedRoute exact path="/">
          <Home />
        </UnauthorizedRoute>
        <UnauthorizedRoute exact path="/login">
          <Login />
        </UnauthorizedRoute>
        <UnauthorizedRoute exact path="/sign-up">
          <SignUp />
        </UnauthorizedRoute>
        <UnauthorizedRoute exact path="/password-reset-request">
          <PasswordResetRequest />
        </UnauthorizedRoute>
        <UnauthorizedRoute exact path="/collectors/:collectorId/password-reset">
          <PasswordReset />
        </UnauthorizedRoute>

        <AuthorizedRoute exact path="/collection">
          <Collection />
        </AuthorizedRoute>
        <AuthorizedRoute exact path="/entry">
          <InsectEntry />
        </AuthorizedRoute>
        <AuthorizedRoute path={"/edit/:humanReadableId" }>
          <InsectEntry />
        </AuthorizedRoute>
        <AuthorizedRoute path="/labels">
          <Labels />
        </AuthorizedRoute>
        <AuthorizedRoute path="/user-settings">
          <UserSettings />
        </AuthorizedRoute>

        <Redirect exact path="/edit" to="/collection" />
        <Redirect path="*" to="/" />
      </Switch>
    );
  }
}

// App = connect()(App);

ReactDOM.render(
  <Provider store={store}>
    {/* <React.Suspense fallback="loading..."> */}
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    {/* </React.Suspense> */}
  </Provider>,
  document.getElementById('root')
);
module.hot.accept();
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup, Button, DropdownToggle, DropdownMenu, DropdownItem, ButtonDropdown } from 'reactstrap';

// css
import './index.css';

const CollectionHeader = ({
  onDeleteClick: handleDeleteClick,
  onSearchClick: handleSearchClick
}) => {
  const [ dropdownOpen, setDropdownOpen ] = useState(false);
  const history = useHistory();

  return (
    <div className="row mb-1.25 mb-sm-2">
      <div className="col-lg-3">
        <h1 className="pb-sm-1.25 pb-lg-0">Collection</h1>
      </div>
      <div className="collection-actions col-lg-9 text-lg-right d-inline-flex flex-fill justify-content-around justify-content-sm-between d-lg-inline">
        <Link
          to="/entry"
          className="btn responsive-btn btn-primary d-inline-flex align-items-center px-2 px-sm-0.75"
        >
          <i className="fas fa-plus mr-sm-0.5"></i><span className="d-none d-sm-inline">Add</span>
        </Link>

        <button
          className="btn responsive-btn btn-secondary d-inline-flex align-items-center ml-sm-1 ml-xl-2 px-2 px-sm-0.75"
          onClick={handleDeleteClick}
        >
          <i className="fas fa-trash-alt mr-sm-0.5"></i><span className="d-none d-sm-inline">Delete</span>&nbsp;<span className="d-none d-md-inline">Selected</span>
        </button>

        <button
          className="btn responsive-btn btn-tertiary d-inline-flex align-items-center ml-sm-1 ml-xl-2 px-2 px-sm-0.75"
          onClick={handleSearchClick}
        >
          <i className="fas fa-search mr-sm-0.5"></i><span className="d-none d-sm-inline">Search</span>
        </button>
        <ButtonGroup className="label-btn-group btn-group btn-group-tertiary-border text-dark ml-sm-1 ml-xl-2">
          <Button
            // className="responsive-btn btn-tertiary-border text-dark d-inline-flex align-items-center px-2.5 px-sm-0.75"
            className="responsive-btn btn-tertiary-border text-dark d-inline-flex align-items-center px-2 px-sm-0.75"
            onClick={() => history.push({
              pathname: '/labels',
              state: { labelType: 'both' }
            })}
          >
            <i className="fas fa-tags mr-sm-0.5"></i><span className="d-none d-sm-inline">Labels</span>
          </Button>
          <ButtonDropdown isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
            <DropdownToggle className="dropdown-btn btn-tertiary-border text-dark d-inline-flex align-items-center">
              <i className="fas fa-caret-down d-none d-sm-inline"></i>
              <i className="fas fa-caret-up d-inline d-sm-none"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                onClick={() => history.push({
                  pathname: '/labels',
                  state: { labelType: 'location' }
                })}
              >
                Location Labels
              </DropdownItem>
              <DropdownItem
                onClick={() => history.push({
                  pathname: '/labels',
                  state: { labelType: 'identification' }
                })}
              >
                Identification Labels
              </DropdownItem>
              <DropdownItem
                onClick={() => history.push({
                  pathname: '/labels',
                  state: { labelType: 'envelope' }
                })}
              >
                Glassine Envelope Labels
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </ButtonGroup>

      </div>
    </div>
  )
};

CollectionHeader.propTypes = {
  onDeleteClick: PropTypes.func.isRequired
};

export default CollectionHeader;
const round = (value, precision) => {
    const multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

/**
 * Converts a decimal GPS coordinate to degrees, minutes, and seconds notation
 * @param {Number} number Number with a decimal or not
 * @return {String} The GPS coordinate string without direction
 */
const convertToDms = number => {
    let dmsString = '';
    let newNumber = Math.abs(number);
    let symbolArray = ['\xB0', '\'', '"']
    for (let i = 0; i < 2; i++) {
        let integer = Math.floor(newNumber);
        let decimal = newNumber - integer;
        newNumber = decimal * 60;
        dmsString += integer + symbolArray[i];
    }
    return dmsString + round(newNumber, 1).toString() + symbolArray[2];
}

/**
 * Converts decimal GPS coordinates straight to a string for display
 * @param {Number} latitude Latitude of a GPS coordinates in decimal format
 * @param {Number} longitude Longitude of GPS coordinates in decimal format
 * @return {String} The string representation of the GPS coordinates
 */
export const truncatedDecimalGpsFormatter = (latitude, longitude) => {
    return `${round(latitude, 2)}, ${round(longitude, 2)}`;
};

/**
 * Converts a set of decimal GPS coordinates into degrees, minutes, and seconds
 *      and returns them as a string
 * @param {Number} latitude Latitude of a GPS coordinates in decimal format
 * @param {Number} longitude Longitude of GPS coordinates in decimal format
 * @return {String} The GPS coordinates string represented as degrees, minutes, and seconds
 */
export const dmsGpsFormatter = (latitude, longitude) => {
    latitude = parseFloat(latitude);
    longitude = parseFloat(longitude);
    const latitudeDirection = latitude > 0
        ? 'N'
        : 'S';
    const longitudeDirection = longitude > 0
        ? 'E'
        : 'W';
    return convertToDms(latitude) +
        latitudeDirection + ' ' +
        convertToDms(longitude) +
        longitudeDirection;
}

export default truncatedDecimalGpsFormatter;
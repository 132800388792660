/**
 * Determines if a single insect is selected by taking in an object with human-readable IDs as keys
 * @param {Object} selectedInsects Keys are the human-readable IDs with boolean values
 *    indicating whether or not an insect is selected
 * @return {Boolean} Whether or not an insect is selected
 */
const isInsectSelected = selectedInsects => {
  if (! selectedInsects) {
    return false;
  } else {
    // console.log('selected insects in warning:', selectedInsects)
    for (let humanReadableId in selectedInsects) {
      if (selectedInsects[humanReadableId] === true) {
        // return true as soon as one selected insect is detected
        return true;
      }
    }
    return false;
  }
};

export default isInsectSelected;

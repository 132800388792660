// State
// newInsect: {}

// actions
// a. add insect
// b. edit insect
// 1. add data element
// 2. update identification
// 3. add new insect

/* ============================
 * import 
 * ============================ */
import handleFetchHttpErrors, { handleHttpResponse } from '../../utils/handleFetchHttpErrors';
import { replacePrimaryInsectImage } from '../insect_images/actions';
import getCsrfToken from '../../utils/getCsrfToken';

/* ============================
 * Get Collection Actions
 * ============================ */

export const GET_COLLECTION_REQUEST = 'GET_COLLECTION_REQUEST';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_FAIL = 'GET_COLLECTION_FAIL';

export const getCollectionRequest = () => ({
    type: GET_COLLECTION_REQUEST
});

export const getCollectionSuccess = collection => ({
    type: GET_COLLECTION_SUCCESS,
    collection
});

export const getCollectionFail = message => ({
    type: GET_COLLECTION_FAIL,
    message
});

export const getCollection = () => {
    return dispatch => {
        dispatch(getCollectionRequest());
        return fetch('/api/specimens', {
            headers: {
                'x-csrf-token': getCsrfToken()
            },
        })
            .then(handleHttpResponse)
            .then(response => dispatch(getCollectionSuccess(response.data)))
            .catch(error => {
                console.error(error);
                return dispatch(getCollectionFail(error.message));
            });
    }
};

export const getCollectionIfNeeded = () => {
    return (dispatch, getState) => {
        if (! getState().insects.collectionRequest.fullCollectionAcquired) {
            return dispatch(getCollection());
        }
    }
};

export const GET_INSECT_REQUEST = 'GET_INSECT_REQUEST';
export const GET_INSECT_SUCCESS = 'GET_INSECT_SUCCESS';
export const GET_INSECT_FAIL = 'GET_INSECT_FAIL';


/* ============================
 * Add Insect Actions
 * ============================ */

export const ADD_INSECT_REQUEST = 'ADD_INSECT_REQUEST'; // should populate 'newInsect' field
export const ADD_INSECT_SUCCESS = 'ADD_INSECT_SUCCESS'; // should add 'newInsect' to 'collection' and clear 'newInsect'
export const ADD_INSECT_FAIL = 'ADD_INSECT_FAIL'; // should keep 'newInsect' and NOT add to 'collection'
export const RESET_ADD_INSECT = 'RESET_ADD_INSECT';

export const addInsectRequest = () => ({
    type: ADD_INSECT_REQUEST
});

export const addInsectSuccess = insect => ({
    type: ADD_INSECT_SUCCESS,
    insect
});

export const addInsectFail = message => ({
    type: ADD_INSECT_FAIL,
    message
});

export const addInsect = insectFormData => {
    let insect = {};
    insectFormData.forEach((value, key) => {insect[key] = value;}); // convert formdata to JSON

    return dispatch => {
        dispatch(addInsectRequest());
        return fetch('/api/specimens', {
            headers: {
                'x-csrf-token': getCsrfToken()
            },
            method: 'POST',
            body: insectFormData // expecting the information to be in FormData() object
        })
            .then(handleHttpResponse)
            .then(response => dispatch(addInsectSuccess(response.data)))
            .catch(error => {
                console.error(error);
                return dispatch(addInsectFail(error.message))
            });
    };
};

export const resetAddInsect = () => ({
    type: RESET_ADD_INSECT
})

/* ============================
 * Select Insect Actions
 * ============================ */

export const TOGGLE_SELECTED_INSECT = 'TOGGLE_SELECTED_INSECT';
export const SELECT_INSECT_GROUP = 'SELECT_INSECT_GROUP';
export const UNSELECT_INSECT_GROUP = 'UNSELECT_INSECT_GROUP';

export const toggleSelectedInsect = humanReadableId => ({
    type: TOGGLE_SELECTED_INSECT,
    humanReadableId
})

/**
 * Fires an action for selecting an entire group
 * @param {Object} humanReadableIdVisibleObject Keys are human-readable IDs and values are booleans
 */
export const selectInsectGroup = humanReadableIdVisibleObject => ({
    type: SELECT_INSECT_GROUP,
    humanReadableIdVisibleObject
})

/**
 * Fires an action for selecting an entire group
 * @param {Object} humanReadableIdVisibleObject Keys are human-readable IDs and values are booleans
 */
export const unselectInsectGroup = humanReadableIdVisibleObject => ({
    type: UNSELECT_INSECT_GROUP,
    humanReadableIdVisibleObject
});

/* ============================
 * Update Insect Actions
 * ============================ */

export const UPDATE_INSECT_PREP = 'UPDATE_INSECT_PREP';
export const UPDATE_INSECT_REQUEST = 'UPDATE_INSECT_REQUEST';
export const UPDATE_INSECT_SUCCESS = 'UPDATE_INSECT_SUCCESS';
export const UPDATE_INSECT_FAIL = 'UPDATE_INSECT_FAIL';
export const UPDATE_INSECT_CLEAR = 'UPDATE_INSECT_CLEAR';

export const prepareInsectUpdate = insect => ({
    type: UPDATE_INSECT_PREP,
    insect
});

export const updateInsectRequest = () => ({
    type: UPDATE_INSECT_REQUEST
});

export const updateInsectSucess = (humanReadableId, updatedInsect) => ({
    type: UPDATE_INSECT_SUCCESS,
    humanReadableId,
    updatedInsect
});

export const updateInsectFail = message => ({
    type: UPDATE_INSECT_FAIL,
    message
});

export const clearInsectPendingUpdate = () => ({
    type: UPDATE_INSECT_CLEAR
});

const shouldClearInsectPendingUpdate = state => {
    const { originalData } = state.insects.insectPendingUpdate;
    // console.log('redux update originalData:', originalData)
    if (originalData) {
        return true;
    } else {
        return false;
    }
}

export const clearInsectPendingUpdateIfNeeded = () => {
    return (dispatch, getState) => {
        // console.log('update insect state:', getState());
        if (shouldClearInsectPendingUpdate(getState())) {
            // console.log('prepping to clear update insect state')
            return dispatch(clearInsectPendingUpdate());
        }
    }
}

export const updateInsect = (humanReadableId, insectFormData) => {
    return dispatch => {
        // console.log('redux requesting insect update');
        dispatch(updateInsectRequest());
        return fetch(`/api/specimens/${humanReadableId}`, {
            headers: {
                'x-csrf-token': getCsrfToken()
            },
            method: 'PUT',
            body: insectFormData
        })
            .then(handleHttpResponse)
            .then(response => dispatch(updateInsectSucess(humanReadableId, response.data)))
            .catch(error => {
                console.error(error);
                return dispatch(updateInsectFail(error.message))
            });
    }
};

export const updateInsectWithPrimaryImageReplacement = (
    humanReadableId,
    currentImageId,
    insectFormData
) => {
    return dispatch => {
        return Promise.resolve()
            .then(() => dispatch(replacePrimaryInsectImage(humanReadableId, currentImageId, insectFormData)))
            .then(() => dispatch(updateInsect(humanReadableId, insectFormData.delete('images'))))
    }
}

/* ============================
 * Delete Insect Actions
 * ============================ */

export const CREATE_DELETE_INSECT_QUEUE = 'CREATE_DELETE_INSECT_QUEUE';
export const EXECUTE_DELETE_INSECT_QUEUE = 'EXECUTE_DELETE_INSECT_QUEUE'; // reducer should return promise that can be checked for status of queue based on state
export const DELETE_INSECT_QUEUE_SUCCESS = 'DELETE_INSECT_QUEUE_SUCESS';
export const DELETE_INSECT_QUEUE_FAIL = 'DELETE_INSECT_QUEUE_FAIL'; // this does not mean that the entire insect queue has failed, it just means that at least one has an error

export const DELETE_INSECT_REQUEST = 'DELETE_INSECT_REQUEST';
export const DELETE_INSECT_SUCCESS = 'DELETE_INSECT_SUCCESS';
export const DELETE_INSECT_FAIL = 'DELETE_INSECT_FAIL';

export const createDeleteQueue = humanReadableIdArray => ({
    type: CREATE_DELETE_INSECT_QUEUE,
    humanReadableIdArray
});

export const deleteQueueSuccess = () => ({
    type: DELETE_INSECT_QUEUE_SUCCESS
})

export const deleteQueueFail = () => ({
    type: DELETE_INSECT_QUEUE_FAIL
});

export const deleteInsectRequest = queueIndex => ({
    type: DELETE_INSECT_REQUEST,
    queueIndex
});

export const deleteInsectSuccess = (queueIndex, humanReadableId) => ({
    type: DELETE_INSECT_SUCCESS,
    queueIndex,
    humanReadableId
});

export const deleteInsectFail = (queueIndex, message) => ({
    type: DELETE_INSECT_FAIL,
    queueIndex,
    message
});

export const deleteInsects = humanReadableIdArray => {
    return dispatch => {
        dispatch(createDeleteQueue(humanReadableIdArray));
        // Require that all promises go through even if errors happen
        // (handling of the errors will take place at a later time)
        return Promise.allSettled(
            humanReadableIdArray.map((humanReadableId, i) => {
                dispatch(deleteInsectRequest(i));
                // return dispatch(deleteInsectFail(i, 'test error'));
                return fetch(`/api/specimens/${humanReadableId}`, {
                    headers: {
                        'x-csrf-token': getCsrfToken()
                    },
                    method: 'DELETE'
                })
                    .then(handleHttpResponse)
                    .then(response => dispatch(deleteInsectSuccess(i, humanReadableId)))
                    .catch(error => {
                        console.error(error);
                        dispatch(deleteInsectFail(i, error.message));
                        throw new Error(error);
                    });
            })
        )
        .then(results => {
            // console.log('delete queue results:', results);
            for (let i = 0; i < results.length; i++) {
                if (results[i].status === 'rejected') {
                    return dispatch(deleteQueueFail())
                }
            }
            return dispatch(deleteQueueSuccess());
            // return dispatch(deleteQueueFail());
        });
    };
};


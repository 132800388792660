/**
 * Splits a URL query string of the form '?key1=value1&key2=value2&...' into an object of the form
 *      { key1: 'value1', key2: 'value2', ... }
 * @param {String} queryString The URL query string to parse
 * @return {Object} The object of the form { key1: 'value1', key2: 'value2', ... } resulted from 
 *      parsing the query string
 */
export default queryString => {
    return queryString
        .replace('?', '')
        .split('&')
        .map(keyValuePair => keyValuePair.split('='))
        .reduce((queryStringObject, keyValuePairArray) => {
            queryStringObject[keyValuePairArray[0]] = keyValuePairArray[1];
            return queryStringObject;
        }, {});
};
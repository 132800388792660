/**
 * Searches for a substring (needle) in a string (haystack),
 *      optionally requiring the needle to be at the beginning of the haystack
 * @param {String} needle The substring to search the haystack for
 * @param {String} haystack The string to search for the needle in
 * @param {Boolean} startsWith The boolean requiring the needle to optionally be at the beginning of the haystack
 */
export default (needle, haystack, startsWith) => {
    if (! needle || ! haystack) {
      return false;
    }

    if (startsWith && haystack.toLowerCase().startsWith(needle.toLowerCase()) >= 0) {
        return true;
    } else if (haystack.toLowerCase().search(needle.toLowerCase()) >= 0) {
      // console.log('checking anywhere in the string');
      return true;
    } else {
      return false;
    }
};
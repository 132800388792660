import React, { useState, useEffect, useRef, useReducer } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import promisifiedJsonp from '../../../../utils/jsonp';

import TaxonSearch from './components/TaxonSearch';
import CurrentIdentification from './components/CurrentIdentification';
import TaxonSelection from './components/TaxonSelection';


const IdentificationTool = ({
  setIdentification: setParentComponentIdentification,
  startingTsn,
  // This is a boolean that will be set out of the gate in order to prevent setting the parent state when unnecessary
  // it forces a behavior similar to the image upload component
  editing
}) => {
  const [ searchData, setSearchData ] = useState({
    searchTerm: '',         // The string to use for searching for a taxon through itis.gov jsonp
    searchTimer: null       // The setTimeout() id for starting and stopping searching
  });
  const [ currentTaxaSubsetData, setCurrentTaxaSubsetData ] = useState({
    taxaUrl: '',            // The base of the url without 'start' and 'rows' parameters, used for getting current and incremented result subsets 
    totalResults: 0,        // The known total results available from a given itis search, equivalent to itis.response.numFound
    currentSubsetStart: 0,  // The index of the first result from itis.gov, equivalent to itis.response.start
    taxa: [],               // The list of taxa from an itis search, equivalent to itis.response.docs
    hierarchySoFar: []      // An ordered array indicating the tree path to the current chosen taxa level, modified from itis.response.docs[i - 1].hierarchySoFarWRanks
  });

  // determines if a component is mounted (must be placed in each useEffect and detected on EACH state update) 
  const isMounted = useRef(null);

  useEffect(() => {
    isMounted.current = true;
    // console.log('starting tsn:', startingTsn)
    setupIdentificationStartingPoint(startingTsn);
    return () => isMounted.current = false;
  }, [startingTsn]);

  useEffect(() => {
    isMounted.current = true;
    if (searchData.searchTerm === '' && searchData.searchTimer) {
      clearTimeout(searchData.searchTimer);
      setupIdentificationStartingPoint(startingTsn);
    }
    return () => {
      clearTimeout(searchData.searchTimer);
      isMounted.current = false;
    }
  }, [searchData.searchTerm])

  /**
   * Parses a hierarchy string from the itis.gov jsonp results into an ordered array of objects
   *    of the form [{ name: 'Animalia', rank: 'kingdom' }, { name: 'Bilateria', rank: 'Subphylum' }, ...]
   * @param {String} hierarchyWithRanksString From itis.gov in the form tsn:$rank1:taxon1$rank2:taxon2$rank3:taxon3$...$
   * @param {String} hierarchyTsnString From itis.gov in the form tsn:$rank1:ta
   * @return {Array} The ordered array of objects indicating the identification tree path
   */
  const convertHierarchyStringToArray = (hierarchyWithRanksString, hierarchyTsnString) => {
    // String example -- tsn:$rank1:taxon1$rank2:taxon2$rank3:taxon3$...$
    let hierarchySoFar = hierarchyWithRanksString
      .slice(hierarchyWithRanksString.indexOf(':') + 1)
      .split('$')
      .slice(1, -1)
      .map(taxonString => {
        let colonLocation = taxonString.indexOf(':');
        return {
          rank: taxonString.slice(0, colonLocation),
          name: taxonString.slice(colonLocation + 1)
        }
      });

    hierarchyTsnString
      .split('$')
      .slice(1, -1)
      .map((tsn, i) => hierarchySoFar[i].tsn = tsn)

    return hierarchySoFar;
  };

  /**
   * Helper function to retrieve taxon from itis.gov based on TSN
   * @param {Number} tsn TSN of the desired taxon
   * @return {Promisified Object} The entire results of an itis.gov query
   */
  const getTaxonByTsn = tsn => 
    promisifiedJsonp('https://services.itis.gov/?q=tsn:' + tsn + '&sort=rank+asc&sort=rank&wt=json')

  /**
   * Helper function to retrieve taxon from itis.gov based on name
   * @param {Number} name Name of the desired taxon
   * @return {Promisified Object} The entire results of an itis.gov query
   */
  const getTaxonByName = name =>
    promisifiedJsonp('https://services.itis.gov/?q=nameWInd:/' + name + '/&sort=rank+asc&sort=rank&wt=json')

  /**
   * Sets up the identification state with either the default hexapoda taxon or a 
   *    custom taxon specified with a TSN
   * @param {Number} tsn Optional TSN to start the identification state with
   */
  const setupIdentificationStartingPoint = tsn => {
    let initialQuery;
    if (tsn) {
      initialQuery = getTaxonByTsn(tsn);
    } else {
      initialQuery = getTaxonByName('hexapoda');
    }

    let hierarchySoFar,
        parentTsn;
    
    // Get starting point based on starting TSN or default starting point of hexapoda
    initialQuery
      
      // store data from results and update parent state
      .then(data => {
        let startingTaxon = data.response.docs[0];
        parentTsn = startingTaxon.tsn;
        hierarchySoFar = convertHierarchyStringToArray(
          startingTaxon.hierarchySoFarWRanks[0],
          startingTaxon.hierarchyTSN[0]
        );
        setParentComponentIdentification({
          tsn: data.response.docs[0].tsn,
          identificationString: data.response.docs[0].hierarchySoFar[0].split(':')[1].slice(1,-1),
          identificationStringWithRanks: data.response.docs[0].hierarchySoFarWRanks[0]
            .slice(data.response.docs[0].hierarchySoFarWRanks[0].indexOf(':') + 1)
            .slice(1,-1)
        });

      })
      .then(() => getFirstSetOfChildren(parentTsn))
      .then(childrenData => {
        if (isMounted.current) {
          setCurrentTaxaSubsetData(prevState => ({
            ...prevState,
            taxaUrl: childrenData.taxaUrl,
            totalResults: childrenData.totalResults,
            currentSubsetStart: childrenData.currentSubsetStart,
            taxa: childrenData.taxa,
            hierarchySoFar
          }));
        }
      });
  }

  /**
   * Gets the first set of children of a specified TSN (note that the 
   *    itis.gov website naturally limits the default query to 10 results)
   * @param {Number} parentTsn The TSN to get all the children of
   * @return {Promisified Object} Object containing metadata about search results and an array of the children
   *    of the form {
   *      taxaUrl: 
   *      totalResults:
   *      currentSubsetStart:
   *      taxa:
   *    }
   */
  const getFirstSetOfChildren = parentTsn => {
    let url = 'https://services.itis.gov/?q=parentTSN:' + parentTsn + '&wt=json';
    return promisifiedJsonp(url)
      .then(data => ({
        taxaUrl: url,
        totalResults: data.response.numFound,
        currentSubsetStart: data.response.start,
        taxa: data.response.docs
      }));
  };

  /**
   * Searches itis.gov for a taxa with either the taxon name or a vernacular name matching a string
   * @param {String} searchTerm The string to use for searching the itis.gov
   * @return {Promisified Object} Object containing metadata about search results and an array of the children
   *    of the form {
   *      taxaUrl: 
   *      totalResults:
   *      currentSubsetStart:
   *      taxa:
   *    }
   */
  const getFirstSetOfSearchResults = searchTerm => {
    let url = 'https://services.itis.gov/?q=nameWInd:/' + searchTerm + '.*/ OR vernacular:/.*' + searchTerm + '.*/&sort=rank+asc&sort=rank&wt=json';
    return promisifiedJsonp(url)
      .then(data => ({
        taxaUrl: url,
        totalResults: data.response.numFound,
        currentSubsetStart: data.response.start,
        taxa: data.response.docs
      }))
  }

  /**
   * Retrieves the next subset of taxa, the amount and direction is determined by the step
   * @param {Number} step The size of the next subset to retrieve along with the direction to move
   *    + is forward
   *    - is backwards
   * @return {Promisified Object} Object containing the bare mininum new from the new results
   *    of the form {
   *      currentSubsetStart:
   *      taxa:
   *    }
   */
  const getNextSubsetofTaxa = step => {
    const {
      currentSubsetStart: start,
      totalResults: max
    } = currentTaxaSubsetData;

    let rowStart = start + step,
        offset = Math.abs(step);
    if (start + step >= max) {
      return;
    } else {
      if (start + step < 0) {
        rowStart = 0;
      }
      if (rowStart + offset > max) {
        offset = max - (start + step);
      }
      return promisifiedJsonp(currentTaxaSubsetData.taxaUrl + '&start=' + rowStart + '&rows=' + offset)
        .then(data => ({
          currentSubsetStart: data.response.start,
          taxa: data.response.docs
        }))
    }
  }

  /**
   * Updates the state of the local and parent component to reflect the taxon that
   *    the user clicked on
   * @param {Number} selectedTsn The TSN of the taxon selected by the user
   * @return {Void}
   */
  const handleTaxonSelection = selectedTsn => e => {
    e.preventDefault();
    let selectedTaxon;
    // Get select taxon data from a full query
    getTaxonByTsn(selectedTsn)
      .then(data => selectedTaxon = data.response.docs[0])

      // then get the children of the selected taxon
      .then(() => getFirstSetOfChildren(selectedTsn))

      // finally set states with the information retrieved
      .then(data => {
        setParentComponentIdentification({
          tsn: selectedTaxon.tsn,
          identificationString: selectedTaxon.hierarchySoFar[0]
            .split(':')[1]
            .slice(1, -1),
          identificationStringWithRanks: selectedTaxon.hierarchySoFarWRanks[0]
            .slice(selectedTaxon.hierarchySoFarWRanks[0].indexOf(':') + 1)
            .slice(1, -1)
        });

        if (isMounted.current) {
          setCurrentTaxaSubsetData(prevState => ({
            ...prevState,
            taxaUrl: data.taxaUrl,
            totalResults: data.totalResults,
            currentSubsetStart: data.currentSubsetStart,
            taxa: data.taxa,
            hierarchySoFar: convertHierarchyStringToArray(
              selectedTaxon.hierarchySoFarWRanks[0],
              selectedTaxon.hierarchyTSN[0]
            )
          }));
        }
      });
  };

  /**
   * Handles users clicking a 'next' or 'previous' button in order to navigate taxa subsets by setting 
   *    appropriate state variables
   * @param {Number} step The amount and direction to move in order to get the next taxa subset from itis.gov
   * @return {Void}
   */
  const handleTaxaSubsetNavigation = step => () => {
    return getNextSubsetofTaxa(step)
      .then(data => {
        if (isMounted.current) {
          setCurrentTaxaSubsetData(prevState => ({
            ...prevState,
            currentSubsetStart: data.currentSubsetStart,
            taxa: data.taxa
          }));
        }
      });
  };

  /**
   * Handles the user changing the search term by setting state and adding a timeout
   * @param {Object} e The event object of the taxon search term value
   */
  const handleSearchTermChange = e => {
    e.persist();
    if (searchData.searchTimer) {
      clearTimeout(searchData.searchTimer);
    }
    setSearchData(prevState => ({
      ...prevState,
      searchTerm: e.target.value,
      searchTimer: setTimeout(
        () => getFirstSetOfSearchResults(e.target.value)
          .then(data => setCurrentTaxaSubsetData(prevState => ({
            ...prevState,
            taxaUrl: data.taxaUrl,
            totalResults: data.totalResults,
            currentSubsetStart: data.currentSubsetStart,
            taxa: data.taxa,
            hierarchySoFar: []
          }))),
      500)
    }));
  }

  // console.log('identification tool state:', { ...searchData, ...currentTaxaSubsetData });
  return (
    <div>
      <CurrentIdentification
        identificationPath={currentTaxaSubsetData.hierarchySoFar}
        onSelectTaxon={handleTaxonSelection}
      />
      <TaxonSearch
        searchTerm={searchData.searchTerm}
        onChange={handleSearchTermChange}
      />
      {currentTaxaSubsetData.taxa.length > 0 && (
        <TaxonSelection
          totalTaxa={currentTaxaSubsetData.totalResults}
          startingTaxonNumber={currentTaxaSubsetData.currentSubsetStart}
          taxa={currentTaxaSubsetData.taxa}
          onSelectTaxon={handleTaxonSelection}
          incrementResults={handleTaxaSubsetNavigation}
        />
      )}
    </div>
  );
};

IdentificationTool.propTypes = {
  setIdentification: PropTypes.func.isRequired,
  startingTsn: PropTypes.number,
};

export default IdentificationTool;
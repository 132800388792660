const incrementAlpha = alpha => {
    let insensitiveAlpha = alpha.toUpperCase(),
        newAlpha,
        wrapAround = false;
    if (insensitiveAlpha === 'Z') {
        wrapAround = true;
        newAlpha = 'A';
    } else {
        wrapAround = false;
        newAlpha = String.fromCharCode(insensitiveAlpha.charCodeAt(0) + 1);
    }
    return [newAlpha, wrapAround];
}

const incrementNumeric = stringInt => {
    let integer = parseInt(stringInt),
        wrapAround = false;
    if (integer === 9) {
        integer = 0;
        wrapAround = true;
    } else {
        integer += 1;
        wrapAround = false;
    }

    return [integer.toString(), wrapAround];
}

/**
 * Increments an insect ID of any form containing only letters and numbers to the next one
 * @param {String} id Human-readable insect ID with only letters and numbers
 */
const incrementId = id => {
    let idString = id.toString(),
        newId = '',
        keepIncrementing = true,
        stringIdPosition;
    for (stringIdPosition = idString.length - 1; stringIdPosition >= 0 && keepIncrementing; stringIdPosition--) {
        if (/[A-Za-z]/.test(idString.charAt(stringIdPosition))) {
            let [ newChar, wrapAround ] = incrementAlpha(idString.charAt(stringIdPosition));
            keepIncrementing = wrapAround;
            newId = newChar + newId;
        } else if (/[0-9]/.test(idString.charAt(stringIdPosition))) {
            let [ newInt, wrapAround ] = incrementNumeric(idString.charAt(stringIdPosition));
            keepIncrementing = wrapAround;
            newId = newInt + newId;
        }
    }
    return idString.slice(0, stringIdPosition + 1).toUpperCase() + newId;
}

// export default incrementId;
module.exports = incrementId;
import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'reactstrap';

// css
import './index.css';

// custom components
import StandardFormInput from '../../../../components/StandardFormInput';
import BasicModal from '../../../../components/BasicModal';
import BasicModalHeader from '../../../../components/BasicModalHeader';
import BasicModalBody from '../../../../components/BasicModalBody';
import BasicModalFooter from '../../../../components/BasicModalFooter';

const SearchModal = ({
  modalOpen,
  toggleModal,
  insectSearchState,
  onSearchFieldChange: handleSearchFieldChange,
  onSearchFormSubmit: handleSearchFormSubmit,
  className
}) => {
  return (
    <BasicModal isOpen={modalOpen} toggle={toggleModal} className={`search-modal ${className}`}>
      <BasicModalHeader toggle={toggleModal}>
        <h1 className="mb-0">Search</h1>
      </BasicModalHeader>
      <BasicModalBody>
        <form>
          <StandardFormInput
            label="Human-Readable ID"
            id="humanReadableId"
            type="text"
            value={insectSearchState.humanReadableId}
            onChange={handleSearchFieldChange}
            autoComplete="off"
          />
          <StandardFormInput
            label="Classification"
            id="identification"
            type="text"
            value={insectSearchState.identification}
            onChange={handleSearchFieldChange}
            autoComplete="off"
          />
          <StandardFormInput
            label="Location"
            id="location"
            type="text"
            value={insectSearchState.location}
            onChange={handleSearchFieldChange}
            autoComplete="off"
          />
          <div className="row">
            <div className="col-md-6">
              <StandardFormInput
                label="Start Date"
                id="startDate"
                type="date"
                value={insectSearchState.startDate}
                onChange={handleSearchFieldChange}
                autoComplete="off"
              />
            </div>
            <div className="col-md-6">
              <StandardFormInput
                label="End Date"
                id="endDate"
                type="date"
                value={insectSearchState.endDate}
                onChange={handleSearchFieldChange}
                autoComplete="off"
              />
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-lg btn-primary my-1.5 w-100"
            onClick={e => {
              toggleModal(e);
              handleSearchFormSubmit(e);
            }}
          >
            Submit
          </button>
        </form>
      </BasicModalBody>
      <BasicModalFooter />
    </BasicModal>
  );
};

SearchModal.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  className: PropTypes.string
}

export default SearchModal;
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

// images
import TimKennellJrImg from './images/Tim Kennell Jr.-compressed.jpg';

const DeveloperSection = () => {
  return (
    <section className="developer-section bg-primary text-white py-4.5">
      <div className="container">
        <h1 className="mb-1.5 mb-md-3">About the Developer</h1>
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3 text-center text-md-left mb-1.5 mb-md-0">
            <img src={TimKennellJrImg} alt="Developer Picture" className="img-fluid developer-img"/> 
          </div>
          <div className="col-12 col-md-8">
            <h2>Tim Kennell Jr.</h2>
            <p>Rather than take the road less traveled, I took all of them.  I am a medical student training to help those in need, a scientist analyzing mounds of data, a developer (see title of section), and an insect enthusiast exploring one aspect of God's wonderful creation.  If someone else finds themselves collecting critters, my hope is that this application may help them in some small way.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DeveloperSection;
import React from 'react';
import PropTypes from 'prop-types';
import Navigation from '../Navigation';
import Footer from '../Footer';

import './index.css';

const DefaultPageLayout = ({ children }) => (
  <div className="page-layout">
    <Navigation />
    <div className="content">
      {children}
    </div>
    <Footer />
  </div>
);

export default DefaultPageLayout;
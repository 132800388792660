import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

const CollectionSelectAll = ({
  checked,
  onSelect: handleSelect,
  collectionSize
}) => {
  return (
    <div className="collection-select-all-section d-flex w-100 align-items-center mb-1.5">
      <div className="collection-total text-white ml-1.5">
        <strong>Total</strong>: {collectionSize}
      </div>
      <div className="form-group custom-control custom-checkbox text-white ml-auto mr-1.5 my-0.75">
        <input
          type="checkbox"
          className="custom-control-input"
          id="select-all"
          value={checked}
          onChange={handleSelect}
        />
        <label className="custom-control-label" htmlFor="select-all">Select All</label>
      </div>
    </div>
  );
};

CollectionSelectAll.propTypes = {
  checked: PropTypes.bool,
  onSelect: PropTypes.func,
  collectionSize: PropTypes.number
};

export default CollectionSelectAll;
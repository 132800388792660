import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AboutHeroSection from './components/HeroSection';
import HowToSection from './components/HowToSection';
import ApiSection from './components/ApiSection';
import AttributionSection from './components/AttributionSection';
import DeveloperSection from './components/DeveloperSection';

export default () => {
  return (
    <div>
      <AboutHeroSection />
      <HowToSection />
      <ApiSection />
      <AttributionSection />
      <DeveloperSection />
    </div>
  )
}
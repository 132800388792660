import { combineReducers } from "redux";
import {
    ADD_INSECT_IMAGE_REQUEST,
    ADD_INSECT_IMAGE_SUCCESS,
    ADD_INSECT_IMAGE_FAIL,
    DELETE_INSECT_IMAGE_REQUEST,
    DELETE_INSECT_IMAGE_SUCCESS,
    DELETE_INSECT_IMAGE_FAIL
} from './actions'

const manageInsectImageAddition = (
    state = {
        humanReadableId: null,
        isPending: false,
        errorMessage: null
    },
    action
) => {
    switch (action.type) {
        case ADD_INSECT_IMAGE_REQUEST:
            return Object.assign({}, state, {
                humandReadableId: action.humandReadableId,
                isPending: true,
                errorMessage: null
            })
        case ADD_INSECT_IMAGE_SUCCESS:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: null
            })
        case ADD_INSECT_IMAGE_FAIL:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: action.message
            });
        default:
            return state;
    
    }
};

const manageInsectImageDeletion = (
    state = {
        humanReadableId: null,
        isPending: false,
        errorMessage: null
    },
    action
) => {
    switch (action.type) {
        case DELETE_INSECT_IMAGE_REQUEST:
            return Object.assign({}, state, {
                humanReadableId: action.humanReadableId,
                isPending: true,
                errorMessage: null
            })
        case DELETE_INSECT_IMAGE_SUCCESS:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: null
            })
        case DELETE_INSECT_IMAGE_FAIL:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: action.message
            })
        default:
            return state;
    }
}

export default combineReducers({
    addStatus: manageInsectImageAddition,
    deleteStatus: manageInsectImageDeletion
});
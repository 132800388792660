/* ============================
 * import 
 * ============================ */
import jwt from 'jsonwebtoken';
import config from '../../config';
import { handleHttpResponse } from '../../utils/handleFetchHttpErrors';
import getCsrfToken from '../../utils/getCsrfToken';

/* ============================
 * Login Actions
 * ============================ */
export const REQUEST_LOGIN = 'REQUEST_LOGIN';

/**
 * Creates a login request action initiating the login process
 * @param {Object} credentials Login credentials { user: 'user_name', password: '****' }
 */
export const requestLogin = credentials => ({
    type: REQUEST_LOGIN,
    credentials
});

export const SUCCESSFUL_LOGIN = 'SUCCESSFUL_LOGIN';

/**
 * Emits a successful login action
 * @param {Object} token Contains user information to be used after successful login
 */
export const successfullyLogin = userData => ({
    type: SUCCESSFUL_LOGIN,
    userData
});

export const FAIL_LOGIN = 'FAIL_LOGIN';

/**
 * Emits a failed login action
 * @param {String} message The error message when failing to login
 */
export const failLogin = message => ({
    type: FAIL_LOGIN,
    message
});

/**
 * Action builder that cascades all actions for successful or unsuccessful login
 * @param {Object} credentials User information for login { user: string, password: string, remember: boolean }
 */
export const login = credentials => {
    return dispatch => {
        dispatch(requestLogin(credentials));
        return fetch('/api/auth', {
            headers: {
                'content-type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify({
                email: credentials.email,
                password: credentials.password,
                rememberMe: credentials.remember
            })
        })
            .then(handleHttpResponse)
            .then(response => dispatch(successfullyLogin(response.data)))
            .catch(error => {
                console.error(error);
                return dispatch(failLogin(error.message));
            });
    }
};

export const verifyLogin = () => {
    return dispatch => {
        // console.log('VERIFYING LOGIN')
        dispatch(requestLogin());
        return fetch('/api/auth', {
            headers: {
                'x-csrf-token': getCsrfToken()
            }
        })
            .then(handleHttpResponse)
            .then(response => dispatch(successfullyLogin(response.data)))
            .catch(error => {
                console.error(error);
                return dispatch(failLogin())
            });
    }
}

const shouldVerifyLogin = state => {
    const { user, requestPending, loginStatusChecked, token } = state.userLoginState;
    let tokenExpirationTime;
    try {
        tokenExpirationTime = jwt.verify(token, config.jwt.publicKey).exp * 1000;
    } catch (error) {
        tokenExpirationTime = Number.NEGATIVE_INFINITY;
    }
    const tokenExpired = (new Date()).getTime() > tokenExpirationTime;

    // console.log('user information:', user)
    // console.log('redux token expiration:', tokenExpired)
    // if a request for login in pending, let it finish before trying again
    if (requestPending) {
        return false;
    } else if (! user && ! loginStatusChecked) {
        return true;
    } else {
        return false;
    }
}

export const verifyLoginIfNeeded = () => {
    return (dispatch, getState) => {
        // console.log('login state:', getState())
        if (shouldVerifyLogin(getState())) {
            // console.log('prepping to verify login')
            return dispatch(verifyLogin());
        }
    }
}


/* ============================
 * Logout Actions
 * ============================ */
export const REQUEST_LOGOUT = 'REQUEST_LOGOUT';
export const SUCCESSFUL_LOGOUT = 'SUCCESSFUL_LOGOUT';
export const FAIL_LOGOUT = 'FAIL_LOGOUT';

export const failLogout = message => ({
    type: FAIL_LOGOUT,
    message
});

export const logout = () => {
    return dispatch => {
        // console.log('attempting logout!!!')
        dispatch({type: REQUEST_LOGOUT});
        return fetch('/api/auth', {
            headers: {
                'content-type': 'application/json',
                'x-csrf-token': getCsrfToken()
            },
            method: 'DELETE'
        })
        .then(handleHttpResponse)
        .then(response => dispatch({ type: SUCCESSFUL_LOGOUT }))
        .catch(error => {
            console.error(error);
            // return dispatch(failLogout(error.message))
            return dispatch({ type: SUCCESSFUL_LOGOUT }); // logout should always occur when requested
        });
    };
};

const shouldLogout = state => {
    const { requestPending } = state.userLoginState;
    if (requestPending) {
        return false;
    } else {
        return true;
    }
}

export const logoutIfNeeded = () => (dispatch, getState) => {
    if (shouldLogout(getState())) {
        return dispatch(logout());
    }
}
/**
 * Checks the file byte string for its file signature
 * Idea from: https://stackoverflow.com/questions/18299806/how-to-check-file-mime-type-with-javascript-before-upload
 * @param {Object} file A file object from the DOM
 * @return {Promise} An object containing the file type and the file object from the DOM
 */
export const fileSignatureIsImg = file => {
  return new Promise((resolve, reject) => {
    let reader = new FileReader();
    // load event emitted when readAsArrayBuffer done
    reader.addEventListener('load', () => {
      let byteArray = (new Uint8Array(reader.result)),
          byteString = '';

      // convert array of file bytes to byte string for analyzing signature
      for (let i = 0; i < byteArray.length; i++) {
        byteString += byteArray[i].toString(16);
      }

      // check for a byte signature matching a known image type
      switch (byteString) {
        case '89504e47': // image/png
          resolve({type: 'png', file: file});
          break;
        case '47494638': // image/gif
          resolve({type: 'gif', file: file});
          break;
        case 'ffd8ffe0': // image/jpeg
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
        case 'ffd8ffee':
        case 'ffd8ffdb':
          resolve({type: 'jpg', file: file});
          break;
        default:
          reject('Only PNG, JPG, and GIF images files are allowed!');
          break;
      }
    })

    // slices the first 4 bytes off the file
    if (file) {
      reader.readAsArrayBuffer(file.slice(0, 4));
    }
  });
}

/**
 * Pretty much copied verbatum from https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
 * Pulls the exif orientation from read image file:  see https://beradrian.wordpress.com/2008/11/14/rotate-exif-images/
 * @param {Object} file The file object from the DOM
 * @return {Promise} resolve with the orientation number
 */
export const getImgExifRotation = file => {
  return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function(e) {

          var view = new DataView(e.target.result);
          if (view.getUint16(0, false) != 0xFFD8) {
              reject(-2);
          }
          var length = view.byteLength, offset = 2;
          while (offset < length) {
              if (view.getUint16(offset+2, false) <= 8) resolve(-1);
              var marker = view.getUint16(offset, false);
              offset += 2;
              if (marker == 0xFFE1) {
                  if (view.getUint32(offset += 2, false) != 0x45786966) {
                      resolve(-1);
                  }

                  var little = view.getUint16(offset += 6, false) == 0x4949;
                  offset += view.getUint32(offset + 4, little);
                  var tags = view.getUint16(offset, little);
                  offset += 2;
                  for (var i = 0; i < tags; i++) {
                      if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                          let rotation = view.getUint16(offset + (i * 12) + 8, little);
                          // console.log(rotation);
                          resolve(rotation);
                      }
                  }
              } else if ((marker & 0xFF00) != 0xFF00) {
                  break;
              } else { 
                  offset += view.getUint16(offset, false);
              }
          }
          resolve(-1);
      };
      reader.readAsArrayBuffer(file);
  });
}

/**
 * Converts the exif to degrees based on: https://beradrian.wordpress.com/2008/11/14/rotate-exif-images/
 * @param {Number} exifRotation The exif number indicating orientation
 * @return {Number} The CURRENT rotation of the image (no correction added)
 */
export const convertExifRotationToDeg = exifRotation => {
  switch (exifRotation) {
    case 3:
      return Promise.resolve(180);
    case 6:
      return Promise.resolve(-90);
    case 8:
      return Promise.resolve(90);
    case -1: 
      return Promise.resolve(0);
    // can't convert something that isn't one of the above
    // likelihood is that the system has been handed something that isn't a number
    default:
      return Promise.resolve(NaN);
  }
};

/**
 * Resolves with the images rotation, specifically handles jpg vs. gif vs. png
 * @param {Object} imgObject {type: 'png'|'gif'|'jpg', file: fileObject}
 */
export const getImageRotation = imgObject => {
  switch (imgObject.type) {
    case 'png':
    case 'gif':
      return Promise.resolve(0);
    case 'jpg':
      return getImgExifRotation(imgObject.file)
        .then(convertExifRotationToDeg);
  }
}
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

/**
 * Creates the header for the basic modal
 * @param {Function} toggle Toggles the modal on and off, should be the same function as the one passed to the parent
 */
const BasicModalHeader = ({
  toggle,
  className,
  children
}) => {
  return (
    <div className={`basic-modal-header modal-header bg-quinary pt-2 px-2 pb-0 ${className}`}>
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={toggle}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      {children}
    </div>
  );
};

BasicModalHeader.propTypes = {
  toggle: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default BasicModalHeader;
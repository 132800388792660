import { combineReducers } from 'redux';
import {
    ADD_INSECT_REQUEST,
    ADD_INSECT_SUCCESS,
    ADD_INSECT_FAIL,
    RESET_ADD_INSECT,
    GET_COLLECTION_REQUEST,
    GET_COLLECTION_SUCCESS,
    GET_COLLECTION_FAIL,
    SELECT_INSECT_GROUP,
    UNSELECT_INSECT_GROUP,
    TOGGLE_SELECTED_INSECT,
    CREATE_DELETE_INSECT_QUEUE,
    DELETE_INSECT_QUEUE_SUCCESS,
    DELETE_INSECT_QUEUE_FAIL,
    DELETE_INSECT_REQUEST,
    DELETE_INSECT_SUCCESS,
    DELETE_INSECT_FAIL,
    UPDATE_INSECT_REQUEST,
    UPDATE_INSECT_SUCCESS,
    UPDATE_INSECT_FAIL,
    UPDATE_INSECT_PREP,
    UPDATE_INSECT_CLEAR
} from './actions';

import {
    ADD_INSECT_IMAGE_SUCCESS,
    DELETE_INSECT_IMAGE_SUCCESS
} from '../insect_images/actions';

import manageInsectImages from '../insect_images/reducers';

/**
 * Updates the information for a new insect
 * @param {Object} state The current state of the new insect
 * @param {Object} action The information for updating the new insect
 */
const manageNewInsect = (state = {
    isPending: false,
    errorMessage: null
}, action) => {
    switch (action.type) {
        case ADD_INSECT_REQUEST:
            return Object.assign({}, state, {
                isPending: true,
                errorMessage: null
            });
        case ADD_INSECT_SUCCESS:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: null
            });
        case ADD_INSECT_FAIL:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: action.message
            });
        case RESET_ADD_INSECT:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: null
            });
        default:
            return state;
    }
};

/**
 * Updates the state of the collection
 * @param {Object} state The current state of the collection
 * @param {Object} action Contains the information for changing the state
 */
const manageCollection = (state = {}, action) => {
    switch (action.type) {
        case ADD_INSECT_SUCCESS:
            // console.log('action insect:', action.insect[0])
            let {humanReadableId} = action.insect[0];

            return Object.assign({}, state, {
                [humanReadableId]: { ...action.insect[0] }
            });
        case GET_COLLECTION_SUCCESS:
            let objectifiedCollection = {};
            action.collection.map(insect => {
                objectifiedCollection[insect.humanReadableId] = insect;
            });
            return Object.assign({}, state, objectifiedCollection);
        case UPDATE_INSECT_SUCCESS:
            return Object.assign({}, state, {
                [action.humanReadableId]: { ...action.updatedInsect[0] }
            })
        case DELETE_INSECT_SUCCESS:
            let { humanReadableId: humanReadableIdToRemove } = action;
            const { [humanReadableIdToRemove]: value, ...newState } = state;
            return newState;
        case ADD_INSECT_IMAGE_SUCCESS:
            // console.log('collection on image addition of ' + action.humanReadableId, state[action.humanReadableId])
            return Object.assign({}, state, {
                [action.humanReadableId]: {
                    ...state[action.humanReadableId],
                    images: [
                        ...state[action.humanReadableId].images,
                        ...action.insectImage
                    ]
                }
            });
        case DELETE_INSECT_IMAGE_SUCCESS:
            return Object.assign({}, state, {
                [action.humanReadableId]: {
                    ...state[action.humanReadableId],
                    images: state[action.humanReadableId].images.filter(image => image.imageId !== action.imageId)
                }
            });
        default:
            return state;
    }
};

/**
 * Manages the status of a collection request
 * @param {Object} state The current state of the collection request
 * @param {Object} action Contains the information for changing the state
 */
const manageCollectionRequest = (state = {
    isPending: false,
    errorMessage: null,
    fullCollectionAcquired: false
}, action) => {
    switch (action.type) {
        case GET_COLLECTION_REQUEST:
            return Object.assign({}, state, {
                isPending: true
            });
        case GET_COLLECTION_SUCCESS:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: null,
                fullCollectionAcquired: true
            });
        case GET_COLLECTION_FAIL:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: action.message
            });
        default:
            return state;
    }
};

const manageSelectedInsects = (state = {}, action) => {
    const { humanReadableId } = action;
    switch (action.type) {
        case TOGGLE_SELECTED_INSECT:
            // console.log('toggle individual insect');
            return Object.assign({}, state, {
                [humanReadableId]: ! state[humanReadableId]
            });
        case SELECT_INSECT_GROUP:
            return Object.assign(
                {},
                state, 
                Object.keys(action.humanReadableIdVisibleObject)
                    .reduce((selectedGroup, humanReadableId) => {
                        if (action.humanReadableIdVisibleObject[humanReadableId]) {
                            selectedGroup[humanReadableId] = true;
                        }
                        return selectedGroup;
                    }, {})
            );
        case UNSELECT_INSECT_GROUP:
            return Object.assign(
                {},
                state, 
                Object.keys(action.humanReadableIdVisibleObject)
                    .reduce((unselectedGroup, humanReadableId) => {
                        if (action.humanReadableIdVisibleObject[humanReadableId]) {
                            unselectedGroup[humanReadableId] = false;
                        }
                        return unselectedGroup;
                    }, {})
            );
        case DELETE_INSECT_SUCCESS:
            return Object.assign({}, state, {
                [action.humanReadableId]: false
            })
        default:
            return state;
    }
};


// TODO:  create parent deletion state and split into queue and queue status for separate management
const manageDeleteQueue = (
    state = {
        isPending: false,
        errorMessage: null,
        queue: []
    },
    action
) => {
    switch (action.type) {
        case CREATE_DELETE_INSECT_QUEUE:
            let queue = action.humanReadableIdArray.map(humanReadableId => ({
                id: humanReadableId,
                isPending: false,
                error: null
            }));
            return Object.assign({}, state, {
                queue,  // expecting this to overwrite any previous queue, but this might need some testing
                isPending: true
            });
        case DELETE_INSECT_QUEUE_SUCCESS:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: null
            });
        case DELETE_INSECT_QUEUE_FAIL:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: 'At least one of the specimens could not be deleted'
            });
        case DELETE_INSECT_REQUEST:
            return Object.assign({}, state, { queue: state.queue.map((specimen, i) => (
                action.queueIndex === i
                    ? { ...specimen, isPending: true }
                    : specimen
            ))});
        case DELETE_INSECT_SUCCESS:
            return Object.assign({}, state, { queue: state.queue.map((specimen, i) => (
                action.queueIndex === i
                    ? { ...specimen, isPending: false }
                    : specimen
            ))});
        case DELETE_INSECT_FAIL:
            return Object.assign({}, state, { queue: state.queue.map((specimen, i) => (
                action.queueIndex === i
                    ? { ...specimen, isPending: false, message: action.message }
                    : specimen
            )) });
        default:
            return state;
    }
}

const manageInsectPendingUpdate = (
    state = {
        id: '',
        isPending: false,
        errorMessage: null,
        originalData: null
    },
    action
) => {
    switch (action.type) {
        case UPDATE_INSECT_PREP:
            return Object.assign({}, state, {
                id: action.insect.humanReadableId,
                isPending: false,
                errorMessage: null,
                originalData: action.insect
            })
        case UPDATE_INSECT_REQUEST:
            return Object.assign({}, state, {
                isPending: true,
                errorMessage: null,
            });
        case UPDATE_INSECT_SUCCESS:
            // console.log('redux reducer updated insect:', action.updatedInsect[0])
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: null,
                originalData: action.updatedInsect[0]
            });
        case UPDATE_INSECT_FAIL:
            return Object.assign({}, state, {
                isPending: false,
                errorMessage: action.message
            });
        case UPDATE_INSECT_CLEAR:
            return Object.assign({}, state, {
                id: '',
                isPending: false,
                errorMessage: null,
                originalData: null
            });
        case DELETE_INSECT_IMAGE_SUCCESS:
            if (state.id === action.humanReadableId) {
                return Object.assign({}, state, {
                    originalData: Object.assign({}, state.originalData, {
                        images: state.originalData.images.filter(image => image.imageId !== action.imageId)
                    })
                });
            }
            return state;
        case ADD_INSECT_IMAGE_SUCCESS:
            // console.log('redux update original data image: state.id:', state.id);
            // console.log('redux update original data image: action.humanReadableId:', action.humanReadableId);

            if (state.id === action.humanReadableId) {
                return Object.assign({}, state, {
                    originalData: Object.assign({}, state.originalData, {
                        images: [
                            ...state.originalData.images,
                            ...action.insectImage
                        ]
                    })
                })
            }
            return state;
        default:
            return state;
    }
};

export default combineReducers({
    newInsect: manageNewInsect,
    collection: manageCollection,
    collectionRequest: manageCollectionRequest,
    selectedInsects: manageSelectedInsects,
    deleteQueue: manageDeleteQueue,
    insectPendingUpdate: manageInsectPendingUpdate,
    insectImages: manageInsectImages
});
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { NavLink as RRNavLink } from 'react-router-dom';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem } from 'reactstrap';

import { connect } from 'react-redux';
import { login, logout } from '../../state/login/actions';

// images
import logoWhiteWithWords from './images/net v1.2 (white logo and words).svg';

// CSS
import './index.css';

/**
 * Main Navbar for the system
 */
class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false
    };
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  collapseNavbar = collapse => {
    this.setState({
      isOpen: !collapse
    })
  }
  render() {
    const { userLoggedIn, login, logout } = this.props;

    const About = (
      <NavItem>
        <NavLink className="px-md-1 in-navbar-link" tag={RRNavLink} exact to="/about" onClick={() => this.collapseNavbar(true)}>About</NavLink>
      </NavItem>
    );

    return (
      <div className="d-print-none">
        <Navbar className="navbar-custom py-0.75 py-md-1.25" expand="md" fixed="top">
          <div className="container">
            <NavbarBrand tag={RRNavLink} to={userLoggedIn ? "/collection" : "/"}>
              <img src={logoWhiteWithWords} alt="logo with words" />
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />

              {/* render navigation bar for logged in users */}
              {userLoggedIn && (
                <Collapse isOpen={this.state.isOpen} navbar>
                  <Nav className="ml-auto" navbar>
                    <NavItem>
                      <NavLink className="px-md-1 in-navbar-link" tag={RRNavLink} to="/collection" onClick={() => this.collapseNavbar(true)}>Collection</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink className="px-md-1 in-navbar-link" tag={RRNavLink} to="/entry" onClick={() => this.collapseNavbar(true)}>Insect Entry</NavLink>
                    </NavItem>
                    {About}
                    <UncontrolledDropdown nav inNavbar className="px-md-1 in-navbar-link">
                      <DropdownToggle nav caret>
                        Profile
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={() => this.collapseNavbar(true)}>
                          <NavLink className="p-0" tag={RRNavLink} to="/user-settings">Settings</NavLink>
                        </DropdownItem>
                        <DropdownItem onClick={e => {e.preventDefault(); this.collapseNavbar(true); logout()}}>
                          Logout
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </Nav>
                </Collapse>
              
              // render navigation for users that need to login
              ) || (
                <Collapse isOpen={this.state.isOpen} navbar>
                  {/* <Nav className="mr-auto" navbar>
                    {About}
                  </Nav> */}
                  <Nav className="ml-auto" navbar>
                    {About}
                    <NavItem>
                      <NavLink
                        tag={RRNavLink}
                        to="/login"
                        onClick={() => this.collapseNavbar(true)}
                        className="px-md-1 in-navbar-link"
                      >
                        Login
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        tag={RRNavLink}
                        to="/sign_up"
                        onClick={() => this.collapseNavbar(true)}
                      >
                        Sign Up
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </Collapse>
              )}
            </div>
        </Navbar>
      </div>
    );
  }
}

Navigation.propTypes = {
  userLoggedIn: PropTypes.bool.isRequired,
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired
}

const mapStateToProps = ({userLoginState: {userLoggedIn}}) => ({
  userLoggedIn: userLoggedIn
});

const mapDispatchToProps = dispatch => ({
  login: () => dispatch(login({user: 'dummy'})),
  logout: () => dispatch(logout())
});

// quick trick to push the properties and the and actions into the navigation bar
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Navigation);
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

/**
 * Intended to be used with <SideNav /> and <SideNavPageContent /> and <SideNavItem />
 * e.g.
 * <SideNavContainer>
 *   <SideNav>
 *     <SideNavItem></SideNavItem>
 *   </SideNav>
 *   <SideNavPageContent></SideNavPageContent>
 * </SideNavContainer>
 */
const SideNavContainer = ({ children }) => {
  return (
    <div className="side-nav-container bg-quinary">
      <div className="side-nav-background bg-primary d-none d-lg-block"></div>

      <div className="container">
        <div className="row align-items-stretch" style={{ minHeight: '100%' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default SideNavContainer;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import './index.css';
import { useLocation } from 'react-router';
import { getCollectionIfNeeded } from '../../state/insects/actions';

import isInsectSelected from '../../utils/isInsectSelected';
import StandardLabelTable from './components/StandardLabelTable';
import EnvelopeLabelTable from './components/EnvelopeLabelTable';
import PrintingHelpModal from './components/PrintingHelpModal';

const Labels = ({
  getCollectionIfNeeded,
  collection,
  selectedInsects
}) => {
  const [ printingHelpModalIsOpen, setPrintingHelpModalIsOpen ] = useState(false);

  const location = useLocation();
  
  useEffect(() => {getCollectionIfNeeded()}, []);

  /**
   * Adds a <style> element to the html <head> based on the labelType passed into the page
   * @effect Addition and removal of <style> element from <head>
   */
  useEffect(() => {
    let pageSize,
        pageMargins;
    if (location.state && location.state.labelType === 'envelope') {
      pageSize = '5in 3in';
      pageMargins = '0.5in !important';
    } else {
      pageSize = '11in 8.5in';
      pageMargins = '0.35in !important';
    }
    let style = document.createElement('style');
    style.type = 'text/css';
    style.innerHTML = 
      `@page {
        size: ${pageSize};
        margin: ${pageMargins};
        padding: 0;
      }`;
    document.head.appendChild(style);
    return () => document.head.removeChild(style);
  }, [location]);

  // console.log('collection:', collection)
  // console.log('selected insects:', selectedInsects)

  let humanReadableIdArray = [];
  if (!isInsectSelected(selectedInsects)) {
    humanReadableIdArray = Object.keys(collection);
  } else {
    humanReadableIdArray = Object.keys(selectedInsects)
      .filter(humanReadableId => selectedInsects[humanReadableId]);
  }

  {/* Napkin Math:
    8.5" x 11" piece of paper
    0.5" margins each side
    0.25" x 0.6" cells
    # columns = (11 - 2 * 0.5) / 0.6 = 16.666... ~ 16
    # rows = (8.5 - 2 * 0.5) / 0.26 = 28.846... ~ 28  */}
  let rowsPerTable = 26,
      columnsPerTable = 15,
      cellsPerTable = rowsPerTable * columnsPerTable,
      cellsPerInsect,
      cellType = location.state && location.state.labelType
        ? location.state.labelType
        : null;
    if (! location.state || location.state.labelType === 'both') {
      cellsPerInsect = 2;
    } else {
      cellsPerInsect = 1;
    }

  let insectSets;
  if (location.state && location.state.labelType === 'envelope') {
    insectSets = humanReadableIdArray.map(humanReadableId => collection[humanReadableId]);

  } else {
    insectSets = humanReadableIdArray.reduce((insectSets, currentHumanReadableId, i) => {
      let insectSetNumber = Math.floor(cellsPerInsect * i / cellsPerTable);
      insectSets[insectSetNumber] = insectSets[insectSetNumber] || [];
      insectSets[insectSetNumber].push(collection[currentHumanReadableId]);
      return insectSets;
    }, [])
  }

  return (
    <section className="label-section">
      <PrintingHelpModal
        isOpen={printingHelpModalIsOpen}
        toggle={() => setPrintingHelpModalIsOpen(! printingHelpModalIsOpen)}
      />
      <div className="container pt-7 pt-md-9 printer-remove-padding">
        <div className="row d-print-none">
          <div className="col-sm-3">
            <h1>Labels</h1>
          </div>
          <div className="col-sm-9 text-sm-right mb-1 mb-sm-0 d-flex d-sm-block justify-content-between">
            <button
              className="btn btn-primary mr-2"
              onClick={() => window.print()}
            >
              <i className="fas fa-print"></i> Print Labels
            </button>
            <button
              className="btn btn-secondary"
              onClick={() => setPrintingHelpModalIsOpen(true)}
            >
              <i className="fas fa-print"></i> <i className="fas fa-question"></i> Printing Help
            </button>
          </div>
        </div>
        {location.state && location.state.labelType === 'envelope' && (
          // <div className="row printer-flex-remove">
          <div className="envelope-row row justify-content-around justify-content-xl-between printer-flex-remove">
            {insectSets.map((insect, i) =>
              <div
                key={i}
                className="p-0.75 envelope-label-table-container"
              >
                <EnvelopeLabelTable
                  key={i}
                  insect={insect}
                />
              </div>
            )}
          </div>
        ) || (
          insectSets.map((insectSet, i) =>
            <StandardLabelTable
              key={i}
              insectSet={insectSet}
              rowsPerTable={rowsPerTable}
              columnsPerTable={columnsPerTable}
              cellsPerInsect={cellsPerInsect}
              cellType={cellType}
            />
          )
        )}
      </div>
    </section>
  )
};

const mapStateToProps = ({
  insects: { collection, selectedInsects }
}) => ({
  collection,
  selectedInsects
});

const mapDispatchToProps = dispatch => ({
  getCollectionIfNeeded: () => dispatch(getCollectionIfNeeded())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Labels);
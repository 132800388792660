import React, { Component } from 'react';
import PropTypes from 'prop-types';

// css
import './index.css'

const taxonToShow = 2;

const CurrentIdentification = ({ identificationPath, onSelectTaxon }) => (
  <div className="current-identification mb-1.75">
    <label>Current Identification</label>

    <nav aria-label="breadcrumb">
      <ol className="breadcrumb bg-tertiary p-0.75">
        {identificationPath.length > taxonToShow && (
          <li className="breadcrumb-item" aria-current="page" key={-1}>
            . . .
          </li>
        )}
        {identificationPath.slice(-taxonToShow).map((node, i) => {
          if (i === taxonToShow - 1) {
            return (
              <li className="breadcrumb-item active" aria-current="page" key={i}>
                <div>
                  <p>{node.name}</p>
                  <p><small>{node.rank}</small></p>
                </div>
              </li>
            );
          } else {
            return (
              <li className="breadcrumb-item" aria-current="page" key={i}>
                <div onClick={onSelectTaxon(node.tsn)}>
                  <p>{node.name}</p>
                  <p><small>{node.rank}</small></p>
                </div>
              </li>
            );
          }
        })}
      </ol>
    </nav>
  </div>
);

CurrentIdentification.propTypes = {
  identificationPath: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default CurrentIdentification;
import React from 'react';
import PropTypes from 'prop-types';

const Alert = ({
  className,
  style,
  faIcon,
  text
}) => (
  <div className={`alert d-inline-flex flex-fill align-items-center ${className}`} style={style} role="alert">
    <i className={`fas ${faIcon} mr-1`}></i> {text}
  </div>
);

Alert.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  faIcon: PropTypes.string,
  text: PropTypes.string.isRequired
}

Alert.defaultProps = {
  faIcon: 'exclamation-circle'
}

export default Alert;
/**
 * Retrieves the taxon object in a JSON array based a specified rank
 * @param {Array} classificationStringWithRanks JSON array of classification path including taxon name and rank
 * @param {String} rank The rank of the classification path node to retrieve
 * @return {Object} The classification path node object of form { taxon: 'Bilateria', rank: 'subphylum' }
 */
export const getTaxonByRank = (classificationWithRanks, rank) => {
    return classificationWithRanks
        .filter(classificationNode =>
            classificationNode.rank.toLowerCase() === rank.toLowerCase()
        )[0];
};

/**
 * 
 * @param {String} classificationStringWithRanks Classification path string of the form 'rank1:taxon1$rank2:taxon2$...'
 * @return {Array} The jsonified classification path of the form [{ taxon: 'taxon1', rank: 'rank1' }, { taxon: 'taxon2', rank: 'rank2' }, ...] 
 */
export const jsonifyClassificationStringWithRanks = classificationStringWithRanks => {
    return classificationStringWithRanks
        .split('$')
        .map(taxonAndRank => {
            let taxonAndRankArray = taxonAndRank.split(':');
            return {
                taxon: taxonAndRankArray[1],
                rank: taxonAndRankArray[0]
            };
        });
};

export default getTaxonByRank;
import React from 'react';
import PropTypes from 'prop-types';
import { dmsGpsFormatter } from '../../../../utils/gpsFormats';
import { insectLabelDateFormatter } from '../../../../utils/dateFormats';
import getCountryByName from '../../../../utils/countryCodes';
import getTaxonByRank, { jsonifyClassificationStringWithRanks } from '../../../../utils/classificationTraversal';

import { labelNameFormatter } from '../../../../utils/nameFormats';

const EnvelopeLabelTable = ({
  insect
}) => {
  let locationString = [];
  if (insect.country) locationString.push(getCountryByName(insect.country)[0].iso3);
  if (insect.state) locationString.push(insect.state);
  if (insect.county) locationString.push(insect.county);
  locationString = locationString.filter(Boolean).join(', ');

  let finderName = labelNameFormatter(
    insect.finderFirstName,
    insect.finderMiddleName,
    insect.finderLastName,
    insect.finderSuffix
  );

  let identifierName = labelNameFormatter(
    insect.identifierFirstName,
    insect.identifierMiddleName,
    insect.identifierLastName,
    insect.identifierSuffix
  );

  let collectionDateFinderLine = [
    insectLabelDateFormatter(insect.collectionDate),
    finderName
  ].filter(Boolean).join(' ');

  let identification = insect.classificationStringWithRanks
    ? jsonifyClassificationStringWithRanks(insect.classificationStringWithRanks)
    : null;

  let order,
      family,
      genus,
      species,
      specificIdentification;
  if (identification) {
    order = getTaxonByRank(identification, 'order');
    family = getTaxonByRank(identification, 'family');
    genus = getTaxonByRank(identification, 'genus');
    species = getTaxonByRank(identification, 'species');
  }
  if (species) {
    specificIdentification = species.taxon;
  } else if (genus) {
    specificIdentification = genus.taxon + ' spp.';
  }

  return (
    <table className="envelope-label-table">
      <tbody>
        <tr>
          <td>
            <div>
              <p>{insect.humanReadableId}</p>
              {insect.latitude && insect.latitude && (
                <p>{dmsGpsFormatter(insect.latitude, insect.longitude)}</p>
              )}
              {locationString && (
                <p>{locationString}</p>
              )}
              {collectionDateFinderLine && (
                <p>{collectionDateFinderLine}</p>
              )}
            </div>
          </td>
          <td>
            {identification && (
              <div>
                {order && <p>{order.taxon}</p>}
                {family && <p>{family.taxon}</p>}
                {specificIdentification && <p><em>{specificIdentification}</em></p>}
                {identifierName && <p>det. {identifierName}</p>}
              </div>
            )}
          </td>
        </tr>
      </tbody>
    </table>
  )
};

EnvelopeLabelTable.propTypes = {
  insect: PropTypes.object.isRequired
}

export default EnvelopeLabelTable;
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

/**
 * Intended to be used with <SideNavContainer>
 * e.g.
 * <SideNavContainer>
 *   <SideNav>
 *     <SideNavItem></SideNavItem>
 *   </SideNav>
 *   <SideNavPageContent></SideNavPageContent>
 * </SideNavContainer>
 */
const SideNav = ({ children, className }) => {
  return (
    <div className={`side-navigation bg-primary col-3 d-none d-lg-block pr-0 pt-7.5 ${className}`}>
      <div>
        {children}
      </div>
    </div>
  );
};

SideNav.propTypes = {
  className: PropTypes.string
};

export default SideNav;
import React from 'react';
import PropTypes from 'prop-types';

// css
// import './index.css';

/**
 * Creates the body for the basic modal
 * @param {Function} toggle Toggles the modal on and off, should be the same function as the one passed to the parent
 */
const BasicModalBody = ({
  className,
  children
}) => {
  return (
    <div className={`modal-body bg-quinary pt-2 px-2 ${className}`}>
      {children}
    </div>
  );
};

BasicModalBody.propTypes = {
  className: PropTypes.string
};

export default BasicModalBody;
import React from 'react';
import PropTypes from 'prop-types';

// css
import './index.css';

// images
import BootstrapLogoImg from './images/bootstrap_logo-compressed.png';
import ExpressLogoImg from './images/Express-compressed.png';
import MarillaProfileImg from './images/Marilla Kennell-compressed.jpg';
import FontAwesomeLogoImg from './images/font-awesome-brands-compressed.png';
import MikeProfileImg from './images/mike_cox-compressed.png';
import MysqlLogoImg from './images/mysql_logo-compressed.png';
import NginxLogoImg from './images/nginx_logo-compressed.png';
import NodejsLogoImg from './images/Node.js_logo-compressed.png';
import ReactjsLogoImg from './images/reactjs-compressed.png';
import UbuntuLogoImg from './images/ubuntu_black-orange_hex-compressed.png';

// custom components
import PersonProfile from './components/PersonProfile';
import TechnologyHighlight from './components/TechnologyHighlight';

const AttributionSection = () => {
  return (
    <section className="attribution-section bg-quinary pt-6.5 pb-8">
      <div className="container">
        <h1 className="text-center mb-1 mb-md-2">Attribution</h1>
        <p className="text-center">This application would not be possible without the use of many free and open-source technologies, as well as kindly donated images.</p>

        <h2 className="text-center mt-3 mt-md-4.5 mb-3">Technologies</h2>
        <div className="row align-items-center">
          <TechnologyHighlight
            href="https://ubuntu.com"
            imgSrc={UbuntuLogoImg}
            imgAlt="Ubuntu Logo"
          />
          <TechnologyHighlight
            href="https://www.nginx.com/"
            imgSrc={NginxLogoImg}
            imgAlt="Nginx Logo"
          />
          <TechnologyHighlight
            href="https://nodejs.org/en/"
            imgSrc={NodejsLogoImg}
            imgAlt="NodeJS Logo"
          />
          <TechnologyHighlight
            href="https://www.mysql.com/"
            imgSrc={MysqlLogoImg}
            imgAlt="MySQL Logo"
          />
          <TechnologyHighlight
            href="https://expressjs.com/"
            imgSrc={ExpressLogoImg}
            imgAlt="Express Logo"
          />
          <TechnologyHighlight
            href="https://reactjs.org/"
            imgSrc={ReactjsLogoImg}
            imgAlt="ReactJS Logo"
            altSize
          />
          <TechnologyHighlight
            href="https://getbootstrap.com/"
            imgSrc={BootstrapLogoImg}
            imgAlt="Bootstrap Logo"
            altSize
          />
          <TechnologyHighlight
            href="https://fontawesome.com/"
            imgSrc={FontAwesomeLogoImg}
            imgAlt="Material Logo"
            altSize
          />
        </div>

        <h2 className="text-center mt-5 mt-md-6 mb-5">Images</h2>
        <div className="row">
          <div className="col-12 col-md-6">
            <PersonProfile
              className="justify-content-md-center mb-6 mb-md-0"
              imgSrc={MarillaProfileImg}
              name="Marilla Kennell"
            />
          </div>
          <div className="col-12 col-md-6">
            <PersonProfile
              className="justify-content-md-center"
              imgSrc={MikeProfileImg}
              name="Mike Cox"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default AttributionSection;
/**
 * Get anti-CSRF token if exists
 * @return {?String} The anti-CSRF token if it exists
 */
export default () => {
    const csrfTokenKeyValue = document.cookie
        ? document.cookie.split('; ').find(key => key.startsWith('csrf'))
        : null;
    return csrfTokenKeyValue
        ? csrfTokenKeyValue.split('=')[1]
        : null
}